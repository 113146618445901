import type { CompanyFullAddress } from '@shared/company/CompanyTypes'
import { createAddressText } from '@shared/company/CompanyUtils'
import { AddressInputSchema } from '@shared/lib/validation/schemas'
import type { CompanyAddressId, CompanyId } from 'rma-shared/types/brands'
import type { CompanyShippingAddressInput } from '../../generated/graphql'

export function createAddressOptions(addresses?: readonly CompanyFullAddress[] | null) {
  if (!addresses) {
    return []
  }

  const addressesOptions = addresses.map((address) => ({
    value: address.id || ('' as CompanyAddressId),
    label: createAddressText(address),
  }))

  return addressesOptions
}

export function getValidAddresses(
  companyId: CompanyId,
  companyName: string,
  addresses?: readonly CompanyFullAddress[] | null,
) {
  if (!addresses) {
    return []
  }

  const validAddreses = addresses.filter((address) => {
    const transformedAddress = transformAddress(companyId, companyName, address)

    return AddressInputSchema.isValidSync(transformedAddress)
  })

  return validAddreses
}

export function getDefaultAddress(addresses?: readonly CompanyFullAddress[]) {
  if (!addresses) {
    return null
  }

  return addresses.find((address) => address.isDefaultShipping) ?? addresses[0]
}

export function getAddress(
  addressId: CompanyAddressId,
  addresses?: readonly CompanyFullAddress[],
): CompanyFullAddress | null {
  if (!addresses) {
    return null
  }

  return addresses.find((address) => address.id === addressId) ?? null
}

export function transformAddress(
  companyId: CompanyId,
  companyName: string,
  address: CompanyFullAddress,
): CompanyShippingAddressInput {
  return {
    address: address.address1 || '',
    city: address.city || '',
    companyId,
    companyName,
    country: address.country || '',
    id: address.id || ('' as CompanyAddressId),
    phoneNumber: address.phone || '',
    zipcode: address.zipcode || '',
    state: address.state,
  }
}
