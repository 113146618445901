interface createAddressTextProps {
  address1?: Maybe<string>
  city?: Maybe<string>
  zipcode?: Maybe<string>
  country?: Maybe<string>
  state?: Maybe<string>
}
export const createAddressText = ({ address1, city, zipcode, country, state }: createAddressTextProps) => {
  const stateFormatted = state ? `${state} ` : ''
  const output = `${address1 || ''}, ${city || ''} ${stateFormatted} ${zipcode || ''}, ${country || ''}`
  return output
}
