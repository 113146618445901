import { ToolbarSearch } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled from 'styled-components'
import { SubNavigationBar } from '../sub-navigation-bar'

interface TicketsTableHeaderProps {
  label: string
  right?: ReactNode
  searchQuery: string
  onChangeSearchQuery?: (searchQuery: string) => void
}

export function TicketsTableHeader({ label, right, searchQuery, onChangeSearchQuery }: TicketsTableHeaderProps) {
  return (
    <SubNavigationBar
      title={label}
      right={
        <>
          {onChangeSearchQuery && (
            <RightSidePlaceholder>
              <ToolbarSearch
                value={searchQuery}
                onChange={(_e, value) => onChangeSearchQuery(value.toString().trim())}
                onClear={() => onChangeSearchQuery('')}
                width={180}
                expand={!!searchQuery}
              />
              {right}
            </RightSidePlaceholder>
          )}
        </>
      }
    />
  )
}

// this is where a search field might be implemented
// but before such a field is here, use this placeholder as spacer to ensure the title is horizontally centered
const RightSidePlaceholder = styled.div`
  width: 342px; // width of the tabs in the left nav side
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
