import type { ApolloError } from '@apollo/client'
import { Div } from '@shared-ui/components'
import { Bullet } from '@shared-ui/components/Bullet'
import { Flex } from '@shared-ui/components/Flex'
import { MessageContainer } from '@shared-ui/components/MessageContainer'
import useWindowDimensions from '@shared-ui/hooks/useWindowDimensions'
import { theme } from '@shared-ui/styles/theme'
import { Donut, Loader, Text } from '@ubnt/ui-components'
import { Heading } from 'components/Heading'
import styled from 'styled-components'
import { TicketOverallStatus } from '../../../../generated/graphql'
import type { DashboardStatusesStatsFragment } from './__generated__/TicketStatus'

const ticketStatusLabelMap = {
  [TicketOverallStatus.InProgress]: 'In Progress',
  [TicketOverallStatus.Declined]: 'Declined',
  [TicketOverallStatus.Cancelled]: 'Canceled',
  [TicketOverallStatus.Approved]: 'Approved',
}

const ticketStatusColorMap = {
  [TicketOverallStatus.InProgress]: theme.colors.statuses.inProgress,
  [TicketOverallStatus.Declined]: theme.colors.statuses.declined,
  [TicketOverallStatus.Cancelled]: theme.colors.statuses.cancelled,
  [TicketOverallStatus.Approved]: theme.colors.statuses.approved,
}

export const TicketStatus = ({
  loading,
  error,
  statuses,
}: {
  loading: boolean
  error?: ApolloError
  statuses?: readonly DashboardStatusesStatsFragment[]
}) => {
  const { width } = useWindowDimensions()
  const isMobile = width < theme.media.viewport.s

  return (
    <Div className="flex flex-column flex-1">
      <Heading size="header-xs" weight="bold" underline>
        Ticket Status
      </Heading>

      {(() => {
        if (error || loading || !statuses) {
          return (
            <MessageContainer>
              {/* eslint-disable no-nested-ternary */}
              {error ? (
                <Text>Error loading data.</Text>
              ) : loading ? (
                <Loader size="small" />
              ) : (
                <Text>No data available.</Text>
              )}
              {/* eslint-enable no-nested-ternary */}
            </MessageContainer>
          )
        }

        const count = statuses.reduce((totalCount, status) => totalCount + status.count, 0)
        const items = [...statuses]
          .sort((a, b) => b.count - a.count)
          .map((status) => ({
            id: status.status,
            value: status.count,
            color: ticketStatusColorMap[status.status],
          }))

        return (
          <Div className="flex align-end height-100">
            <Div className="flex">
              <Donut
                size={isMobile ? 135 : 180}
                motif="light"
                title={count}
                description={'Tickets'.toUpperCase()}
                items={items}
                tooltipProps={theme.tooltip}
                renderTooltipMessage={(status) =>
                  `${status.value} ${ticketStatusLabelMap[status.id as TicketOverallStatus]}`
                }
              />
              <Div className="flex flex-1 ml-1 align-center">
                <div>
                  <div>
                    <Text color="secondary">Type</Text>
                  </div>
                  <ItemList>
                    {items.map((status) => (
                      <Flex key={status.id} alignCenter>
                        <Bullet color={status.color} />
                        <Text color="secondary">{ticketStatusLabelMap[status.id]}</Text>
                      </Flex>
                    ))}
                  </ItemList>
                </div>

                <Div className="ml-1">
                  <div>
                    <Text color="secondary">Total</Text>
                  </div>
                  <ItemList>
                    {items.map((status) => (
                      <Flex key={status.id} alignCenter>
                        <Text color="secondary">{status.value}</Text>
                      </Flex>
                    ))}
                  </ItemList>
                </Div>
              </Div>
            </Div>
          </Div>
        )
      })()}
    </Div>
  )
}

const ItemList = styled.div`
  margin-top: ${(props) => props.theme.spacing.s};
  white-space: nowrap;
`
