import type { TicketStatus } from '@shared/tickets'
import type { TableColumnConfig, TableItem } from '@ubnt/ui-components'
import { Loader, Text, Table } from '@ubnt/ui-components'
import type { FC } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import { QueryTicketType } from '../../../../generated/graphql'
import { useNestedPath } from '../../../../hooks'
import { Container } from '../../../Container'
import { LoaderContainer } from '../../../Containers'
import { RemoteImage } from '../../../Image'
import { PaginationControls } from '../../../PaginationControls'
import { Device, FormattedDate, Status, SupportBy, TicketIdRow, TimeElapsed, Tracking } from '../../../table-columns'
import type { CommonProps } from '../../common'
import { DEFAULT_TICKETS_PER_PAGE } from '../../common'
import { useTickets_Closed_OutgoingTicketsQuery as useOutgoingTicketsQuery } from './__generated__/TicketsTable'
import { StickyTableWrap, StickyTableContainer } from '../../../StickyTable'

interface Row {
  id: string
  mac: string
  model: {
    name: string
    image: string | null
  }
  nextHandlerName: string
  createdAt: string
  updatedAt: string
  closedAt?: string
  status: TicketStatus
  trackingNumber: string
}

export const TicketsTable: FC<CommonProps> = ({ searchQuery, stickyHeadTop }) => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_TICKETS_PER_PAGE)
  const { loading, error, data } = useOutgoingTicketsQuery({
    variables: {
      query: {
        type: QueryTicketType.Closed,
        search: searchQuery,
      },
      cursor: offset.toString(),
      limit,
    },
  })

  const history = useHistory()
  const nested = useNestedPath()

  const total = data?.tickets?.pageInfo.total || 0

  return (
    <StickyTableWrap>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        const items = (data?.tickets?.result || []).map(
          (item): TableItem<Row> => ({
            id: item.id,
            mac: item.device.mac || '',
            model: {
              name: item.device.name,
              image: item.device.productImage,
            },
            nextHandlerName: item.nextHandler.name,
            createdAt: item.createdAt,
            closedAt: item.closedAt ?? undefined,
            updatedAt: item.updatedAt,
            status: item.status,
            // TODO: Update this once multi shipment logic gets implemented
            trackingNumber: item.shipment?.trackingNumber ?? '',
          }),
        )

        return (
          <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
            <Table
              rowHeight={50}
              headerHeight={50}
              initialSortBy="closedAt"
              disableColumnFilters
              columns={columns}
              items={items}
              onRowClick={(item) => {
                history.push(nested(`/${item.id}`))
              }}
              renderPlaceholder={() => (
                <Container $padding={['m', 0]}>
                  <Text size="inherit">No closed tickets{searchQuery && ' found'}.</Text>
                </Container>
              )}
              renderFooter={() => (
                <PaginationControls
                  pageInfo={{
                    offset,
                    limit,
                    total,
                  }}
                  loadPage={(newPageInfo) => {
                    setOffset(newPageInfo.offset)
                    setLimit(newPageInfo.limit)
                  }}
                  itemsLabel="tickets"
                />
              )}
            />
          </StickyTableContainer>
        )
      })()}
    </StickyTableWrap>
  )
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'id',
    label: <span data-testid="rma-dashboard-ticket-no">Ticket no.</span>,
    sortable: true,
    renderCell: (row) => <TicketIdRow ticketId={row.id} />,
  },
  {
    id: 'model',
    label: <span data-testid="direct-dashboard-device">Device</span>,
    sortable: true,
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'mac',
    label: <span data-testid="rma-dashboard-mac-id">MAC ID / Serial</span>,
    sortable: true,
    renderCell: (row) => formatSerial(row.mac),
  },
  {
    id: 'nextHandlerName',
    label: 'Handled by',
    sortable: true,
    renderCell: (row) => <SupportBy name={row.nextHandlerName} />,
  },
  {
    id: 'closedAt',
    label: 'Completion time',
    sortable: true,
    renderCell: (row) => <TimeElapsed from={row.createdAt} to={row.closedAt} />,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    renderCell: (row) => <Status id={row.id} status={row.status} link={false} />,
  },
  {
    id: 'updatedAt',
    label: 'Updated on',
    sortable: true,
    renderCell: (row) => <FormattedDate date={row.updatedAt} />,
  },
  {
    id: 'trackingNumber',
    label: 'Shipment ID',
    sortable: true,
    renderCell: (row) => <Tracking ticketId={row.id} trackingNumber={row.trackingNumber} />,
  },
]
