import useNestedPath from '@shared-ui/hooks/useNestedPath'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Tickets } from '../tickets'

export const Dashboard = () => {
  const nested = useNestedPath()

  return (
    <Switch>
      <Route path={nested('/tickets')}>
        <Tickets />
      </Route>
      <Route path="*">
        <Redirect to={nested('/tickets')} />
      </Route>
    </Switch>
  )
}
