import { StickyHeader } from '@shared-ui/components/StickyTable'
import { TicketsTableHeader } from '@shared-ui/components/Tickets/TicketsTableHeader'
import { TicketsTableNav } from '@shared-ui/components/Tickets/tickets-table-nav'
import { CustomerTicketsTable } from '@shared-ui/components/customer-tickets-table'
import { QueryTicketType } from '@shared-ui/generated/graphql'
import useNavigate from '@shared-ui/hooks/useNavigate'
import useNestedPath from '@shared-ui/hooks/useNestedPath'
import { useTicketsStore } from '@shared-ui/tickets/tickets-store'
import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Ticket } from '../dashboard/tickets/components/Ticket'

const CustomerTicketsView = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const nested = useNestedPath()

  const onTicketClose = useCallback(() => navigate(nested('')), [navigate, nested])

  return (
    <>
      {children}
      <Route path={nested('/:ticketId')}>
        <Ticket onClose={onTicketClose} />
      </Route>
    </>
  )
}

export function CustomerTickets() {
  const { totals, search } = useTicketsStore()
  const nested = useNestedPath()
  const navigate = useNavigate()
  const [stickyHeadTop, setStickyHeadTop] = useState('0')

  const handleSearch = (searchQuery: string) => {
    navigate(nested('/all'))
    useTicketsStore.setState({
      search: searchQuery,
    })
  }

  return (
    <div className="flex column flex-1 relative">
      <StickyHeader ref={(el: HTMLDivElement | null) => setStickyHeadTop(`${el?.offsetHeight || 0}px`)}>
        <TicketsTableHeader label="Customer Tickets" searchQuery={search} onChangeSearchQuery={handleSearch} />
        <TicketsTableNav
          tabs={[
            {
              id: nested('/pending'),
              label: 'Pending',
              count: totals.Pending,
            },
            {
              id: nested('/in-progress'),
              label: 'In Progress',
              count: totals.InProgress,
            },
            {
              id: nested('/shipped'),
              label: 'Shipped',
              count: totals.Shipped,
            },
            {
              id: nested('/declined'),
              label: 'Declined',
              count: totals.Declined,
            },
            {
              id: nested('/closed'),
              label: 'Closed',
              count: totals.Closed,
            },
            {
              id: nested('/all'),
              label: 'All',
              count: totals.All,
            },
          ]}
        />
      </StickyHeader>

      <Switch>
        <Route path={nested('/pending')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.Pending} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path={nested('/in-progress')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.InProgress} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path={nested('/shipped')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.Shipped} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path={nested('/declined')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.Declined} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path={nested('/closed')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.Closed} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path={nested('/all')}>
          <CustomerTicketsView>
            <CustomerTicketsTable type={QueryTicketType.All} stickyHeadTop={stickyHeadTop} />
          </CustomerTicketsView>
        </Route>

        <Route path="*">
          <Redirect to={nested('/pending')} />
        </Route>
      </Switch>
    </div>
  )
}
