import type { ApolloError } from '@apollo/client'
import { Div } from '@shared-ui/components'
import { Flex, FlexItem } from '@shared-ui/components/Flex'
import { MessageContainer } from '@shared-ui/components/MessageContainer'
import { Stars } from '@shared-ui/components/Stars'
import {
  Average,
  AverageContainer,
  Bar,
  Head,
  RatingContainer,
  RatingCountContainer,
  Star,
} from '@shared-ui/components/graphs/AverageRating'
import { Loader, Text } from '@ubnt/ui-components'
import { Heading } from 'components/Heading'
import { theme } from '../../../../styles/theme'
import type { DashboardRatingStatsFragment } from './__generated__/AverageRating'

const STAR_COUNT = 5

export const AverageRating = ({
  loading,
  error,
  ratings,
}: {
  loading: boolean
  error?: ApolloError // TODO: Or anything else.
  ratings?: DashboardRatingStatsFragment
}) => {
  return (
    <Div className="flex flex-column flex-1">
      <Heading size="header-xs" weight="bold" underline>
        Average Star Rating
      </Heading>

      {(() => {
        if (error || loading || !ratings) {
          return (
            <MessageContainer>
              {/* eslint-disable no-nested-ternary */}
              {error ? (
                <Text>Error loading data.</Text>
              ) : loading ? (
                <Loader size="small" />
              ) : (
                <Text>No data available.</Text>
              )}
              {/* eslint-enable no-nested-ternary */}
            </MessageContainer>
          )
        }

        const breakdown = [...ratings.breakdown].reverse()
        const max = Math.max(0, ...breakdown.map((rating) => rating.count))

        return (
          <FlexItem>
            <Head>
              <RatingContainer>
                <Stars value={ratings.average} count={STAR_COUNT} />
              </RatingContainer>
              <AverageContainer>
                <Average>
                  {(ratings.average * STAR_COUNT).toFixed(2)} / {STAR_COUNT}
                </Average>
                <Flex center>
                  <Text color="secondary">{ratings.count} Total Ratings</Text>
                </Flex>
              </AverageContainer>
            </Head>

            <Flex>
              <FlexItem>
                {breakdown.map((rating, i) => {
                  const value = (rating.count / max) * 100

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Flex key={i} alignCenter>
                      <Text>{rating.group}</Text>
                      <Star />
                      <Bar
                        height={15}
                        items={[{ value, color: theme.colors.gradientBlue }]}
                        itemTooltip={() => ({
                          message: `${rating.count} reviews`,
                          ...theme.tooltip,
                        })}
                      />
                    </Flex>
                  )
                })}
              </FlexItem>

              <RatingCountContainer>
                {breakdown.map((rating) => (
                  <Text key={rating.group}>{rating.count}</Text>
                ))}
              </RatingCountContainer>
            </Flex>
          </FlexItem>
        )
      })()}
    </Div>
  )
}
