import type { CompanyAddressId, CompanyId, FileId, ShippingAddressId, TicketId } from '@shared/types/brands'
import { cssVariables } from '@ubnt/ui-components'
import type { FormikConfig } from 'formik'
import { Form, Formik } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import type {
  CompanyShippingAddressInput,
  CreateForwardingTicketsInput,
  CreateTicketAddress,
} from '../../../../generated/graphql'
import useNavigate from '../../../../hooks/useNavigate'
import useNestedPath from '../../../../hooks/useNestedPath'
import type { FileUploadFile } from '../../../file-upload'
import type { ShipmentInfoInputValue as ShipmentInfo } from '../../../form/ShipmentInfoInput'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import {
  useSubmit_CreateRmaCompaniesQuery as useCreateRmaCompaniesQuery,
  useSubmit_CreateRmaMutation as useCreateRmaMutation,
} from './__generated__/Submit'

type State = { selectedIds: readonly TicketId[] }

const isValidState = (state: unknown): state is State =>
  typeof state === 'object' && !!state && 'selectedIds' in state && Array.isArray((state as State).selectedIds)

const getSelectedIds = (state: unknown): readonly TicketId[] =>
  isValidState(state) ? state.selectedIds.filter((id) => typeof id === 'string') : []

const createEmptyCompanyAddress = (): CompanyShippingAddressInput => ({
  id: '' as CompanyAddressId,
  city: '',
  companyId: '' as CompanyId,
  companyName: '',
  country: '',
  phoneNumber: '',
  address: '',
  zipcode: '',
  state: '',
})

type Values = {
  shipmentInfo: ShipmentInfo
  shippingAddress: CreateTicketAddress
  shippingAddressId: ShippingAddressId | null
  deliveryAddress: CompanyShippingAddressInput
  returnAddress: CompanyShippingAddressInput
}

const initialValues: Values = {
  shipmentInfo: {
    shippingCompany: '',
    trackingNumber: '',
  },
  shippingAddress: {
    firstName: '',
    lastName: '',
    companyName: '',
    address1: '',
    phoneNumber: '',
    city: '',
    country: '',
    state: '',
    zipcode: '',
  },
  shippingAddressId: null,
  deliveryAddress: createEmptyCompanyAddress(),
  returnAddress: createEmptyCompanyAddress(),
}

export const Submit = () => {
  const nested = useNestedPath()
  const navigate = useNavigate()
  const { state } = useLocation()
  // Looks weird but we're basically preserving selected ids between navigations (steps)
  const [selectedIds, setSelectedIdsRaw] = useState(() => getSelectedIds(state))
  const setSelectedIds = (newSelectedIds: string[]) => {
    navigate(nested(''), { selectedIds: newSelectedIds })
    setSelectedIdsRaw(newSelectedIds)
  }

  const skipFileCopyMap = useMemo(() => {
    return new Map<TicketId, FileId[]>(selectedIds.map((ticketId) => [ticketId, []]))
  }, [selectedIds])

  const newCompanyFilesMap = useMemo(() => {
    return new Map<TicketId, FileUploadFile[]>(selectedIds.map((ticketId) => [ticketId, []]))
  }, [selectedIds])

  const { data: distributors } = useCreateRmaCompaniesQuery({
    variables: { filter: { ticketIds: selectedIds, isExpired: false } },
  })
  const nextHandler = distributors?.ticketsPendingRma?.result[0]?.nextHandler.id

  const [createRma, { loading: isSubmitting, error }] = useCreateRmaMutation()

  const handleSubmit: FormikConfig<Values>['onSubmit'] = useCallback(
    async (input) => {
      try {
        const createTicketsInput: CreateForwardingTicketsInput = {
          deliveryAddress: input.deliveryAddress,
          returnAddress: input.returnAddress,
          shipmentInfo: input.shipmentInfo,
          ticketIds: selectedIds,
          shippingAddress: input.shippingAddress,
          shippingAddressId: input.shippingAddressId || 0,
          newCompanyFiles: [...newCompanyFilesMap]
            .filter((ticketList) => ticketList[1].length)
            .map((ticketList) => {
              return {
                ticketId: ticketList[0],
                files: ticketList[1].map((item) => item.file),
              }
            }),
          skipFileCopyIds: [...skipFileCopyMap]
            .filter((ticketList) => ticketList[1].length)
            .map((ticketList) => {
              return {
                ticketId: ticketList[0],
                filesIds: ticketList[1].map((item) => `${item}`),
              }
            }),
        }
        const { data } = await createRma({ variables: { input: createTicketsInput } })
        if (!data?.createForwardingTickets.shipmentBatchId) {
          return
        }
        navigate(`/tickets/pending/done/${data.createForwardingTickets.shipmentBatchId}`)
      } catch {
        //
      }
    },
    [createRma, navigate, nested, selectedIds, newCompanyFilesMap, skipFileCopyMap],
  )

  // TODO: Fix, not working (selectedIds cached on a Refresh and nothing shows up).
  if (!selectedIds.length) {
    return <Redirect to=".." />
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <FlexForm>
        <Items>
          <Step1
            ticketIds={selectedIds}
            skipFileCopyMap={skipFileCopyMap}
            newCompanyFilesMap={newCompanyFilesMap}
            onDeleteTicket={(ticketId) => {
              setSelectedIds(selectedIds.filter((selectedId) => selectedId !== ticketId))
            }}
          />
        </Items>
        <Confirmation>
          <Step2
            nextHandlerId={nextHandler}
            itemCount={selectedIds.length}
            errorMessage={error?.message}
            isSubmitting={isSubmitting}
          />
        </Confirmation>
      </FlexForm>
    </Formik>
  )
}

const FlexForm = styled(Form)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const Items = styled.div`
  flex: 1;
  padding: ${cssVariables['spacing-xxl']} ${cssVariables['spacing-xl']};
`

const Confirmation = styled.div`
  width: 400px;
  border-left: 1px solid ${cssVariables.motifs.light.neutral03};
`
