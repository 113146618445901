import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import useNestedPath from '../../hooks/useNestedPath'
import { FlexItem } from '../Flex'
import { Ticket as ActiveTicketDetails } from './Active/Tickets/Ticket'
import { TicketsTable as ActiveTicketsTable } from './Active/Tickets/TicketsTable'
import { Ticket as ClosedTicketDetails } from './Closed/Tickets/Ticket'
import { TicketsTable as ClosedTicketsTable } from './Closed/Tickets/TicketsTable'
import { Ticket as ExpiredTicketDetails } from './Expired/Tickets/Ticket'
import { TicketsTable as ExpiredTicketsTable } from './Expired/Tickets/TicketsTable'
import { Done } from './Pending/Submit/Done'
import { Submit } from './Pending/Submit/Submit'
import { Ticket as PendingTicketDetails } from './Pending/Tickets/Ticket'
import { TicketsTable as PendingTicketsTable } from './Pending/Tickets/TicketsTable'
import { TicketsTableHeader } from './TicketsTableHeader'
import { TicketsTableNav } from './tickets-table-nav'
import { TicketsTableWrap } from './TicketsTableWrap'
import { StickyHeader } from '../StickyTable'

export const RmaTickets = ({ label }: { label: string }) => {
  const nested = useNestedPath()
  const [navRightEl, setNavRightEl] = useState<ReactNode | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState('')
  const [stickyHeadTop, setStickyHeadTop] = useState('0')
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (location.search || location.hash) {
      // leaving hash for backward compatibility for already sent out emails containing such format links
      setSearchQuery(location.search.substring(1) || location.hash.substring(1))
    }
  }, [location])

  const onChangeSearchQuery = (query: string) => {
    history.replace(`${location.pathname}?${query}`)
    setSearchQuery(query)
  }

  const isCreatingShipment = location.pathname === nested('/pending/submit')

  return (
    <FlexItem>
      <StickyHeader ref={(el: HTMLDivElement | null) => setStickyHeadTop(`${el?.offsetHeight || 0}px`)}>
        <TicketsTableHeader
          label={label}
          right={navRightEl}
          searchQuery={searchQuery}
          onChangeSearchQuery={isCreatingShipment ? undefined : onChangeSearchQuery}
        />
        <TicketsTableNav
          tabs={[
            {
              id: nested('/pending'),
              label: 'Pending',
              hideIf: [nested('/pending/submit')],
            },
            {
              id: nested('/active'),
              label: 'Active',
            },
            {
              id: nested('/expired'),
              label: 'Expired',
            },
            {
              id: nested('/history'),
              label: 'History',
            },
          ]}
        />
      </StickyHeader>

      <Switch>
        <Route path={nested('/pending')}>
          <Switch>
            <Route path={nested('/pending/submit')}>
              <Submit />
            </Route>

            <TicketsTableWrap TicketDetails={PendingTicketDetails} searchQuery={searchQuery}>
              <Route path={nested('/pending/done/:shipmentId')}>
                <Done />
              </Route>
              <PendingTicketsTable
                onRenderCreateShipmentButton={setNavRightEl}
                searchQuery={searchQuery}
                stickyHeadTop={stickyHeadTop}
              />
            </TicketsTableWrap>
          </Switch>
        </Route>
        <Route path={nested('/active')}>
          <TicketsTableWrap TicketDetails={ActiveTicketDetails} searchQuery={searchQuery}>
            <ActiveTicketsTable searchQuery={searchQuery} stickyHeadTop={stickyHeadTop} />
          </TicketsTableWrap>
        </Route>
        <Route path={nested('/expired')}>
          <TicketsTableWrap TicketDetails={ExpiredTicketDetails} searchQuery={searchQuery}>
            <ExpiredTicketsTable searchQuery={searchQuery} stickyHeadTop={stickyHeadTop} />
          </TicketsTableWrap>
        </Route>
        <Route path={nested('/history')}>
          <TicketsTableWrap TicketDetails={ClosedTicketDetails} searchQuery={searchQuery}>
            <ClosedTicketsTable searchQuery={searchQuery} stickyHeadTop={stickyHeadTop} />
          </TicketsTableWrap>
        </Route>
        <Route path="*">
          <Redirect to={nested('/pending')} />
        </Route>
      </Switch>
    </FlexItem>
  )
}
