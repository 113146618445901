import type { PageInfo } from 'rma-shared/types/tickets'
import create from 'zustand'

interface ShipmentsState {
  pageInfo: PageInfo
}

interface ShipmentsStateFunctions {
  reset: () => void
}

const createInitialState = (): ShipmentsState => {
  return {
    pageInfo: {
      limit: 100,
      offset: 0,
      total: 0,
    },
  }
}

export const useShipmentsStore = create<ShipmentsState & ShipmentsStateFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },
}))
