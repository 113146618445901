import { PackageIcon } from '@ubnt/icons'
import type { ShippingAddress, Shipment, Ticket } from 'generated/graphql'
import styled from 'styled-components'
import type { FC } from 'react'
import { cssVariables } from '@ubnt/ui-components'
import isPortal from '../../utils/isPortal'
import { Flex, FlexItem, SidePanelCard, Text } from '../index'
import type { Shipping_GeneralFragment as ShippingGeneralFragment } from './__generated__/ShippingTab'

interface ShippingTabProps {
  shipment: ShippingGeneralFragment
  linkToTickets: (ticketId: Ticket['id'], shipmentUid: Shipment['uid']) => string
}
const ShippingTabBody: FC<ShippingTabProps> = ({ shipment }) => {
  return (
    <Wrap>
      <ShippingHeading data={shipment} />
      <ShippingDetails data={shipment} />
    </Wrap>
  )
}

interface ShippingHeadingProps {
  data: ShippingGeneralFragment
}
const ShippingHeading = ({ data }: ShippingHeadingProps) => {
  return (
    <HeadingContainer>
      <HeadingText>{data.status}</HeadingText>
      <HeadingLink>
        <Text color="secondary">{data.trackingNumber ? data.trackingNumber : 'Unspecified tracking Id'}</Text>
      </HeadingLink>
    </HeadingContainer>
  )
}

interface ShippingDetailsProps {
  data: ShippingGeneralFragment
}
const ShippingDetails = ({ data }: ShippingDetailsProps) => {
  const isDirectPortal = isPortal('DIRECT')

  return (
    <FlexItem scroll>
      <Flex column>
        <Block>
          {!isDirectPortal && (
            <Row>
              <Text color="secondary">Shipment to</Text>
              <Value color="secondary">
                <ShippingAddressInfo shippingAddress={data.deliveryAddress} />
              </Value>
            </Row>
          )}

          <Row>
            <Text color="secondary">Shipment from</Text>
            <Value color="secondary">
              <ShippingAddressInfo shippingAddress={data.returnAddress} />
            </Value>
          </Row>
        </Block>
      </Flex>
    </FlexItem>
  )
}

interface ShippingAddressInfoProps {
  shippingAddress?: ShippingAddress | null
}
const ShippingAddressInfo = ({ shippingAddress }: ShippingAddressInfoProps) => {
  if (!shippingAddress) {
    return <Text color="secondary">Address not found.</Text>
  }

  const { name, city, state, country, address1, zipcode } = shippingAddress

  return (
    <>
      <ShippingAddressBlock>
        <ShippingAddressRow>{name}</ShippingAddressRow>
        <ShippingAddressRow>
          {city}, {state ? `${state} ,` : ''} {country}
        </ShippingAddressRow>
        <ShippingAddressRow>
          {address1}, {zipcode}
        </ShippingAddressRow>
      </ShippingAddressBlock>
    </>
  )
}

const HeadingText = styled(Text).attrs({ size: 'header-s', weight: 'bold' })`
  height: 24px;
  display: flex;
  align-items: center;
`

const HeadingContainer = styled.div`
  margin-bottom: ${cssVariables['spacing-xl']};
`

const HeadingLink = styled(Flex)`
  align-items: center;
  height: 22px;
  font-size: 12px;
  margin-top: 6px;
`

const ShippingAddressBlock = styled(Flex).attrs({ column: true })`
  margin-bottom: ${(props) => props.theme.spacing.m};

  :last-child {
    margin-bottom: 0;
  }
`
const ShippingAddressRow = styled(Text).attrs({ block: true, color: 'secondary' })``

const Block = styled(Flex).attrs({ column: true })``

const Row = styled(Flex).attrs({ justifyBetween: true })`
  > * {
    flex: 0 0 50%;
    margin-bottom: 22px;

    :last-child {
      text-align: right;
    }
  }
`

const Value = styled(Text).attrs({ block: true })``

const Wrap = styled(SidePanelCard)`
  padding: ${cssVariables['spacing-l']};
`

export const ShippingTab = {
  tab: {
    label: 'Shipping',
    id: '/shipping',
    icon: PackageIcon,
  },
  component: ShippingTabBody,
}
