// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type Tickets_Pending_NextHandlerFragment = { readonly __typename?: 'Ticket' } & {
  readonly nextHandler: { readonly __typename?: 'TicketHandler' } & Pick<Types.TicketHandler, 'id' | 'name'>
}

export type Tickets_Pending_TicketsPendingRmaQueryVariables = Types.Exact<{
  filter: Types.Maybe<Types.TicketsPendingRmaFilter>
  cursor: Types.Maybe<Types.Scalars['Cursor']>
  limit: Types.Maybe<Types.Scalars['Int']>
}>

export type Tickets_Pending_TicketsPendingRmaQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsPendingRma: { readonly __typename?: 'TicketsResult' } & {
    readonly pageInfo: { readonly __typename?: 'PageInfo' } & Pick<
      Types.PageInfo,
      'endCursor' | 'hasNextPage' | 'total'
    >
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<
        Types.Ticket,
        'id' | 'expiresAt' | 'description' | 'customerName' | 'customerFailureCategoryId' | 'handlerFailureCategoryId'
      > & {
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'mac' | 'productImage'
          >
        } & Tickets_Pending_NextHandlerFragment
    >
  }
}

export type Tickets_Pending_TicketsPendingDistributorRmaQueryVariables = Types.Exact<{
  filter: Types.Maybe<Types.TicketsPendingRmaFilter>
  cursor: Types.Maybe<Types.Scalars['Cursor']>
  limit: Types.Maybe<Types.Scalars['Int']>
}>

export type Tickets_Pending_TicketsPendingDistributorRmaQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsPendingRma: { readonly __typename?: 'TicketsResult' } & {
    readonly pageInfo: { readonly __typename?: 'PageInfo' } & Pick<
      Types.PageInfo,
      'endCursor' | 'hasNextPage' | 'total'
    >
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<
        Types.Ticket,
        'id' | 'expiresAt' | 'description' | 'customerName' | 'customerFailureCategoryId' | 'handlerFailureCategoryId'
      > & {
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'mac' | 'productImage'
          >
        }
    >
  }
}

export const Tickets_Pending_NextHandlerFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tickets_Pending_NextHandler' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextHandler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const Tickets_Pending_TicketsPendingRmaDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tickets_Pending_TicketsPendingRma' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsPendingRmaFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsPendingRma' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Tickets_Pending_NextHandler' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tickets_Pending_NextHandler' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextHandler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useTickets_Pending_TicketsPendingRmaQuery__
 *
 * To run a query within a React component, call `useTickets_Pending_TicketsPendingRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickets_Pending_TicketsPendingRmaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTickets_Pending_TicketsPendingRmaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTickets_Pending_TicketsPendingRmaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Tickets_Pending_TicketsPendingRmaQuery,
    Tickets_Pending_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useQuery<Tickets_Pending_TicketsPendingRmaQuery, Tickets_Pending_TicketsPendingRmaQueryVariables>(
    Tickets_Pending_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export function useTickets_Pending_TicketsPendingRmaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Tickets_Pending_TicketsPendingRmaQuery,
    Tickets_Pending_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Tickets_Pending_TicketsPendingRmaQuery, Tickets_Pending_TicketsPendingRmaQueryVariables>(
    Tickets_Pending_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export type Tickets_Pending_TicketsPendingRmaQueryHookResult = ReturnType<
  typeof useTickets_Pending_TicketsPendingRmaQuery
>
export type Tickets_Pending_TicketsPendingRmaLazyQueryHookResult = ReturnType<
  typeof useTickets_Pending_TicketsPendingRmaLazyQuery
>
export type Tickets_Pending_TicketsPendingRmaQueryResult = Apollo.QueryResult<
  Tickets_Pending_TicketsPendingRmaQuery,
  Tickets_Pending_TicketsPendingRmaQueryVariables
>
export const Tickets_Pending_TicketsPendingDistributorRmaDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tickets_Pending_TicketsPendingDistributorRma' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsPendingRmaFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsPendingRma' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useTickets_Pending_TicketsPendingDistributorRmaQuery__
 *
 * To run a query within a React component, call `useTickets_Pending_TicketsPendingDistributorRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickets_Pending_TicketsPendingDistributorRmaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTickets_Pending_TicketsPendingDistributorRmaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTickets_Pending_TicketsPendingDistributorRmaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Tickets_Pending_TicketsPendingDistributorRmaQuery,
    Tickets_Pending_TicketsPendingDistributorRmaQueryVariables
  >,
) {
  return Apollo.useQuery<
    Tickets_Pending_TicketsPendingDistributorRmaQuery,
    Tickets_Pending_TicketsPendingDistributorRmaQueryVariables
  >(Tickets_Pending_TicketsPendingDistributorRmaDocument, baseOptions)
}
export function useTickets_Pending_TicketsPendingDistributorRmaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Tickets_Pending_TicketsPendingDistributorRmaQuery,
    Tickets_Pending_TicketsPendingDistributorRmaQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    Tickets_Pending_TicketsPendingDistributorRmaQuery,
    Tickets_Pending_TicketsPendingDistributorRmaQueryVariables
  >(Tickets_Pending_TicketsPendingDistributorRmaDocument, baseOptions)
}
export type Tickets_Pending_TicketsPendingDistributorRmaQueryHookResult = ReturnType<
  typeof useTickets_Pending_TicketsPendingDistributorRmaQuery
>
export type Tickets_Pending_TicketsPendingDistributorRmaLazyQueryHookResult = ReturnType<
  typeof useTickets_Pending_TicketsPendingDistributorRmaLazyQuery
>
export type Tickets_Pending_TicketsPendingDistributorRmaQueryResult = Apollo.QueryResult<
  Tickets_Pending_TicketsPendingDistributorRmaQuery,
  Tickets_Pending_TicketsPendingDistributorRmaQueryVariables
>
