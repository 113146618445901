import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/min'

const asYouType = new AsYouType()

export interface PhoneNumber {
  number: string
  code: string
  country: string
}

export const parsePhoneNumber = (phoneNumber: string | null = ''): PhoneNumber => {
  const parsed = parsePhoneNumberFromString(phoneNumber || '')

  asYouType.reset()

  return {
    code: parsed ? (parsed.countryCallingCode as string) : '',
    country: parsed && parsed.country ? parsed.country : '',
    number: asYouType.input(phoneNumber || ''),
  }
}
