import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NavigationTabsTertiary } from '../NavigationTabs'

interface TicketsTableNavTab {
  id: string
  label: string
  count?: number
  hideIf?: string[]
}

export function TicketsTableNav({ tabs }: { tabs: TicketsTableNavTab[] }) {
  const location = useLocation()
  const navTabs = useMemo(
    () => tabs.map((tab) => ({ id: tab.id, label: tab.count ? `${tab.label} (${tab.count})` : tab.label })),
    [tabs],
  )

  const isHidden = tabs.some((tab) => !!tab.hideIf && tab.hideIf.some((url) => location.pathname.startsWith(url)))
  if (isHidden) {
    return null
  }

  return (
    <NavTabsContainer>
      <NavigationTabsTertiary tabs={navTabs} />
    </NavTabsContainer>
  )
}

const NavTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`
