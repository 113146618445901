import { Button, cssVariables, Loader, Text, Table } from '@ubnt/ui-components'
import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { CompanyId, DeviceId, TicketId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { getFailureCategory } from 'rma-shared/types/tickets'
import { useNavigate, useNestedPath } from '../../../../hooks'
import { Container } from '../../../Container'
import { LoaderContainer } from '../../../Containers'
import { RemoteImage } from '../../../Image'
import { Link } from '../../../Link'
import type { TableColumnConfig, TableItem } from '../../../Table'
import { Device, SupportBy, TimeLeft } from '../../../table-columns'
import { UpdateHandler } from './UpdateHandler'
import { useTickets_Pending_TicketsPendingRmaQuery as useTicketsPendingRmaQuery } from './__generated__/WindowTable'
import { PaginationControls } from '../../../PaginationControls'
import { DEFAULT_TICKETS_PER_PAGE } from '../../common'
import { StickyTableWrap, StickyTableContainer } from '../../../StickyTable'

interface Row {
  id: TicketId
  device: {
    id: DeviceId
    model: {
      name: string
      image: string | null
    }
  }
  macId: string
  expiresAt?: string | null
  nextHandlerId: CompanyId
  nextHandlerName: string
  failureCategory: string
  description: string
  setSelectedTicket: (ticket: TicketId) => void
}

const HandledByCell = ({ row }: { row: Row }) => {
  if (row.nextHandlerName) {
    return <SupportBy name={row.nextHandlerName} />
  }

  return (
    // TODO: Separate a Link button from an actual Link.
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      onClick={() => {
        row.setSelectedTicket(row.id)
      }}
    >
      Select
    </Link>
  )
}

interface Props {
  onRenderCreateShipmentButton: (el: ReactNode) => void
  searchQuery: string
  stickyHeadTop: string
}

export const TicketsTable: FC<Props> = ({ onRenderCreateShipmentButton, searchQuery, stickyHeadTop }) => {
  const history = useHistory()
  const navigate = useNavigate()
  const nested = useNestedPath()

  const [offset, setOffset] = useState(0)
  const [openUrl, setOpenUrl] = useState('')
  const [limit, setLimit] = useState(DEFAULT_TICKETS_PER_PAGE)
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | undefined>()

  const { loading, error, data } = useTicketsPendingRmaQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cursor: offset.toString(),
      limit,
      filter: {
        search: searchQuery,
      },
    },
  })

  const total = data?.ticketsPendingRma?.pageInfo.total || 0

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedTicket, setSelectedTicket] = useState<TicketId>()

  const handleSendRma = useCallback(() => {
    navigate(nested('/submit'), { selectedIds })
  }, [navigate, nested, selectedIds])

  useEffect(() => {
    if (clickTimeout) {
      clearTimeout(clickTimeout)
    }

    if (openUrl) {
      setClickTimeout(
        setTimeout(() => {
          history.push(nested(openUrl))
          setOpenUrl('')
        }, 100),
      )
    }
  }, [openUrl, setOpenUrl])

  useEffect(() => {
    onRenderCreateShipmentButton(
      <CreateShipmentWrap>
        <Button disabled={!selectedIds.length} onClick={handleSendRma} variant="primary">
          <span data-testid="create-shipment-btn">Create Shipment</span>
        </Button>
      </CreateShipmentWrap>,
    )

    return () => {
      onRenderCreateShipmentButton(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, onRenderCreateShipmentButton])

  return (
    <StickyTableWrap>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        const devices = data?.ticketsPendingRma?.result || []

        const selectedHandler = selectedIds.length
          ? devices.find((device) => selectedIds.includes(device.id))?.nextHandler.id
          : null

        const items = devices.map(
          (item): TableItem<Row> => ({
            id: item.id,
            device: {
              id: item.device.id,
              model: {
                name: item.device.name,
                image: item.device.productImage,
              },
            },
            macId: item.device.mac || '',
            expiresAt: item.expiresAt,
            nextHandlerId: item.nextHandler.id,
            nextHandlerName: item.nextHandler.name,
            description: item.description,
            failureCategory: getFailureCategory(item.handlerFailureCategoryId || item.customerFailureCategoryId),
            disabled: !item.nextHandler.id || (!!selectedHandler && item.nextHandler.id !== selectedHandler),
            setSelectedTicket,
          }),
        )

        return (
          <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
            <Table
              rowHeight={50}
              headerHeight={50}
              initialSortBy="expiresAt"
              multiSelectMode
              disableColumnFilters
              columns={columns}
              items={items}
              selectedIds={selectedIds}
              onRowClick={(item) => {
                setOpenUrl(`/${item.id}`)
              }}
              onChangeSelection={(ids) => {
                if (clickTimeout) {
                  clearTimeout(clickTimeout)
                }
                setOpenUrl('')

                const newSelectedIds = ids as TicketId[]
                newSelectedIds.reverse()

                let nextSelectedHandler: CompanyId | null = null
                for (const selectedId of newSelectedIds) {
                  const device = devices.find((item) => item.id === selectedId)
                  if (device?.nextHandler.id) {
                    nextSelectedHandler = device.nextHandler.id
                    break
                  }
                }

                const filteredIds = newSelectedIds.filter((id) => {
                  const device = devices.find((item) => item.id === id)
                  return device?.nextHandler.id === nextSelectedHandler
                })

                setSelectedIds(filteredIds)
              }}
              renderPlaceholder={() => (
                <Container $padding={['m', 0]}>
                  <Text size="inherit">No open tickets{searchQuery && ' found'}.</Text>
                </Container>
              )}
              renderFooter={() => (
                <PaginationControls
                  pageInfo={{
                    offset,
                    limit,
                    total,
                  }}
                  loadPage={(newPageInfo) => {
                    setOffset(newPageInfo.offset)
                    setLimit(newPageInfo.limit)
                  }}
                  itemsLabel="tickets"
                />
              )}
            />

            {selectedTicket && (
              <UpdateHandler
                ticketId={selectedTicket}
                onClose={() => {
                  setSelectedTicket(undefined)
                }}
              />
            )}
          </StickyTableContainer>
        )
      })()}
    </StickyTableWrap>
  )
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'device',
    label: 'Device',
    sortable: true,
    renderCell: (row) => (
      <Device
        name={row.device.model.name}
        image={<RemoteImage url={row.device.model.image} width={24} height={24} />}
      />
    ),
  },
  {
    id: 'macId',
    label: 'MAC ID / Serial',
    sortable: true,
    renderCell: (row) => formatSerial(row.macId),
  },
  {
    id: 'expiresAt',
    label: 'Time left',
    sortable: true,
    renderCell: (row) => <TimeLeft to={row.expiresAt} />,
  },
  {
    id: 'nextHandlerName',
    label: 'Handled by',
    sortable: true,
    renderCell: (row) => <HandledByCell row={row} />,
  },
  {
    id: 'failureCategory',
    label: 'Failure Category',
    sortable: true,
    renderCell: (row) => <>{row.failureCategory}</>,
  },
  {
    id: 'description',
    label: 'Description',
    renderCell: () => <>-</>,
  },
]

const CreateShipmentWrap = styled.div`
  display: flex;
  margin-left: ${cssVariables['spacing-s']};
`
