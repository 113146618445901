import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useNavigate from '@shared-ui/hooks/useNavigate'

export const RedirectTrailingSlash = () => {
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname.endsWith('/') && pathname !== '/') {
      navigate(pathname.slice(0, -1), state, true)
    }
  }, [navigate, pathname, state])

  return null
}
