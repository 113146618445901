import type { FileId, TicketId } from '@shared/types/brands'
import { TrashIcon } from '@ubnt/icons'
import { Button, cssVariables, Loader } from '@ubnt/ui-components'
import { useCallback, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { getFailureCategory } from 'rma-shared/types/tickets'
import styled from 'styled-components'
import type { File } from '../../../../generated/graphql'
import type { FileUploadFile } from '../../../file-upload'
import { RemoteImage } from '../../../Image'
import { MessageContainer } from '../../../MessageContainer'
import type { TableColumnConfig, TableItem } from '../../../Table'
import { Table } from '../../../Table'
import { Device } from '../../../table-columns'
import type { AttachmentsUpdateInput } from './UpdateAttachments'
import { UpdateAttachments } from './UpdateAttachments'
import { UpdateTicket } from './UpdateTicket'
import { useSubmit_Step1_TicketsPendingRmaQuery as useTicketsPendingRmaQuery } from './__generated__/Step1'

interface Row {
  id: TicketId
  sequenceNumber: number
  model: {
    name: string
    image: string | null
  }
  macId: string
  failure: {
    description: string
    handlerDescription: string | null
    category: string
    rmaParentCategoryId: string | null
  }
  userFiles: readonly Pick<File, 'id' | 'url' | 'filename'>[]
  companyFiles: readonly Pick<File, 'id' | 'url' | 'filename'>[]
  onAttachmentsSave: (input: AttachmentsUpdateInput) => void
  skipFileCopyMap: Map<TicketId, FileId[]>
  newCompanyFilesMap: Map<TicketId, FileUploadFile[]>
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'sequenceNumber',
    label: 'No.',
    renderCell: ({ sequenceNumber }) => sequenceNumber,
    maxWidth: 60,
  },
  {
    id: 'macId',
    label: 'Mac ID',
  },
  {
    id: 'model',
    label: 'Device',
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'failure',
    label: 'Failure Description',
    renderCell: (row) => <FailureDescriptionCell ticket={row} />,
  },
  {
    id: 'userFiles',
    label: 'Attachments',
    renderCell: (row) => <AttachmentsCell ticket={row} />,
  },
]

const FailureDescriptionCell = ({ ticket }: { ticket: Row }) => {
  const [open, setOpen] = useState(false)

  if (open) {
    return (
      <UpdateTicket
        ticket={ticket}
        onClose={() => {
          setOpen(false)
        }}
      />
    )
  }

  return (
    <Button variant="inline" onClick={() => setOpen(true)}>
      View / Edit
    </Button>
  )
}

const AttachmentsCell = ({ ticket }: { ticket: Row }) => {
  const [open, setOpen] = useState(false)

  const onAttachmentsSave = useCallback(
    (input: AttachmentsUpdateInput) => {
      if (ticket.onAttachmentsSave) {
        ticket.onAttachmentsSave(input)
      }
    },
    [ticket],
  )

  if (open) {
    return (
      <UpdateAttachments
        ticket={ticket}
        skipFileCopyMap={ticket.skipFileCopyMap}
        newCompanyFilesMap={ticket.newCompanyFilesMap}
        onSave={onAttachmentsSave}
        onClose={() => {
          setOpen(false)
        }}
      />
    )
  }

  return (
    <Button variant="inline" onClick={() => setOpen(true)}>
      View / Edit
    </Button>
  )
}

type Props = {
  ticketIds: readonly string[]
  skipFileCopyMap: Map<TicketId, FileId[]>
  newCompanyFilesMap: Map<TicketId, FileUploadFile[]>
  onDeleteTicket: (ticketId: TicketId) => void
}

export const Step1 = ({ ticketIds, skipFileCopyMap, newCompanyFilesMap, onDeleteTicket }: Props) => {
  const { data, loading } = useTicketsPendingRmaQuery({ variables: { filter: { ticketIds } } })

  const onAttachmentsSave = useCallback(
    (input: AttachmentsUpdateInput) => {
      if (input.filesRemoveIds.length) {
        skipFileCopyMap.set(input.ticketId, input.filesRemoveIds)
      }
      if (input.newCompanyFiles.length) {
        newCompanyFilesMap.set(input.ticketId, input.newCompanyFiles)
      }
    },
    [skipFileCopyMap, newCompanyFilesMap],
  )

  const items = useMemo(() => {
    return (data?.ticketsPendingRma?.result ?? []).map(
      (item, index): TableItem<Row> => ({
        id: item.id,
        sequenceNumber: index + 1,
        model: {
          name: item.device.name,
          image: item.device.productImage,
        },
        macId: item.device.mac || '',
        failure: {
          description: item.description,
          handlerDescription: item.handlerDescription,
          category: item.handlerFailureCategoryId || item.customerFailureCategoryId,
          rmaParentCategoryId: item.device.product?.rmaParentCategoryId ?? '',
        },
        userFiles: item.userFiles,
        companyFiles: item.companyFiles,
        onAttachmentsSave,
        skipFileCopyMap,
        newCompanyFilesMap,
      }),
    )
  }, [newCompanyFilesMap, skipFileCopyMap, onAttachmentsSave, data?.ticketsPendingRma?.result])

  if (!ticketIds.length) {
    return <Redirect to="/tickets/pending" />
  }

  return (
    <Table
      columns={columns}
      items={items}
      disableColumnFilters
      rowHeight={50}
      headerHeight={50}
      renderHoverContent={(rowData) => (
        <RowActions>
          <DeleteTicketButton title="Delete ticket" variant="tertiary" onClick={() => onDeleteTicket(rowData.id)}>
            <TrashIcon />
          </DeleteTicketButton>
        </RowActions>
      )}
      renderFooter={() => {
        if (loading) {
          return (
            <MessageContainer>
              <Loader size="small" />
            </MessageContainer>
          )
        }

        return null
      }}
    />
  )
}

const RowActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
`

const DeleteTicketButton = (styled(Button)`
  color: ${cssVariables['red-0']};

  &:hover {
    background: transparent;
  }
` as unknown) as typeof Button
