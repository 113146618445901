import { Loader } from '@ubnt/ui-components'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import ConfirmationBackground from '../../../../assets/ConfirmationBackground.png'
import useNavigate from '../../../../hooks/useNavigate'
import { DescriptionList, ModalMinimal } from '../../../index'
import { useShipmentCreationDoneQuery } from './__generated__/Done'

export const Done = () => {
  const navigate = useNavigate()
  const { shipmentId } = useParams<{ shipmentId: string }>()
  const { data, loading } = useShipmentCreationDoneQuery({
    variables: { id: parseInt(shipmentId, 10) },
  })

  const onClose = () => {
    navigate('../..')
  }
  const onViewShipment = () => {
    navigate(`/shipments/active/${shipmentId}`)
  }

  return (
    <ModalMinimal
      isOpen={!loading && !!data?.shipment}
      size="medium"
      onRequestClose={onClose}
      actions={[
        {
          text: <span data-testid="view-shipment-btn">View Shipment</span>,
          variant: 'primary',
          onClick: onViewShipment,
        },
        {
          text: <span data-testid="close-btn">Close</span>,
          variant: 'secondary',
          onClick: onClose,
        },
      ]}
      title={<span data-testid="create-shipment-popup-submitted-title">Your shipment has been created!</span>}
      titleLine
      subtitle={
        data?.shipment ? (
          <>
            <span data-testid="create-shipment-popup-submitted-shipment-no">Shipment No. </span>
            {data.shipment.uid}
          </>
        ) : undefined
      }
      background={ConfirmationBackground}
    >
      {data?.shipment ? (
        <DescriptionList>
          <dt>Number of devices</dt>
          <dd>{data?.shipment.deviceCount}</dd>

          <dt>Shipment to</dt>
          <dd>{data?.shipment.toPartyName}</dd>

          {data?.shipment.shippingCompany && (
            <>
              <dt>Shipment company</dt>
              <dd>{data?.shipment.shippingCompany}</dd>
            </>
          )}

          {data?.shipment.trackingNumber && (
            <>
              <dt>Tracking ID</dt>
              <dd>{data?.shipment.trackingNumber}</dd>
            </>
          )}
        </DescriptionList>
      ) : (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )}
    </ModalMinimal>
  )
}

const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
`
