import { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import useNavigate from '../../hooks/useNavigate'
import useNestedPath from '../../hooks/useNestedPath'
import { ErrorBoundary } from '../ErrorBoundary'
import { FlexItem } from '../Flex'
import { StickyHeader } from '../StickyTable'
import { TicketsTableNav } from '../Tickets/tickets-table-nav'
import { TicketsTableHeader } from '../Tickets/TicketsTableHeader'
import { ShipmentsTableActive } from './shipments-table-active'
import { HistoryTable } from './shipments-table-history'
import { ShipmentTabContainer } from './ShipmentTabContainer'

export function ShipmentsView() {
  const navigate = useNavigate()
  const nested = useNestedPath()
  const [search, setSearch] = useState('')
  const [stickyHeadTop, setStickyHeadTop] = useState('0')

  const active = nested('/active')
  const history = nested('/history')

  return (
    <FlexItem>
      <StickyHeader ref={(el: HTMLDivElement | null) => setStickyHeadTop(`${el?.offsetHeight || 0}px`)}>
        <TicketsTableHeader label="Shipments" searchQuery={search} onChangeSearchQuery={setSearch} />
        <TicketsTableNav
          tabs={[
            {
              id: active,
              label: 'Active',
            },
            {
              id: history,
              label: 'History',
            },
          ]}
        />
      </StickyHeader>

      <ErrorBoundary>
        <Switch>
          <Route path={active}>
            <ShipmentsTableActive search={search} stickyHeadTop={stickyHeadTop} />
          </Route>
          <Route path={history}>
            <HistoryTable search={search} stickyHeadTop={stickyHeadTop} />
          </Route>
          <Route path="*">
            <Redirect to={active} />
          </Route>
        </Switch>
      </ErrorBoundary>

      <Switch>
        <Route path={`${active}/:shipmentId`}>
          <ShipmentTabContainer onClose={() => navigate(active)} />
        </Route>
        <Route path={`${history}/:shipmentId`}>
          <ShipmentTabContainer onClose={() => navigate(history)} />
        </Route>
      </Switch>
    </FlexItem>
  )
}
