import {
  CustomerTab,
  DetailsTab,
  LogTab,
  ManageTab,
  MessagingTab,
  Ticket as TicketShared,
  TicketProvider,
  useTicket,
} from '@shared-ui/components/Ticket'
import { confirm } from '@shared-ui/utils/alerts'
import type { TicketId } from '@shared/types/brands'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDashboardTicketQuery } from './__generated__/Ticket'

export type Props = {
  onClose: () => void
}

type UrlParams = {
  ticketId: TicketId
}

export const Ticket = ({ onClose }: Props) => (
  <TicketProvider>
    <TicketWrapper onClose={onClose} />
  </TicketProvider>
)

const TicketWrapper = ({ onClose }: Props) => {
  const { ticketId } = useParams<UrlParams>()
  const { state } = useTicket()
  const { data, loading, error } = useDashboardTicketQuery({ variables: { id: ticketId } })

  const tabs = useMemo(
    () =>
      [
        { ...DetailsTab, props: { displayCustomer: true } },
        CustomerTab,
        data?.ticket?.supportByUbiquiti === false ? MessagingTab : null,
        LogTab,
        ManageTab,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
      ].filter(<T extends unknown>(tab: T): tab is NonNullable<T> => !!tab),
    [data],
  )

  const wrappedOnClose = () => {
    if (state.unsavedChanges) {
      const leave = confirm('Do you wish to discard Manage tab changes and close this window?')
      if (!leave) {
        return
      }
    }

    onClose()
  }

  return (
    <TicketShared
      tabs={tabs}
      loading={loading}
      error={error}
      ticket={data?.ticket ?? undefined}
      onClose={wrappedOnClose}
    />
  )
}
