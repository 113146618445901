import type { ApolloError } from '@apollo/client'
import { Div } from '@shared-ui/components'
import { Flex, FlexItem } from '@shared-ui/components/Flex'
import { MessageContainer } from '@shared-ui/components/MessageContainer'
import { Bar } from '@shared-ui/components/graphs/AverageRating'
import { Loader, Text } from '@ubnt/ui-components'
import { Heading } from 'components/Heading'
import styled from 'styled-components'
import { LengthStatPeriod } from '../../../../generated/graphql'
import { theme } from '../../../../styles/theme'
import type { DashboardLengthStatsFragment } from './__generated__/AverageDuration'

const lengthsPeriodLabelMap = {
  [LengthStatPeriod.Week1]: '-1w',
  [LengthStatPeriod.Week2]: '2w',
  [LengthStatPeriod.Week3]: '3w',
  [LengthStatPeriod.Week4]: '4w',
  [LengthStatPeriod.Week5]: '5w',
  [LengthStatPeriod.Week6]: '6w',
  [LengthStatPeriod.Week7]: '7w',
  [LengthStatPeriod.Week8Plus]: '8w+',
}

export const AverageDuration = ({
  loading,
  error,
  lengths,
}: {
  loading: boolean
  error?: ApolloError // TODO: Or anything else.
  lengths?: readonly DashboardLengthStatsFragment[]
}) => {
  return (
    <Div className="flex flex-column flex-1">
      <Heading size="header-xs" weight="bold" underline>
        Average Ticket Duration
      </Heading>

      {(() => {
        if (error || loading || !lengths) {
          return (
            <MessageContainer>
              {/* eslint-disable no-nested-ternary */}
              {error ? (
                <Text>Error loading data.</Text>
              ) : loading ? (
                <Loader size="small" />
              ) : (
                <Text>No data available.</Text>
              )}
              {/* eslint-enable no-nested-ternary */}
            </MessageContainer>
          )
        }

        const max = Math.max(0, ...lengths.map((length) => length.count))
        const entries = [...lengths].reverse()

        return (
          <FlexItem>
            <FlexItem>
              <Head>
                <CurrentPeriod>Today</CurrentPeriod>
                {/* <Text>/</Text>
              <PreviousPeriod>Yesterday</PreviousPeriod> */}
              </Head>
            </FlexItem>

            <Flex>
              <DurationContainer>
                {entries.map((length) => (
                  <Text key={length.period}>{lengthsPeriodLabelMap[length.period]}</Text>
                ))}
              </DurationContainer>
              <FlexItem>
                {entries.map((length) => {
                  const value = (length.count / max) * 100

                  return (
                    <Flex key={length.period} alignCenter>
                      <Bar
                        height={15}
                        items={[{ value, color: theme.colors.gradientBlue }]}
                        itemTooltip={() => ({
                          message: `${length.count} tickets`,
                          ...theme.tooltip,
                        })}
                      />
                    </Flex>
                  )
                })}
              </FlexItem>
            </Flex>
          </FlexItem>
        )
      })()}
    </Div>
  )
}

const Head = styled(Flex).attrs({ justifyEnd: true })`
  margin-bottom: ${(props) => props.theme.spacing.xxl};
`

const DurationContainer = styled(Flex).attrs({ column: true, justifyAround: true })`
  margin-right: ${(props) => props.theme.spacing.xxl};
`

const CurrentPeriod = styled(Text)`
  color: ${(props) => props.theme.colors.grey1};
`
