import { ArrowLeftPrimaryIcon, ArrowRightPrimaryIcon } from '@ubnt/icons'
import type { CSSProperties } from 'react'
import Select from 'react-select'
import type { PaginationInfo } from 'rma-shared/types/table'
import styled from 'styled-components'

interface Props {
  pageInfo?: PaginationInfo
  loadPage: (pageInfo: PaginationInfo) => void
  pageSizeOptions?: number[]
  itemsLabel: string
  style?: CSSProperties
}

export function PaginationControls({
  pageInfo = { limit: 100, offset: 0, total: 0 },
  loadPage,
  itemsLabel,
  pageSizeOptions = [10, 50, 100],
  style,
}: Props) {
  if (pageInfo.total === 0) {
    return null
  }

  const pageLimit = pageInfo.limit || 100

  return (
    <PaginationWrapper style={style}>
      <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        {pageInfo.total ? pageInfo.offset + 1 : 0} -{' '}
        {pageInfo.total < pageLimit + pageInfo.offset ? pageInfo.total : pageInfo.offset + pageLimit} of{' '}
        {pageInfo.total} {itemsLabel}
      </div>

      {pageInfo.total >= pageSizeOptions[0] && (
        <>
          <div className="ml-12 mr-34">
            <PaginationButton
              onClick={() =>
                loadPage({
                  total: pageInfo.total,
                  limit: pageInfo.limit,
                  offset: pageInfo.offset - pageLimit,
                })
              }
              disabled={pageInfo.offset === 0}
            >
              <ArrowLeftPrimaryIcon />
            </PaginationButton>
            <PaginationButton
              onClick={() =>
                loadPage({
                  total: pageInfo.total,
                  limit: pageInfo.limit,
                  offset: pageInfo.offset + pageLimit,
                })
              }
              disabled={pageInfo.offset + pageLimit >= pageInfo.total}
            >
              <ArrowRightPrimaryIcon />
            </PaginationButton>
          </div>
          <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Rows per page:</div>
          <div>
            <PageDropdown
              options={pageSizeOptions.map((option) => ({ value: option, label: `${option}` }))}
              value={pageInfo.limit}
              onChange={(option) => {
                loadPage({
                  total: pageInfo.total,
                  limit: Number(option),
                  offset: 0,
                })
              }}
            />
          </div>
        </>
      )}
    </PaginationWrapper>
  )
}

function PageDropdown({
  options,
  value,
  onChange,
}: {
  options: { value: string | number; label: string | number }[]
  value?: string | number
  onChange: (value: string | number) => void
}) {
  return (
    <Select
      options={options}
      menuPlacement="auto"
      value={{ value, label: value }}
      onChange={(option: any) => onChange(String(option.value))}
      isSearchable={false}
      components={{
        DropdownIndicator: () => (
          <>
            <ArrowLeftPrimaryIcon color="#595959" width="7" height="8" />
            <ArrowRightPrimaryIcon color="#595959" width="7" height="8" />
          </>
        ),
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          marginLeft: '12px',
          width: '64px',
          height: '24px',
          boxShadow: '0 1px 0 0 #DBDCE1',
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          border: 'none',
          cursor: 'pointer',
          height: '24px',
          minHeight: '24px',
          maxHeight: '24px',
        }),
        indicatorsContainer: (provided, state) => {
          return {
            ...provided,
            marginRight: '6px',
            height: '24px',
            transform: 'rotate(-90deg)',
          }
        },
        indicatorSeparator: (provided) => ({
          ...provided,
          width: '0',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#F8F8F9' : 'white',
          cursor: 'pointer',
          color: '#A5A5A5',
          '&:hover': {
            backgroundColor: '#F8F8F9',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          marginLeft: '0',
          marginRight: '0',
          color: '#595959',
        }),
      }}
    />
  )
}

const PaginationWrapper = styled.div`
  padding: 0;
  color: ${(props) => props.theme.colors.text.primary};
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 12px;
  margin-top: 16px;
`

const PaginationButton = styled.button<{ disabled?: boolean }>`
  height: 26px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid transparent;

  border: 1px solid #dbdce1;
  color: #a4a7b5;
  cursor: pointer;
  padding: 0;

  :hover {
    border: 1px solid #aaacb8;
    color: #7d8296;
  }

  :focus {
    outline: none;
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-right: -1px;
  }

  &:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}
`
