import { TICKET_FAILURE_DESCRIPTION_MAX_LENGTH } from '@shared/tickets'
import type { NetsuiteFailureCategoryId, TicketId } from '@shared/types/brands'
import { Loader, Modal } from '@ubnt/ui-components'
import { useState } from 'react'
import { Container } from '../../../Container'
import { FailureCategory } from '../../../failure-category'
import { Flex } from '../../../Flex'
import { Grid } from '../../../Grid'
import { ParagraphText } from '../../../ParagraphText'
import { RequestError } from '../../../RequestError'
import { Text } from '../../../Text'
import { TextArea } from '../../../text-area'
import { useUpdateTicket_UpdateTicketMutation as useUpdateTicketMutation } from './__generated__/UpdateTicket'

export type Props = {
  onClose: () => void
  ticket: {
    id: TicketId
    failure: {
      description: string
      handlerDescription: string | null
      category: NetsuiteFailureCategoryId
      rmaParentCategoryId: string | null
    }
  }
}

export const UpdateTicket = ({ ticket, onClose }: Props) => {
  const [updateTicket, { loading: updateLoading, error: updateError }] = useUpdateTicketMutation()
  const [failureCategoryId, setFailureCategoryId] = useState<NetsuiteFailureCategoryId>()
  const [description, setDescription] = useState(ticket.failure.handlerDescription || '')

  const savedFailureCategory = ticket.failure.category
  const unsavedChanges = (failureCategoryId && failureCategoryId !== savedFailureCategory) || description

  return (
    <Modal
      isOpen
      title="RMA Ticket"
      onRequestClose={onClose}
      actions={[
        {
          text: 'Cancel',
          variant: 'secondary',
          onClick: onClose,
          disabled: updateLoading,
        },
        {
          text: 'Save',
          variant: 'primary',
          onClick: async () => {
            try {
              await updateTicket({
                variables: {
                  input: {
                    ticketId: ticket.id,
                    handlerFailureCategoryId: failureCategoryId,
                    handlerDescription: description,
                  },
                },
              })
              onClose()
            } catch {
              //
            }
          },
          disabled: !unsavedChanges || updateLoading,
        },
      ]}
    >
      <Container $minHeight="170px">
        <Grid $container direction="column" gap="m">
          <Grid $item $xs={12}>
            <FailureCategory
              failureCategoryId={failureCategoryId || savedFailureCategory}
              onChange={(option) => {
                setFailureCategoryId(option.value as NetsuiteFailureCategoryId)
              }}
            />
          </Grid>

          <Grid $item $xs={12} $container gap="s">
            <Grid $item $xs={12}>
              <Text color="tertiary" block>
                User&#39;s description:
              </Text>
              <ParagraphText>{ticket.failure.description}</ParagraphText>
            </Grid>
            <Grid $item $xs={12}>
              <TextArea
                label="Additional description"
                placeholder="Write about the failure here"
                maxLength={TICKET_FAILURE_DESCRIPTION_MAX_LENGTH}
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
            </Grid>
          </Grid>

          {(updateLoading || updateError) && (
            <Grid $item $xs={12}>
              <Flex center>{updateError ? <RequestError error={updateError} /> : <Loader size="small" />}</Flex>
            </Grid>
          )}
        </Grid>
      </Container>
    </Modal>
  )
}
