// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../generated/graphql'

export type ReviveTicketMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['TicketId']
}>

export type ReviveTicketMutation = { readonly __typename?: 'Mutation' } & {
  readonly reviveTicket: { readonly __typename?: 'ReviveTicketResponse' } & {
    readonly ticket: Types.Maybe<{ readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id' | 'expiresAt'>>
  }
}

export const ReviveTicketDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReviveTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviveTicket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ticketId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type ReviveTicketMutationFn = Apollo.MutationFunction<ReviveTicketMutation, ReviveTicketMutationVariables>

/**
 * __useReviveTicketMutation__
 *
 * To run a mutation, you first call `useReviveTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviveTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviveTicketMutation, { data, loading, error }] = useReviveTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useReviveTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<ReviveTicketMutation, ReviveTicketMutationVariables>,
) {
  return Apollo.useMutation<ReviveTicketMutation, ReviveTicketMutationVariables>(ReviveTicketDocument, baseOptions)
}
export type ReviveTicketMutationHookResult = ReturnType<typeof useReviveTicketMutation>
export type ReviveTicketMutationResult = Apollo.MutationResult<ReviveTicketMutation>
export type ReviveTicketMutationOptions = Apollo.BaseMutationOptions<
  ReviveTicketMutation,
  ReviveTicketMutationVariables
>
