import formatSerial from '@shared/lib/utils/format/serial'
import type { IncomingTicket } from '@shared/types/tickets'
import { QueryTicketType } from '@shared/types/tickets'
import type { TableColumnConfig } from '@ubnt/ui-components'
import { Button, Loader, Table, Text } from '@ubnt/ui-components'
import { useEffect } from 'react'
import { useGoTo, useRequest } from '../hooks'
import { useTicketsStore } from '../tickets/tickets-store'
import { LoaderContainer } from './Containers'
import { Rating } from './Rating'
import { StickyTableContainer } from './StickyTable'
import { Customer, Device, Status, TicketIdRow, TimeElapsed, Tracking } from './table-columns'
import { RemoteImage } from './Image'

export const DashboardTicketsTable = () => {
  const [fetch, { loading, error }] = useRequest('incomingTickets')

  const { tickets, pageInfo, reset } = useTicketsStore()

  const limit = 25
  const hasMore = pageInfo.total > pageInfo.offset + limit

  const handleFetchMore = async (extraOffset: number) => {
    try {
      const result = await fetch({
        limit,
        offset: pageInfo.offset + extraOffset,
        search: '',
        type: QueryTicketType.All,
      })

      const newTickets = [...tickets, ...result.tickets]
      useTicketsStore.setState({
        tickets: newTickets,
        pageInfo: result.pageInfo,
      })
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    void handleFetchMore(0)

    return () => reset()
  }, [])

  const goTo = useGoTo()

  const renderFooter = () => {
    if (loading) {
      return (
        <div className="py-12">
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </div>
      )
    }

    if (!hasMore) {
      return null
    }

    return (
      <div className="py-12">
        <Button variant="secondary" onClick={() => handleFetchMore(limit)}>
          Fetch more
        </Button>
      </div>
    )
  }

  const initialLoading = loading && !tickets.length

  return (
    <div className="flex-basis-0 flex-grow full-height scroll-y">
      {initialLoading && (
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      )}

      {error && (
        <LoaderContainer>
          <Text size="inherit">Error loading data.</Text>
        </LoaderContainer>
      )}

      {!initialLoading && !error && (
        <StickyTableContainer $noItems={!tickets.length}>
          <Table
            rowHeight={50}
            headerHeight={50}
            initialSortBy="createdAt"
            columns={columns}
            items={tickets}
            disableColumnFilters
            onRowClick={(item) => goTo(`/${item.id}`)}
            renderPlaceholder={() => (
              <div className="py-12">
                <Text size="inherit">No incoming tickets.</Text>
              </div>
            )}
          />
          {renderFooter()}
        </StickyTableContainer>
      )}
    </div>
  )
}

const columns: TableColumnConfig<IncomingTicket>[] = [
  {
    id: 'id',
    label: 'Ticket no.',
    growthFactor: 1,
    renderCell: (row) => <TicketIdRow ticketId={row.id} />,
  },
  {
    id: 'sku',
    label: 'Device',
    growthFactor: 1,
    renderCell: (row) => <Device name={row.sku} image={<RemoteImage url={row.image} width={24} height={24} />} />,
  },
  {
    id: 'mac',
    label: 'MAC ID / Serial',
    growthFactor: 1,
    renderCell: (row) => formatSerial(row.mac),
  },
  {
    id: 'createdBy',
    label: 'Customer',
    growthFactor: 1,
    renderCell: (row) => <Customer id={row.id} name={row.createdBy} />,
  },
  {
    id: 'createdAt',
    label: 'Time elapsed',
    growthFactor: 1,
    renderCell: (row) => <TimeElapsed from={row.createdAt} />,
  },
  {
    id: 'status',
    label: 'Status',
    growthFactor: 1,
    renderCell: (row) => <Status id={row.id} status={row.status} />,
  },
  {
    id: 'rating',
    label: 'Rating',
    growthFactor: 1,
    renderCell: (row) => <Rating readonly size="medium" value={row.rating || 0} />,
  },
  {
    id: 'trackingNumber',
    label: 'Tracking',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <Tracking ticketId={row.id} trackingNumber={row.trackingNumber} />,
  },
]
