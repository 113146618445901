// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

import type { DashboardRatingStatsFragment } from './AverageRating'
import type { DashboardStatusesStatsFragment } from './TicketStatus'
import type { DashboardLengthStatsFragment } from './AverageDuration'

export type DashboardTicketStatsQueryVariables = Types.Exact<{ [key: string]: never }>

export type DashboardTicketStatsQuery = { readonly __typename?: 'Query' } & {
  readonly ticketStats: { readonly __typename?: 'TicketStats' } & {
    readonly ratings: { readonly __typename?: 'RatingStats' } & DashboardRatingStatsFragment
    readonly statuses: ReadonlyArray<{ readonly __typename?: 'StatusesStats' } & DashboardStatusesStatsFragment>
    readonly lengths: ReadonlyArray<{ readonly __typename?: 'LengthStats' } & DashboardLengthStatsFragment>
  }
}

export const DashboardTicketStatsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardTicketStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardRatingStats' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardStatusesStats' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lengths' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardLengthStats' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardRatingStats' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RatingStats' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'average' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'average' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardStatusesStats' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StatusesStats' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardLengthStats' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LengthStats' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'period' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
        ],
      },
    },
  ],
}

/**
 * __useDashboardTicketStatsQuery__
 *
 * To run a query within a React component, call `useDashboardTicketStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTicketStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTicketStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardTicketStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<DashboardTicketStatsQuery, DashboardTicketStatsQueryVariables>,
) {
  return Apollo.useQuery<DashboardTicketStatsQuery, DashboardTicketStatsQueryVariables>(
    DashboardTicketStatsDocument,
    baseOptions,
  )
}
export function useDashboardTicketStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardTicketStatsQuery, DashboardTicketStatsQueryVariables>,
) {
  return Apollo.useLazyQuery<DashboardTicketStatsQuery, DashboardTicketStatsQueryVariables>(
    DashboardTicketStatsDocument,
    baseOptions,
  )
}
export type DashboardTicketStatsQueryHookResult = ReturnType<typeof useDashboardTicketStatsQuery>
export type DashboardTicketStatsLazyQueryHookResult = ReturnType<typeof useDashboardTicketStatsLazyQuery>
export type DashboardTicketStatsQueryResult = Apollo.QueryResult<
  DashboardTicketStatsQuery,
  DashboardTicketStatsQueryVariables
>
