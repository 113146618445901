// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type SignUpResellerMutationVariables = Types.Exact<{
  input: Types.ResellerSignUpInput
}>

export type SignUpResellerMutation = { readonly __typename?: 'Mutation' } & Pick<Types.Mutation, 'resellerSignUp'>

export const SignUpResellerDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignUpReseller' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResellerSignUpInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resellerSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
}
export type SignUpResellerMutationFn = Apollo.MutationFunction<SignUpResellerMutation, SignUpResellerMutationVariables>

/**
 * __useSignUpResellerMutation__
 *
 * To run a mutation, you first call `useSignUpResellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpResellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpResellerMutation, { data, loading, error }] = useSignUpResellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpResellerMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpResellerMutation, SignUpResellerMutationVariables>,
) {
  return Apollo.useMutation<SignUpResellerMutation, SignUpResellerMutationVariables>(
    SignUpResellerDocument,
    baseOptions,
  )
}
export type SignUpResellerMutationHookResult = ReturnType<typeof useSignUpResellerMutation>
export type SignUpResellerMutationResult = Apollo.MutationResult<SignUpResellerMutation>
export type SignUpResellerMutationOptions = Apollo.BaseMutationOptions<
  SignUpResellerMutation,
  SignUpResellerMutationVariables
>
