// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

import type { Tickets_Pending_NextHandlerFragment } from './WindowTable'

export type UpdateHandler_DeviceSellerParentsQueryVariables = Types.Exact<{ [key: string]: never }>

export type UpdateHandler_DeviceSellerParentsQuery = { readonly __typename?: 'Query' } & {
  readonly myCompany: Types.Maybe<
    { readonly __typename?: 'Company' } & {
      readonly parents: ReadonlyArray<{ readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name'>>
    }
  >
}

export type UpdateHandler_UpdateTicketDeviceMutationVariables = Types.Exact<{
  input: Types.UpdateTicketDeviceInput
}>

export type UpdateHandler_UpdateTicketDeviceMutation = { readonly __typename?: 'Mutation' } & {
  readonly updateTicketDevice: { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> &
    Tickets_Pending_NextHandlerFragment
}

export const UpdateHandler_DeviceSellerParentsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateHandler_DeviceSellerParents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useUpdateHandler_DeviceSellerParentsQuery__
 *
 * To run a query within a React component, call `useUpdateHandler_DeviceSellerParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandler_DeviceSellerParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateHandler_DeviceSellerParentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateHandler_DeviceSellerParentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpdateHandler_DeviceSellerParentsQuery,
    UpdateHandler_DeviceSellerParentsQueryVariables
  >,
) {
  return Apollo.useQuery<UpdateHandler_DeviceSellerParentsQuery, UpdateHandler_DeviceSellerParentsQueryVariables>(
    UpdateHandler_DeviceSellerParentsDocument,
    baseOptions,
  )
}
export function useUpdateHandler_DeviceSellerParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateHandler_DeviceSellerParentsQuery,
    UpdateHandler_DeviceSellerParentsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<UpdateHandler_DeviceSellerParentsQuery, UpdateHandler_DeviceSellerParentsQueryVariables>(
    UpdateHandler_DeviceSellerParentsDocument,
    baseOptions,
  )
}
export type UpdateHandler_DeviceSellerParentsQueryHookResult = ReturnType<
  typeof useUpdateHandler_DeviceSellerParentsQuery
>
export type UpdateHandler_DeviceSellerParentsLazyQueryHookResult = ReturnType<
  typeof useUpdateHandler_DeviceSellerParentsLazyQuery
>
export type UpdateHandler_DeviceSellerParentsQueryResult = Apollo.QueryResult<
  UpdateHandler_DeviceSellerParentsQuery,
  UpdateHandler_DeviceSellerParentsQueryVariables
>
export const UpdateHandler_UpdateTicketDeviceDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateHandler_UpdateTicketDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTicketDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTicketDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Tickets_Pending_NextHandler' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tickets_Pending_NextHandler' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextHandler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UpdateHandler_UpdateTicketDeviceMutationFn = Apollo.MutationFunction<
  UpdateHandler_UpdateTicketDeviceMutation,
  UpdateHandler_UpdateTicketDeviceMutationVariables
>

/**
 * __useUpdateHandler_UpdateTicketDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateHandler_UpdateTicketDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandler_UpdateTicketDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandlerUpdateTicketDeviceMutation, { data, loading, error }] = useUpdateHandler_UpdateTicketDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHandler_UpdateTicketDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHandler_UpdateTicketDeviceMutation,
    UpdateHandler_UpdateTicketDeviceMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateHandler_UpdateTicketDeviceMutation,
    UpdateHandler_UpdateTicketDeviceMutationVariables
  >(UpdateHandler_UpdateTicketDeviceDocument, baseOptions)
}
export type UpdateHandler_UpdateTicketDeviceMutationHookResult = ReturnType<
  typeof useUpdateHandler_UpdateTicketDeviceMutation
>
export type UpdateHandler_UpdateTicketDeviceMutationResult = Apollo.MutationResult<UpdateHandler_UpdateTicketDeviceMutation>
export type UpdateHandler_UpdateTicketDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpdateHandler_UpdateTicketDeviceMutation,
  UpdateHandler_UpdateTicketDeviceMutationVariables
>
