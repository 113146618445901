import { Authenticated, history, PageNotFound, PageServiceUnavailable } from '@shared-ui/auth'
import { useAuthStore } from '@shared-ui/auth/auth-store'
import { GlobalStyle, NavigationBar, Title, Vertical } from '@shared-ui/components'
import { AllUsersIcon } from '@shared-ui/components/CommonIcons'
import {
  AppContainer,
  HeaderContainer,
  MediaContainer,
  ScrollContainer,
  ScrollContent,
} from '@shared-ui/components/Containers'
import { ErrorBoundary } from '@shared-ui/components/ErrorBoundary'
import { Header } from '@shared-ui/components/header/Header'
import { BoxIcon, InboxIcon } from '@shared-ui/components/icons'
import { VerticalScroll } from '@shared-ui/components/Layout'
import { Shipments } from '@shared-ui/components/shipments'
import { RmaTickets } from '@shared-ui/components/Tickets'
import { TosAgreement } from '@shared-ui/components/TosAgreement'
import { setPortal } from '@shared-ui/utils/isPortal'
import { TosVersion } from '@shared/lib/constants'
import { DashboardIcon, SettingsIcon } from '@ubnt/icons'
import Footer from 'components/Footer'
import { RedirectTrailingSlash } from 'components/RedirectTrailingSlash'
import type { ReactNode } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { CustomerTickets } from './customer-tickets/customer-tickets'
import { Dashboard } from './dashboard'
import { PolicyAndProcedures } from './documents/policy-and-procedures'
import { TermsOfService } from './documents/terms-of-service'
import { UserGuide } from './documents/user-guide'
import { Invitation } from './join'
import { Settings } from './settings/Settings'

setPortal('VAR', ' - Reseller Portal | Ubiquiti')

const Navigation = () => {
  return (
    <NavigationBar
      nav={[
        { icon: <DashboardIcon size="large" />, label: 'Dashboard', path: '/dashboard' },
        { icon: <AllUsersIcon />, label: 'Customer Tickets', path: '/customer-tickets' },
        { icon: <InboxIcon />, label: 'RMA Requests to Distributors', path: '/tickets' },
        { icon: <BoxIcon />, label: 'Shipments', path: '/shipments' },
      ]}
      bottom={[{ icon: <SettingsIcon />, label: 'Settings', path: '/settings' }]}
    />
  )
}

const AppBase = ({ children, inlineFooter }: { children: ReactNode; inlineFooter?: boolean }) => {
  return (
    <MediaContainer>
      <Navigation />
      <ScrollContainer>
        <ScrollContent $inline={inlineFooter}>
          <ErrorBoundary>{children}</ErrorBoundary>
          <Footer />
        </ScrollContent>
      </ScrollContainer>
    </MediaContainer>
  )
}

const DashboardView = () => (
  <AppBase>
    <Title title="Dashboard" />
    <Dashboard />
  </AppBase>
)

const CustomerTicketsView = () => (
  <AppBase>
    <Title title="Customer Tickets" />
    <CustomerTickets />
  </AppBase>
)

const SettingsView = () => (
  <AppBase inlineFooter>
    <Title title="Settings" />
    <Settings />
  </AppBase>
)

const ShipmentsView = () => (
  <AppBase>
    <Title title="Shipments" />
    <Shipments />
  </AppBase>
)

const TicketsView = () => (
  <AppBase>
    <Title title="RMA Requests to Distributors" />
    <RmaTickets label="RMA Requests to Distributors" />
  </AppBase>
)

const CookiesPolicyView = () => (
  <AppBase>
    <Title title="Cookies Policy" />
    <Vertical style={{ padding: '30px 60px' }}>
      <div id="ot-sdk-cookie-policy" />
    </Vertical>
  </AppBase>
)

const AppContent = () => {
  const { user } = useAuthStore()

  const isTosAccepted = user?.tosVersionAccepted === TosVersion
  if (!isTosAccepted) {
    return (
      <>
        <TosAgreement />
        <Footer />
      </>
    )
  }

  return (
    <Switch>
      <Route path="/dashboard">
        <DashboardView />
      </Route>
      <Route path="/customer-tickets">
        <CustomerTicketsView />
      </Route>
      <Route path="/tickets">
        <TicketsView />
      </Route>
      <Route path="/shipments">
        <ShipmentsView />
      </Route>
      <Route path="/settings">
        <SettingsView />
      </Route>
      <Route path="/cookies-policy">
        <CookiesPolicyView />
      </Route>
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  )
}

const AppInvitation = () => {
  const { user } = useAuthStore()

  const isTosAccepted = user?.tosVersionAccepted === TosVersion
  if (!isTosAccepted) {
    return (
      <>
        <TosAgreement />
        <Footer />
      </>
    )
  }

  return <Invitation />
}

export const App = () => (
  <AppContainer>
    <GlobalStyle />
    <Router history={history}>
      <RedirectTrailingSlash />
      <HeaderContainer>
        <Header />
      </HeaderContainer>

      <Switch>
        <Route path="/join/:hash">
          <AppInvitation />
        </Route>

        <Route path="/terms-of-service">
          <VerticalScroll>
            <TermsOfService />
            <Footer />
          </VerticalScroll>
        </Route>

        <Route path="/policy-and-procedures">
          <PolicyAndProcedures />
          <Footer />
        </Route>
        <Route path="/user-guide">
          <UserGuide />
          <Footer />
        </Route>

        <Route path="/404">
          <PageNotFound />
        </Route>
        <Route path="/50x">
          <PageServiceUnavailable />
        </Route>

        <Route>
          <Authenticated>
            <AppContent />
          </Authenticated>
        </Route>
      </Switch>
    </Router>
  </AppContainer>
)
