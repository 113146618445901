import { Button } from '@ubnt/ui-components'
import { useState } from 'react'
import type { TicketId } from 'rma-shared/types/brands'
import { TICKET_EXPIRATION_DAYS, TICKET_REVIVE_COUNT_MAX } from '@shared/tickets/constants'
import { ModalMinimal } from './ModalMinimal'
import { useReviveTicketMutation } from './__generated__/SharedComponents'

interface ReviveTicketProps {
  ticketId: TicketId
  revivedCount: number
}
export const ReviveTicket = ({ ticketId, revivedCount }: ReviveTicketProps) => {
  const [showRevive, setShowRevive] = useState(false)
  const [reviveTicket, { loading, error }] = useReviveTicketMutation()

  const handleClick = () => {
    setShowRevive(true)
  }

  const handleRevive = async () => {
    try {
      await reviveTicket({ variables: { ticketId } })
      setShowRevive(false)
    } catch {
      //
    }
  }

  return (
    <>
      <Button variant="secondary" disabled={revivedCount >= TICKET_REVIVE_COUNT_MAX} onClick={handleClick}>
        Restore Ticket
      </Button>

      {showRevive && (
        <ModalMinimal
          onRequestClose={() => setShowRevive(false)}
          loading={loading}
          error={error?.message}
          actions={[
            {
              text: 'Cancel',
              variant: 'secondary',
              onClick: () => setShowRevive(false),
            },
            {
              text: 'Revive',
              variant: 'primary',
              onClick: handleRevive,
            },
          ]}
          title="Restore This Ticket?"
        >
          Once restored, this ticket will be active for another {TICKET_EXPIRATION_DAYS} days but your RMA performance
          score will be impacted.
        </ModalMinimal>
      )}
    </>
  )
}
