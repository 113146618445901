import { useAuthStore } from '@shared-ui/auth/auth-store'
import {
  Button,
  CompanyAddress,
  Container,
  Flex,
  Grid,
  RemoteImage,
  sanitizeCompanyAddress,
  Text,
} from '@shared-ui/components'
import { useToast } from '@shared-ui/contexts/ToastContext'
import useNavigate from '@shared-ui/hooks/useNavigate'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { ButtonGroup, Loader } from '@ubnt/ui-components'
import { SsoUserRole } from 'generated/graphql'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  useFetchMyCompaniesQuery,
  useJoin_Invitation_InvitationRelationQuery as useInvitationRelationQuery,
  useJoin_Invitation_JoinResellerMutation as useJoinResellerMutation,
} from './__generated__/Invitation'
import { SignupForm } from './SignupForm'

enum InviteErrorMessage {
  SameId = 'You can not accept invitations from your account. Please send this link to a reseller.',
  AlreadyLinked = 'Could not accept the invitation. You are already linked to the distributor.',
  AlreadyUsed = 'This invitation has been used. If you have not used this invitation link, please contact one of your distributors to receive a new invitation link.',
  Expired = 'The invitation has expired. Please contact one of your distributors to receive a new invitation link.',
}

const SessionJustRegisteredKey = 'invitation-just-registered'
const SessionWasRegisteredKey = 'invitation-was-registered'

const TO_DASHBOARD = 'Go to dashboard'

const InvitationHolder = ({ children }: { children: ReactNode }) => {
  return (
    <Container $maxWidth="500px" $padding={['xl', 0]} $center>
      <Grid $container gap="xl">
        <Grid $item $xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  )
}

const InvitationError = ({ text }: { text: string }) => {
  return (
    <Grid $container direction="column" $alignItems="center" gap="s">
      <Grid $item>
        <Text block>{text}</Text>
      </Grid>

      <Grid $item />
      <Grid $item>
        <Text>
          <Link to="/">{TO_DASHBOARD}</Link>
        </Text>
      </Grid>
    </Grid>
  )
}

type InvitationCompany = { logo: string | null; name: string }

type InvitationBodyProps = {
  children: ReactNode
  distributor: InvitationCompany
}
const InvitationBody = ({ children, distributor }: InvitationBodyProps) => {
  return (
    <Grid $container direction="column" justify="center" $alignItems="center">
      <Grid $item>
        <CompanyLogo company={distributor} />
        <Heading>Invitation from {distributor.name}</Heading>
      </Grid>

      <Grid $item $xs={12}>
        <Grid $container direction="column" gap="m">
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

const InvitationLoader = () => (
  <InvitationHolder>
    <Flex full center>
      <Loader />
    </Flex>
  </InvitationHolder>
)

interface UrlParam {
  hash: string
}
export const Invitation = () => {
  const { hash } = useParams<UrlParam>()
  const { user } = useAuthStore()
  const { showNotification, removeNotification } = useToast()
  const navigate = useNavigate()

  const isRegistered = user?.ssoRoles.includes(SsoUserRole.Var)

  const { loading: relationLoading, error: relationError, data: relationData } = useInvitationRelationQuery({
    variables: { hash },
  })
  const [
    joinCompany,
    { called: joinCalled, loading: joinLoading, error: joinError, data: joinData },
  ] = useJoinResellerMutation()

  const { loading: companiesLoading, data: companiesData } = useFetchMyCompaniesQuery()

  const distributorName = relationData?.invitationRelation?.parentCompany?.name || 'distributor'
  const distributorId = relationData ? relationData?.invitationRelation?.parentCompany?.id : undefined
  const showSuccess =
    (joinCalled && joinData?.resellerJoin === true) ||
    (distributorId && sessionStorage.getItem(SessionJustRegisteredKey) === distributorId)

  useEffect(() => {
    if (sessionStorage.getItem(SessionWasRegisteredKey) === null && isRegistered !== undefined) {
      sessionStorage.setItem(SessionWasRegisteredKey, String(isRegistered))
    }
  }, [user, isRegistered])

  useEffect(() => {
    if (showSuccess && isRegistered) {
      const wasRegistered = sessionStorage.getItem(SessionWasRegisteredKey) === 'true'

      showNotification({
        id: 'var-registered-success',
        title: wasRegistered ? 'Success!' : 'Your account has been created!',
        details: wasRegistered
          ? `Your reseller account has been linked to ${distributorName}`
          : 'Welcome to our reseller community and your new dashboard! Here, you can review tickets and explore your RMA tools.',
        stateIndicator: 'success',
        duration: 10000,
        primaryButton: {
          label: 'Got it!',
          onClick: () => removeNotification('var-registered-success'),
        },
      })

      navigate('/')

      sessionStorage.removeItem(SessionJustRegisteredKey)
      sessionStorage.removeItem(SessionWasRegisteredKey)
    }
  }, [showSuccess, isRegistered, showNotification, removeNotification, navigate, distributorName])

  if (relationLoading || companiesLoading || joinLoading) {
    return <InvitationLoader />
  }

  if (relationError) {
    return (
      <InvitationHolder>
        <InvitationError text="Could not load invitation data." />
      </InvitationHolder>
    )
  }

  if (!relationData?.invitationRelation) {
    return (
      <InvitationHolder>
        <InvitationError text="No standing invitation found." />
      </InvitationHolder>
    )
  }

  const { childCompany: reseller, parentCompany: distributor, expiresAt, joinedAt } = relationData.invitationRelation
  const joinFailed = joinError || (joinCalled && joinData?.resellerJoin !== true)
  const distributorAddress = sanitizeCompanyAddress(distributor)
  const isExpired = new Date(expiresAt) < new Date()

  let isParent = reseller?.parents.some((parent) => parent.id === distributor.id)
  if (!isParent) {
    isParent = companiesData?.myCompanies.some((company) =>
      company.parents?.some((parentCompany) => parentCompany.id === distributor.id),
    )
  }

  let invitationStatus: InviteErrorMessage | null = null
  if (reseller && distributor.id === reseller.id) {
    invitationStatus = InviteErrorMessage.SameId
  } else if (isRegistered && isParent) {
    invitationStatus = InviteErrorMessage.AlreadyLinked
  } else if (joinedAt) {
    invitationStatus = InviteErrorMessage.AlreadyUsed
  } else if (isExpired) {
    invitationStatus = InviteErrorMessage.Expired
  }

  const handleJustRegistered = () => {
    sessionStorage.setItem(SessionJustRegisteredKey, distributor.id)
  }

  const goToDashboard = () => {
    sessionStorage.removeItem(SessionJustRegisteredKey)
    navigate('/')
  }

  const onInvitationAccept = async () => {
    try {
      await joinCompany({ variables: { hash } })
    } catch {
      //
    }
  }

  if (invitationStatus) {
    return (
      <InvitationHolder>
        <InvitationBody distributor={distributor}>
          <Grid $item>
            <Grid $container gap="m" flex="grow">
              <Grid $container wrap="nowrap">
                <IconHolder>
                  <ExclamationMarkIcon />
                </IconHolder>

                <Text full block>
                  {invitationStatus}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid $item />
          <Grid $item>
            <Button variant="primary" onClick={goToDashboard}>
              {TO_DASHBOARD}
            </Button>
          </Grid>
        </InvitationBody>
      </InvitationHolder>
    )
  }

  if (!isRegistered) {
    return <SignupForm onComplete={handleJustRegistered} />
  }

  if (joinFailed) {
    return (
      <InvitationHolder>
        <InvitationBody distributor={distributor}>
          <Grid $item>
            <Text full centered color="danger" weight="bold">
              Could not accept the invitation, please try again later or contact an Administrator.
            </Text>
          </Grid>
        </InvitationBody>
      </InvitationHolder>
    )
  }

  return (
    <InvitationHolder>
      <InvitationBody distributor={distributor}>
        <Grid $item>
          <Grid $container direction="column" gap="m">
            <Grid $item>
              <Text>{distributor.name} has invited you to join his team of resellers.</Text>
            </Grid>

            {distributorAddress && (
              <Grid $item>
                <CompanyAddress address={distributorAddress} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid $item>
          <ButtonGroup>
            <Button full variant="primary" disabled={joinLoading} onClick={onInvitationAccept}>
              Join
            </Button>
            <Button full variant="danger" disabled={joinLoading} onClick={goToDashboard}>
              Decline
            </Button>
          </ButtonGroup>
        </Grid>
      </InvitationBody>
    </InvitationHolder>
  )
}

const CompanyLogo = ({ company: { logo, name } }: { company: InvitationCompany }) => (
  <>
    {logo && (
      <Container $maxHeight="50">
        <RemoteImage url={logo} alt={name} />
      </Container>
    )}
  </>
)

const IconHolder = styled(Flex).attrs({
  justifyCenter: true,
  alignCenter: true,
})`
  color: ${(props) => props.theme.colors.blue3};
  margin: 0 8px;
`

const Heading = styled(Text).attrs({ as: 'h1' })`
  display: inline-flex;
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.medium};
`
