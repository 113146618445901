// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type ShipmentCreationDoneQueryVariables = Types.Exact<{
  id: Types.Scalars['ShipmentId']
}>

export type ShipmentCreationDoneQuery = { readonly __typename?: 'Query' } & {
  readonly shipment: { readonly __typename?: 'Shipment' } & Pick<
    Types.Shipment,
    'id' | 'uid' | 'deviceCount' | 'toPartyName' | 'shippingCompany' | 'trackingNumber'
  >
}

export const ShipmentCreationDoneDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentCreationDone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toPartyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingCompany' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useShipmentCreationDoneQuery__
 *
 * To run a query within a React component, call `useShipmentCreationDoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentCreationDoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentCreationDoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShipmentCreationDoneQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentCreationDoneQuery, ShipmentCreationDoneQueryVariables>,
) {
  return Apollo.useQuery<ShipmentCreationDoneQuery, ShipmentCreationDoneQueryVariables>(
    ShipmentCreationDoneDocument,
    baseOptions,
  )
}
export function useShipmentCreationDoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentCreationDoneQuery, ShipmentCreationDoneQueryVariables>,
) {
  return Apollo.useLazyQuery<ShipmentCreationDoneQuery, ShipmentCreationDoneQueryVariables>(
    ShipmentCreationDoneDocument,
    baseOptions,
  )
}
export type ShipmentCreationDoneQueryHookResult = ReturnType<typeof useShipmentCreationDoneQuery>
export type ShipmentCreationDoneLazyQueryHookResult = ReturnType<typeof useShipmentCreationDoneLazyQuery>
export type ShipmentCreationDoneQueryResult = Apollo.QueryResult<
  ShipmentCreationDoneQuery,
  ShipmentCreationDoneQueryVariables
>
