import { Text } from '@ubnt/ui-components'
import { Vertical } from '../../components'

export function PageNotFound() {
  return (
    <Vertical $centerH $centerV $flex>
      <Text size="header-s" weight="bold" color="primary" style={{ marginBottom: '10px', lineHeight: '24px' }}>
        Resource not found
      </Text>

      <Text size="header-xs" color="primary">
        The requested resource does not exist.
      </Text>
    </Vertical>
  )
}
