const {
  REACT_APP_ENV: env = '',
  REACT_APP_API_URL: baseUrl = '',
  REACT_APP_API_WEBSOCKETS_URL: websocketsBaseUrl = '',
  PUBLIC_URL: publicUrl = '',
  REACT_APP_SENTRY_DSN: sentryDSN,
  REACT_APP_RELEASE: release = 'dev',
} = process.env

export const config = {
  env,
  sentryDSN,
  baseUrl,
  websocketsBaseUrl,
  publicUrl,
  release,
}
