import type { TicketStatus } from '@shared/tickets'
import type { TableColumnConfig, TableItem } from '@ubnt/ui-components'
import { Loader, Text, Table } from '@ubnt/ui-components'
import type { FC } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { ShipmentUId } from 'rma-shared/types/brands'
import { QueryTicketType } from '../../../../generated/graphql'
import { useNestedPath } from '../../../../hooks'
import { Container } from '../../../Container'
import { LoaderContainer } from '../../../Containers'
import { RemoteImage } from '../../../Image'
import { Device, FormattedDate, Status, SupportBy, TicketIdRow, Tracking } from '../../../table-columns'
import { useTickets_Active_OutgoingTicketsQuery as useOutgoingTicketsQuery } from './__generated__/TicketsTable'
import { PaginationControls } from '../../../PaginationControls'
import type { CommonProps } from '../../common'
import { DEFAULT_TICKETS_PER_PAGE } from '../../common'
import { StickyTableWrap, StickyTableContainer } from '../../../StickyTable'

interface Row {
  id: string
  mac: string
  model: {
    name: string
    image: string | null
  }
  supportBy: string
  createdAt: string
  updatedAt: string
  status: TicketStatus
  shipmentUid: ShipmentUId
}

export const TicketsTable: FC<CommonProps> = ({ searchQuery, stickyHeadTop }) => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_TICKETS_PER_PAGE)
  const { loading, error, data } = useOutgoingTicketsQuery({
    variables: {
      query: {
        type: QueryTicketType.Open,
        search: searchQuery,
      },
      cursor: offset.toString(),
      limit,
    },
  })

  const history = useHistory()
  const nested = useNestedPath()

  const total = data?.tickets?.pageInfo.total || 0

  return (
    <StickyTableWrap>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        const items = (data?.tickets?.result || []).map(
          (ticket): TableItem<Row> => ({
            id: ticket.id,
            mac: ticket.device.mac || '',
            model: {
              name: ticket.device.name,
              image: ticket.device.productImage,
            },
            supportBy: ticket.supportByName || '',
            createdAt: ticket.createdAt,
            updatedAt: ticket.updatedAt,
            status: ticket.status,
            shipmentUid: ticket.shipmentFromUid || '',
          }),
        )

        return (
          <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
            <Table
              rowHeight={50}
              headerHeight={50}
              initialSortBy="createdAt"
              disableColumnFilters
              columns={columns}
              items={items}
              onRowClick={(item) => {
                history.push(nested(`/${item.id}`))
              }}
              renderPlaceholder={() => (
                <Container $padding={['m', 0]}>
                  <Text size="inherit">No active tickets{searchQuery && ' found'}.</Text>
                </Container>
              )}
              renderFooter={() => (
                <PaginationControls
                  pageInfo={{
                    offset,
                    limit,
                    total,
                  }}
                  loadPage={(newPageInfo) => {
                    setOffset(newPageInfo.offset)
                    setLimit(newPageInfo.limit)
                  }}
                  itemsLabel="tickets"
                />
              )}
            />
          </StickyTableContainer>
        )
      })()}
    </StickyTableWrap>
  )
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'id',
    label: 'Ticket no.',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TicketIdRow ticketId={row.id} />,
  },
  {
    id: 'model',
    label: 'Device',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'mac',
    label: <span data-testid="rma-dashboard-mac-id">MAC ID / Serial</span>,
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => formatSerial(row.mac),
  },
  {
    id: 'supportBy',
    label: 'Handled by',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <SupportBy name={row.supportBy} />,
  },
  {
    id: 'status',
    label: 'Status',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <Status id={row.id} status={row.status} link={false} />,
  },
  {
    id: 'updatedAt',
    label: 'Updated on',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <FormattedDate date={row.updatedAt} />,
  },
  {
    id: 'shipmentUid',
    label: 'Shipment ID',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <Tracking ticketId={row.id} trackingNumber={row.shipmentUid} />,
  },
]
