import styled from 'styled-components'

const STAR_SIZE = 20
const VIEW_BOX_HEIGHT = STAR_SIZE

export type Props = {
  value: number // A relative value (0..1).
  count?: number
}

export const Stars = ({ value, count = 5 }: Props) => {
  const viewBoxWidth = STAR_SIZE * count
  const absoluteValue = value * count

  // Star starting position (discrepancy between SVG and the masked path).
  const starX = viewBoxWidth / 2 - STAR_SIZE / 2

  return (
    <Svg viewBox={`0 0 ${viewBoxWidth} ${VIEW_BOX_HEIGHT}`}>
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#006fff" />
          <stop offset="100%" stopColor="#00a8ff" />
        </linearGradient>
      </defs>

      <mask id="star-mask">
        {Array(count)
          .fill(0)
          .map((_, i) => {
            let fill = (absoluteValue - i) * 100
            fill = fill > 0 ? Math.min(fill, 100) : 0

            // Position stars after one another.
            // eslint-disable-next-line react/no-array-index-key
            return <Star key={i} x={starX - i * STAR_SIZE} fill={fill} />
          })}
      </mask>

      <path d={`M 0 0 H ${viewBoxWidth} V ${VIEW_BOX_HEIGHT} H 0 Z`} fill="url(#gradient)" mask="url(#star-mask)" />
    </Svg>
  )
}

const Svg = styled.svg`
  width: 100%;
`

const UNDERLYING = '#FFF' // Displays the underlying color.
const CANCELING = '#000' // Uses DOM background color.

type StarProps = { x: number; fill?: number }

export const Star = ({ x, fill = 100, ...rest }: StarProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg viewBox={`${x} 0 ${STAR_SIZE} ${STAR_SIZE}`} {...rest}>
    <defs>
      <clipPath id="star">
        <path d="M14.9376046,17.1438094 L13.9947068,11.6845141 L17.9852577,7.82173167 L12.4700842,7.02590167 L10,2.05576047 L7.5299158,7.02590167 L2.01474231,7.82173167 L6.00529324,11.6845141 L5.06239545,17.1438094 L10,14.5660043 L14.9376046,17.1438094 Z" />
      </clipPath>
    </defs>

    {/* Star, used for the stroke. */}
    <path
      stroke={UNDERLYING}
      d="M14.9376046,17.1438094 L13.9947068,11.6845141 L17.9852577,7.82173167 L12.4700842,7.02590167 L10,2.05576047 L7.5299158,7.02590167 L2.01474231,7.82173167 L6.00529324,11.6845141 L5.06239545,17.1438094 L10,14.5660043 L14.9376046,17.1438094 Z"
    />

    {/* Inverse-star, displaying underlying background color. */}
    <rect fill={UNDERLYING} width="100%" height="100%" clipPath="url(#star)" />
    {/* Inverse-star, cutting off the part that doesn't need a fill. */}
    {fill === 100 ? null : <rect x={`${fill}%`} fill={CANCELING} width="100%" height="100%" clipPath="url(#star)" />}
  </svg>
)
