import type { TextProps } from '@ubnt/ui-components'
import { Text } from '@ubnt/ui-components'
import styled, { css } from 'styled-components'

type HeadingProps = TextProps & { underline?: boolean }

export const Heading = styled(Text)<HeadingProps>`
  width: 100%;

  ${(props) =>
    !props.underline
      ? ''
      : css`
          padding-bottom: ${props.theme.spacing.s};
          border-bottom: 1px solid ${props.theme.colors.white7};
        `}
`
