import { ApolloProvider } from '@apollo/client'
import { FocusMonitorProvider, ToastProvider } from '@ubnt/ui-components'
import type { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { client } from '../apollo'
import { theme } from '../styles/theme'

export const Providers: FC = ({ children }) => (
  <ApolloProvider client={client}>
    <FocusMonitorProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>{children}</ToastProvider>
      </ThemeProvider>
    </FocusMonitorProvider>
  </ApolloProvider>
)
