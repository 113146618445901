import { Link } from '@shared-ui/components'
import { InboxIconL, SettingsIconL, UsersAddAllUsersIconL } from '@ubnt/icons'
import { Document, GuideIcon, Img } from 'rma-frontend-shared/components/Document'
import Image1 from '../../assets/var-user-guide/image1.png'
import Image3 from '../../assets/var-user-guide/image3.png'
import Image5 from '../../assets/var-user-guide/image5.png'
import Image6 from '../../assets/var-user-guide/image6.png'

export const UserGuide = () => {
  return (
    <div className="p-20 overflow">
      <Document heading="Reseller RMA Portal Manual">
        <div>
          <ul style={{ fontSize: '16px' }}>
            <li>
              <a href="#introduction">Introduction</a>
            </li>
            <li>
              <a href="#account-settings">Account Settings</a>
            </li>
            <li>
              <a href="#linking-to-distributors">Linking to Distributors</a>
            </li>
            <li>
              <a href="#processing-rma-tickets">Processing RMA Tickets</a>
            </li>
            <li>
              <a href="#creating-shipments-to-distributors">Creating Shipments to Distributors</a>
            </li>
            <li>
              <a href="#viewing-tickets-submitted-to-distributors">Viewing Tickets Submitted to Distributors</a>
            </li>
          </ul>
          <br />

          <h2 id="introduction">Introduction</h2>
          <p>
            The Reseller RMA Portal is a dedicated platform for processing RMA requests and sending shipments to
            distributors. We highly recommend reviewing this entire guide to familiarize yourself with all of the
            portal’s features and functionality.
          </p>
          <p>
            <b>Note:</b> The platform is not designed for purchasing products from Ubiquiti or your distributors.
          </p>
          <br />

          <h2 id="account-settings">Account Settings</h2>
          <p>
            Once you set up your reseller account, please ensure that your company information is up to date. To do
            that, open your <Link href="https://var.ui.com/settings">Settings</Link> menu by clicking the
            <GuideIcon>
              <SettingsIconL size="medium" omitSvgMargin style={{ display: 'inline-block' }} />
            </GuideIcon>{' '}
            icon at the bottom of the side menu. Here, you can add or edit your:
          </p>
          <ul>
            <li>
              <b>Store Name:</b> This name will be visible to your customers
            </li>
            <li>
              <b>Legal Name:</b> Your company’s legal name (not visible to your customers).
            </li>
            <li>
              <b>Website (required)</b>
            </li>
            <li>
              <b>Email:</b> Your RMA notifications will be sent to this address.
            </li>
            <li>
              <b>RMA Address:</b> Your customers will be instructed to send their return items to this address.
            </li>
          </ul>
          <p>
            <b>To add multiple RMA addresses:</b>
          </p>
          <ol>
            <li>
              Click <b>Add New</b>.
            </li>
            <li>Fill out the provided form.</li>
            <li>
              Click <b>Save Changes</b>.
            </li>
          </ol>
          <br />

          <h2 id="linking-to-distributors">Linking to Distributors</h2>
          <p>
            If you&lsquo;ve previously registered your company in the <Link href="https://var.ui.com/">VAR</Link>{' '}
            portal, you are already linked to the distributor who invited you.
          </p>
          <p>
            <b>If you work with multiple distributors</b>, you must link all of them in order to send shipments to them
            in the RMA Portal. To do that, please ask your distributors to send you an invite link. Once you receive it,
            log in to your account and you will see a verification prompt, asking you if you would like to link to the
            inviting distributor. Click <b>Yes</b> to confirm.
          </p>
          <br />

          <h2 id="processing-rma-tickets">Processing RMA Tickets</h2>
          <p>To review customer-submitted information:</p>
          <ol>
            <li>
              Click the{' '}
              <GuideIcon>
                <UsersAddAllUsersIconL size="medium" omitSvgMargin style={{ display: 'inline-block' }} />
              </GuideIcon>{' '}
              icon in the upper-left corner of the side menu to open the{' '}
              <Link href="https://var.ui.com/customer-tickets/pending">Customer Tickets</Link> page.
            </li>
            <li>Select the ticket that you would like to process.</li>
            <li>Read the customer&lsquo;s issue description.</li>
            <li>Verify that the return item(s) are within their warranty period. </li>
          </ol>
          <p>
            <b>Note:</b> Proof of purchase is <b>not required</b> for devices still within their warranty period.
          </p>
          <p>
            <Img src={Image3} />
          </p>
          <p>
            Some RMA tickets may not have an assigned distributor.{' '}
            <b>To manually assign one before processing a ticket:</b>
          </p>
          <ol>
            <li>
              Open the ticket&lsquo;s <b>Manage</b> tab. A prompt will appear asking you to select the distributor that
              you purchased the item from.
            </li>
            <li>
              Select the distributor from the drop-down menu and click and click <b>Confirm</b>.{' '}
            </li>
          </ol>
          <p>
            If you can&apos;t find your distributor, follow the steps in{' '}
            <a href="#linking-to-distributors">Linking to Distributors</a>.
          </p>
          <p>
            <Img src={Image1} />
          </p>
          <p>
            <b>To confirm a warranty</b>, open the ticket&apos;s <b>Manage</b> tab, select the address where the
            customer will send their item(s), and click <b>Confirm</b>. See the{' '}
            <a href="#account-settings">Account Settings</a> section if you need to add a new address.
          </p>
          <p>
            <b>To deny a warranty</b>, provide your declination reason in the <b>Manage</b> tab, then click{' '}
            <b>Decline</b>.
          </p>
          <p>
            <b>Note:</b> Return item(s) under warranty cannot be declined.
          </p>
          <p>
            <Img src={Image5} />
          </p>
          <p>
            After reviewing a customer&apos;s issue description, inspect their item(s) to verify that they are in the
            described state. Based on that, either:
          </p>
          <p>
            <b>Approve the RMA request:</b>
          </p>
          <ol>
            <li>
              Open the ticket&apos;s <b>Manage</b> tab.
            </li>
            <li>
              Select the returned item(s&apos;) <b>Failure Category</b>.
            </li>
            <li>
              Enter a <b>Failure Description</b>.
            </li>
            <li>
              Click <b>Confirm</b>.
            </li>
          </ol>
          <p>
            <b>Deny the request</b> by entering your declination reason in the <b>Manage</b> tab, then clicking{' '}
            <b>Decline</b>.
          </p>
          <p>
            <b>Once an RMA request is approved</b>, ship the customer’s replacement item(s):
          </p>
          <ol>
            <li>
              Open the ticket&apos;s <b>Manage</b> tab.
            </li>
            <li>Select the shipping company (optional).</li>
            <li>Enter the shipment&apos;s tracking number (optional).</li>
            <li>Type a note to the customer (optional).</li>
            <li>Attach applicable file(s) (optional).</li>
            <li>
              Click <b>Confirm</b>.
            </li>
          </ol>
          <br />

          <h2 id="creating-shipments-to-distributors">Creating Shipments to Distributors</h2>
          <p>
            <b>Important</b>: To create a distributor shipments, you must approve the corresponding RMA ticket by
            setting its status to either: <b>Fulfillment</b> or <b>Product Shipped</b>. All devices from approved
            tickets will be listed in the <b>Pending</b> tab of your{' '}
            <Link href="https://var.ui.com/tickets/pending">RMA Requests to Distributors</Link> page for <b>45 days</b>.
          </p>
          <p>
            You must send these devices to distributors within this 45-day period.{' '}
            <b>If you do not, the corresponding ticket(s) will expire</b>.
          </p>
          <ol>
            <li>
              Click the{' '}
              <GuideIcon>
                <InboxIconL size="medium" omitSvgMargin style={{ display: 'inline-block' }} />
              </GuideIcon>{' '}
              icon in the upper-left corner of the side menu to open the{' '}
              <Link href="https://var.ui.com/tickets/pending">RMA Requests to Distributors</Link> page.
            </li>
            <li>
              Select the device(s) you would like to send to a distributor, then click <b>Create Shipment</b>.{' '}
              <b>Note</b>: Multiple devices can be sent in one shipment.
            </li>
            <li>
              Click <b>View/Edit</b> on the right side of each ticket to review its Failure Category and Description.
            </li>
            <li>
              Edit the description, if needed. Click <b>Save</b>, then <b>Next</b>.
            </li>
            <li>
              Select the return address(es). <b>If you cannot find the correct address</b> see our Account Settings
              section.
            </li>
            <li>Select the shipping company and enter the shipment&apos;s tracking number (optional).</li>
            <li>
              Click <b>Submit</b>.
            </li>
          </ol>
          <p>
            <Img src={Image6} />
          </p>
          <br />

          <h2 id="viewing-tickets-submitted-to-distributors">Viewing Tickets Submitted to Distributors</h2>
          <ol>
            <li>
              Click the{' '}
              <GuideIcon>
                <InboxIconL size="medium" omitSvgMargin style={{ display: 'inline-block' }} />
              </GuideIcon>{' '}
              icon in the upper-left corner of the side menu to open the{' '}
              <Link href="https://var.ui.com/tickets/pending">RMA Requests to Distributors</Link> page.
            </li>
            <li>
              Open the <b>Active</b> tab to see all tickets being processed.
              <br />
              <b>Note:</b> Once a ticket is closed, it will be moved to the History tab.
            </li>
          </ol>
        </div>
      </Document>
    </div>
  )
}
