import formatSerial from '@shared/lib/utils/format/serial'
import type { TableColumnConfig } from '@ubnt/ui-components'
import { cssVariables, Table, Text } from '@ubnt/ui-components'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import type { Device, Shipment, Ticket } from '../../generated/graphql'
import { SidePanelCard } from '../SprintShared'

type InventoryProps = {
  shipment: {
    uid: Shipment['uid']
    tickets: readonly {
      id: Ticket['id']
      device: {
        name: Device['name']
        mac: Device['mac']
      }
    }[]
  }
  linkToTickets: (ticketId: Ticket['id'], shipmentUid: Shipment['uid']) => string
}

type Row = {
  id: string
  sku: string
  mac: string
}

export const Inventory = ({ shipment, linkToTickets }: InventoryProps) => {
  const items: Row[] = useMemo(
    () =>
      shipment.tickets.map(({ id, device }) => ({
        id,
        sku: device.name,
        mac: device.mac || '',
      })),
    [shipment.tickets],
  )

  return (
    <Content>
      <Table
        columns={getColumns((ticketId) => linkToTickets(ticketId, shipment.uid))}
        items={items}
        disableColumnFilters
        renderPlaceholder={() => 'No tickets in this shipment'}
      />
    </Content>
  )
}

const getColumns = (linkToTickets: (ticketId: Ticket['id']) => string): TableColumnConfig<Row>[] => [
  {
    id: 'id',
    label: 'Ticket ID',
    maxWidth: 120,
    renderCell: ({ id }) => (
      <Link to={linkToTickets(id)} target="_blank" data-testid="ticket-in-shipment">
        {id}
      </Link>
    ),
  },
  {
    id: 'sku',
    label: 'SKU',
  },
  {
    id: 'mac',
    label: 'MAC ID',
    maxWidth: 120,
    renderCell: ({ mac }) => (
      <Text size="body" title={formatSerial(mac)} truncate>
        {formatSerial(mac)}
      </Text>
    ),
  },
]

const Content = styled(SidePanelCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${cssVariables['spacing-l']};
`
