import { DashboardTicketsTable } from '@shared-ui/components/dashboard-tickets-table'
import useNavigate from '@shared-ui/hooks/useNavigate'
import useNestedPath from '@shared-ui/hooks/useNestedPath'
import { useCallback } from 'react'
import { Route } from 'react-router-dom'
import { AverageDuration } from './AverageDuration'
import { AverageRating } from './AverageRating'
import { Ticket } from './Ticket'
import { TicketStatus } from './TicketStatus'
import { useDashboardTicketStatsQuery } from './__generated__/Tickets'

export const Tickets = () => {
  const navigate = useNavigate()
  const nested = useNestedPath()
  const { loading, error, data } = useDashboardTicketStatsQuery({ fetchPolicy: 'cache-and-network' })
  const isLoading = !data && loading

  const onTicketClose = useCallback(() => navigate(nested('')), [navigate, nested])

  return (
    <div className="relative flex flex-1 column px-30 mb-20 full-height">
      <div className="flex px-56 py-28 sr-40">
        <AverageRating loading={isLoading} error={error} ratings={data?.ticketStats.ratings} />
        <AverageDuration loading={isLoading} error={error} lengths={data?.ticketStats.lengths} />
        <TicketStatus loading={isLoading} error={error} statuses={data?.ticketStats.statuses} />
      </div>

      <DashboardTicketsTable />

      <Route path={nested('/:ticketId')}>
        <Ticket onClose={onTicketClose} />
      </Route>
    </div>
  )
}
