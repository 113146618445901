// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../generated/graphql'

export type AcceptTosMutationVariables = Types.Exact<{
  userId: Types.Scalars['UserId']
}>

export type AcceptTosMutation = { readonly __typename?: 'Mutation' } & Pick<Types.Mutation, 'acceptTos'>

export const AcceptTosDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptTos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptTos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
}
export type AcceptTosMutationFn = Apollo.MutationFunction<AcceptTosMutation, AcceptTosMutationVariables>

/**
 * __useAcceptTosMutation__
 *
 * To run a mutation, you first call `useAcceptTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTosMutation, { data, loading, error }] = useAcceptTosMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAcceptTosMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptTosMutation, AcceptTosMutationVariables>,
) {
  return Apollo.useMutation<AcceptTosMutation, AcceptTosMutationVariables>(AcceptTosDocument, baseOptions)
}
export type AcceptTosMutationHookResult = ReturnType<typeof useAcceptTosMutation>
export type AcceptTosMutationResult = Apollo.MutationResult<AcceptTosMutation>
export type AcceptTosMutationOptions = Apollo.BaseMutationOptions<AcceptTosMutation, AcceptTosMutationVariables>
