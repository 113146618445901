// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type Submit_CreateRmaCompaniesQueryVariables = Types.Exact<{
  filter: Types.TicketsPendingRmaFilter
}>

export type Submit_CreateRmaCompaniesQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsPendingRma: { readonly __typename?: 'TicketsResult' } & {
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> & {
          readonly nextHandler: { readonly __typename?: 'TicketHandler' } & Pick<Types.TicketHandler, 'id'>
        }
    >
  }
}

export type Submit_CreateRmaMutationVariables = Types.Exact<{
  input: Types.CreateForwardingTicketsInput
}>

export type Submit_CreateRmaMutation = { readonly __typename?: 'Mutation' } & {
  readonly createForwardingTickets: { readonly __typename?: 'CreateForwardingTicketsResponse' } & Pick<
    Types.CreateForwardingTicketsResponse,
    'shipmentBatchId'
  >
}

export const Submit_CreateRmaCompaniesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Submit_CreateRmaCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsPendingRmaFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsPendingRma' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextHandler' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useSubmit_CreateRmaCompaniesQuery__
 *
 * To run a query within a React component, call `useSubmit_CreateRmaCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmit_CreateRmaCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmit_CreateRmaCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubmit_CreateRmaCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<Submit_CreateRmaCompaniesQuery, Submit_CreateRmaCompaniesQueryVariables>,
) {
  return Apollo.useQuery<Submit_CreateRmaCompaniesQuery, Submit_CreateRmaCompaniesQueryVariables>(
    Submit_CreateRmaCompaniesDocument,
    baseOptions,
  )
}
export function useSubmit_CreateRmaCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Submit_CreateRmaCompaniesQuery, Submit_CreateRmaCompaniesQueryVariables>,
) {
  return Apollo.useLazyQuery<Submit_CreateRmaCompaniesQuery, Submit_CreateRmaCompaniesQueryVariables>(
    Submit_CreateRmaCompaniesDocument,
    baseOptions,
  )
}
export type Submit_CreateRmaCompaniesQueryHookResult = ReturnType<typeof useSubmit_CreateRmaCompaniesQuery>
export type Submit_CreateRmaCompaniesLazyQueryHookResult = ReturnType<typeof useSubmit_CreateRmaCompaniesLazyQuery>
export type Submit_CreateRmaCompaniesQueryResult = Apollo.QueryResult<
  Submit_CreateRmaCompaniesQuery,
  Submit_CreateRmaCompaniesQueryVariables
>
export const Submit_CreateRmaDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Submit_CreateRma' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateForwardingTicketsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForwardingTickets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shipmentBatchId' } }],
            },
          },
        ],
      },
    },
  ],
}
export type Submit_CreateRmaMutationFn = Apollo.MutationFunction<
  Submit_CreateRmaMutation,
  Submit_CreateRmaMutationVariables
>

/**
 * __useSubmit_CreateRmaMutation__
 *
 * To run a mutation, you first call `useSubmit_CreateRmaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmit_CreateRmaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCreateRmaMutation, { data, loading, error }] = useSubmit_CreateRmaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmit_CreateRmaMutation(
  baseOptions?: Apollo.MutationHookOptions<Submit_CreateRmaMutation, Submit_CreateRmaMutationVariables>,
) {
  return Apollo.useMutation<Submit_CreateRmaMutation, Submit_CreateRmaMutationVariables>(
    Submit_CreateRmaDocument,
    baseOptions,
  )
}
export type Submit_CreateRmaMutationHookResult = ReturnType<typeof useSubmit_CreateRmaMutation>
export type Submit_CreateRmaMutationResult = Apollo.MutationResult<Submit_CreateRmaMutation>
export type Submit_CreateRmaMutationOptions = Apollo.BaseMutationOptions<
  Submit_CreateRmaMutation,
  Submit_CreateRmaMutationVariables
>
