import { MaxSelectedShipmentTickets } from '@shared/tickets'
import { cssVariables, Button, Loader, Table, Text } from '@ubnt/ui-components'
import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { DeviceId, TicketId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { getFailureCategory } from 'rma-shared/types/tickets'
import { Vertical } from '../../..'
import { useNavigate, useNestedPath } from '../../../../hooks'
import { Container } from '../../../Container'
import { LoaderContainer } from '../../../Containers'
import { RemoteImage } from '../../../Image'
import { PaginationControls } from '../../../PaginationControls'
import type { TableColumnConfig, TableItem } from '../../../Table'
import { Device, TicketIdRow, TimeLeft } from '../../../table-columns'
import type { CommonProps } from '../../common'
import { DEFAULT_TICKETS_PER_PAGE } from '../../common'
import { UpdateHandler } from './UpdateHandler'
import { useTickets_Pending_TicketsPendingDistributorRmaQuery as useTicketsPendingRmaQuery } from './__generated__/WindowTable'
import { StickyTableWrap, StickyTableContainer } from '../../../StickyTable'

interface Row {
  id: TicketId
  device: {
    id: DeviceId
    model: {
      name: string
      image: string | null
    }
  }
  macId: string
  expiresAt?: string | null
  failureCategory: string
  description: string
  setSelectedTicket: (ticket: TicketId) => void
}

interface Props extends CommonProps {
  onRenderCreateShipmentButton: (el: ReactNode) => void
}

export const TicketsTableDistributor: FC<Props> = ({ onRenderCreateShipmentButton, searchQuery, stickyHeadTop }) => {
  const history = useHistory()
  const navigate = useNavigate()
  const nested = useNestedPath()

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_TICKETS_PER_PAGE)
  const [openUrl, setOpenUrl] = useState('')
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | undefined>()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectedTicket, setSelectedTicket] = useState<TicketId>()

  const { loading, error, data } = useTicketsPendingRmaQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cursor: offset.toString(),
      limit,
      filter: {
        search: searchQuery,
      },
    },
  })

  const handleSendRma = useCallback(() => {
    navigate(nested('/submit'), { selectedIds })
  }, [navigate, nested, selectedIds])

  const total = data?.ticketsPendingRma?.pageInfo.total || 0

  useEffect(() => {
    onRenderCreateShipmentButton(
      <CreateShipmentWrap>
        <Button disabled={!selectedIds.length} onClick={handleSendRma} variant="primary">
          <span data-testid="create-shipment-btn">Create Shipment</span>
        </Button>
      </CreateShipmentWrap>,
    )

    return () => {
      onRenderCreateShipmentButton(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, onRenderCreateShipmentButton])

  useEffect(() => {
    if (clickTimeout) {
      clearTimeout(clickTimeout)
    }

    if (openUrl) {
      setClickTimeout(
        setTimeout(() => {
          history.push(nested(openUrl))
          setOpenUrl('')
        }, 100),
      )
    }
  }, [openUrl, setOpenUrl])

  return (
    <StickyTableWrap>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        const devices = data?.ticketsPendingRma?.result || []

        const items = devices.map(
          (item): TableItem<Row> => ({
            id: item.id,
            device: {
              id: item.device.id,
              model: {
                name: item.device.name,
                image: item.device.productImage,
              },
            },
            macId: item.device.mac || '',
            expiresAt: item.expiresAt,
            description: item.description,
            failureCategory: getFailureCategory(item.handlerFailureCategoryId || item.customerFailureCategoryId),
            setSelectedTicket,
          }),
        )

        return (
          <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
            <Table
              rowHeight={50}
              headerHeight={50}
              initialSortBy="expiresAt"
              multiSelectMode={!!items.length}
              disableColumnFilters
              columns={columns}
              items={items}
              selectedIds={selectedIds}
              onRowClick={(item) => {
                setOpenUrl(`/${item.id}`)
              }}
              onChangeSelection={(ids) => {
                setOpenUrl('')
                if (clickTimeout) {
                  clearTimeout(clickTimeout)
                }

                const newIds = ids.reverse()
                if (newIds.length > MaxSelectedShipmentTickets) {
                  newIds.length = MaxSelectedShipmentTickets
                }

                setSelectedIds(newIds as string[])
              }}
              renderPlaceholder={() => (
                <Container $padding={['m', 0]}>
                  <Text size="inherit">No open tickets{searchQuery && ' found'}.</Text>
                </Container>
              )}
              renderFooter={() => (
                <Footer>
                  <PaginationControls
                    pageInfo={{
                      offset,
                      limit,
                      total,
                    }}
                    loadPage={(newPageInfo) => {
                      setOffset(newPageInfo.offset)
                      setLimit(newPageInfo.limit)
                    }}
                    itemsLabel="tickets"
                  />
                  {!!items.length && (
                    <FooterButton>
                      <Vertical $centerV $centerH style={{ marginRight: '10px' }}>
                        <Text size="body" color="tertiary">
                          {selectedIds.length} / {MaxSelectedShipmentTickets}
                        </Text>
                      </Vertical>
                    </FooterButton>
                  )}
                </Footer>
              )}
            />

            {selectedTicket && (
              <UpdateHandler
                ticketId={selectedTicket}
                onClose={() => {
                  setSelectedTicket(undefined)
                }}
              />
            )}
          </StickyTableContainer>
        )
      })()}
    </StickyTableWrap>
  )
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'id',
    label: 'Ticket no.',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TicketIdRow ticketId={row.id} />,
  },
  {
    id: 'device',
    label: 'Device',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => (
      <Device
        name={row.device.model.name}
        image={<RemoteImage url={row.device.model.image} width={24} height={24} />}
      />
    ),
  },
  {
    id: 'macId',
    label: 'MAC ID / Serial',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => formatSerial(row.macId),
  },
  {
    id: 'expiresAt',
    label: 'Time left',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TimeLeft to={row.expiresAt} />,
  },
  {
    id: 'failureCategory',
    label: 'Failure Category',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <>{row.failureCategory}</>,
  },
  {
    id: 'description',
    label: 'Description',
    growthFactor: 1,
    renderCell: (row) => (
      <Text size="body" truncate title={row.description}>
        {row.description}
      </Text>
    ),
  },
]

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FooterButton = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CreateShipmentWrap = styled.div`
  display: flex;
  margin-left: ${cssVariables['spacing-s']};
`
