import styled from 'styled-components'

export interface VerticalScrollProps {
  centered?: boolean
}

export const VerticalScroll = styled.div<VerticalScrollProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`
