import React from 'react'
import styled from 'styled-components'
import { theme } from '../styles/theme'
import { ExpandedContainer } from './Container'

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
`

const PageHeading = styled.h1`
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  margin-top: 0px;
  color: ${theme.colors.grey1};
`

const Tos = styled.div`
  display: flex;
  padding: 30px;
  line-height: 22px;
  max-width: 1200px;
  border: 1px solid ${theme.colors.border.normal};
  border-radius: 3px;
  font-size: 0.875rem;
  color: ${theme.colors.grey1};
`

interface DocumentProps {
  heading: string
  children: React.ReactChild
}

export const Document = ({ heading, children }: DocumentProps) => {
  return (
    <ExpandedContainer $centered>
      <ContentWrap>
        <PageHeading>{heading}</PageHeading>
        <Tos>{children}</Tos>
      </ContentWrap>
    </ExpandedContainer>
  )
}

export const GuideIcon = styled.span`
  width: 24px;
  height: 24px;
  margin-left: 6px;
  & > div {
    display: inline-block;
  }
`

export const Img = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 10px;
`
