import type { CompanyAddressId, CompanyId } from '@shared/types/brands'
import type { DropdownOption } from '@ubnt/ui-components'
import { Button, Loader, cssVariables } from '@ubnt/ui-components'
import { useField } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import type { CompanyAddress, CompanyShippingAddressInput } from '../../../../generated/graphql'
import useNavigate from '../../../../hooks/useNavigate'
import { getPortal } from '../../../../utils/isPortal'
import { LoaderContainer } from '../../../Containers'
import { Dropdown } from '../../../SprintShared'
import { Text } from '../../../Text'
import {
  createAddressOptions,
  getAddress,
  getDefaultAddress,
  getValidAddresses,
  transformAddress,
} from '../../../Ticket/TicketService'
import type { ShipmentInfoInputValue as ShipmentInfo } from '../../../form/ShipmentInfoInput'
import { ShipmentInfoInput } from '../../../form/ShipmentInfoInput'
import { useSubmit_Step2_CompaniesQuery as useCompaniesQuery } from './__generated__/Step2'

export const Step2 = ({
  nextHandlerId,
  itemCount,
  errorMessage,
  isSubmitting,
}: {
  nextHandlerId?: CompanyId
  itemCount: number
  errorMessage?: string
  isSubmitting: boolean
}) => {
  const [deliveryAddressId, setDeliveryAddressId] = useState<CompanyAddressId | null>(null)
  const [returnAddressId, setReturnAddressId] = useState<CompanyAddressId | null>(null)
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<DropdownOption[]>([])
  const [returnAddressOptions, setReturnAddressOptions] = useState<DropdownOption[]>([])
  const portalType = getPortal()

  const { data: companies, loading } = useCompaniesQuery({
    variables: { companyId: nextHandlerId as string },
    skip: !nextHandlerId,
  })

  const returnAddresses =
    (portalType === 'DIRECT' ? companies?.myCompany?.rmaAddresses : companies?.myCompany?.addresses) || []

  // Shipments made in DirectRMA portal are addressed to Ubiquiti, so NS addresses need to be used
  // And for VAR portal, shipments are addressed to Distributor, so RMA addresses managed in DP need to be used in that case
  const companyAddresses = companies?.company?.addresses || []
  const companyRmaAddresses = companies?.company?.rmaAddresses || []

  let companyDeliveryAddresses: readonly CompanyAddress[]
  if (portalType === 'DIRECT') {
    companyDeliveryAddresses = companyAddresses
  } else if (companyRmaAddresses && companyRmaAddresses.length) {
    companyDeliveryAddresses = companyRmaAddresses
  } else {
    companyDeliveryAddresses = []
  }

  const navigate = useNavigate()
  const [returnAddress, , returnAddressHelper] = useField<CompanyShippingAddressInput>('returnAddress')
  const [deliveryAddress, , deliveryAddressHelper] = useField<CompanyShippingAddressInput>('deliveryAddress')
  const [, , shippingAddressIdHelper] = useField<CompanyAddressId | null>('shippingAddressId')
  const isAddressLoading = !deliveryAddress.value.id || !returnAddress.value.id

  const [shipmentField, , shipmentInfoHelper] = useField<ShipmentInfo>('shipmentInfo')
  const handleChange = useCallback(
    (newValue: ShipmentInfo) => {
      shipmentInfoHelper.setValue(newValue)
    },
    [shipmentInfoHelper],
  )

  const selectDeliveryAddress = (addressId: CompanyAddressId) => {
    const company = companies?.company
    if (!company) {
      return
    }

    const address = getAddress(addressId, companyDeliveryAddresses)
    if (address) {
      deliveryAddressHelper.setValue(transformAddress(company.id, company.name, address))
    }
  }

  const selectReturnAddress = (addressId: CompanyAddressId) => {
    const company = companies?.myCompany
    if (!company) {
      return
    }

    const address = getAddress(addressId, returnAddresses)
    if (address) {
      shippingAddressIdHelper.setValue(addressId)
      returnAddressHelper.setValue(transformAddress(company.id, company.name, address))
    }
  }

  useEffect(() => {
    const validReturnAddresses = getValidAddresses(
      companies?.company?.id || '',
      companies?.company?.name || '',
      returnAddresses,
    )

    setDeliveryAddressOptions(createAddressOptions(companyDeliveryAddresses))
    setReturnAddressOptions(createAddressOptions(validReturnAddresses))

    const deliveryAddressDefault = getDefaultAddress(companyDeliveryAddresses)
    const returnAddressDefault = getDefaultAddress(validReturnAddresses)

    if (deliveryAddressDefault && deliveryAddressDefault.id) {
      selectDeliveryAddress(deliveryAddressDefault.id)
      setDeliveryAddressId(deliveryAddressDefault.id)
      const company = companies?.company

      if (company) {
        deliveryAddressHelper.setValue(transformAddress(company.id, company.name, deliveryAddressDefault))
      }
    }

    if (returnAddressDefault && returnAddressDefault.id) {
      selectReturnAddress(returnAddressDefault.id)
      setReturnAddressId(returnAddressDefault.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies?.company, companies?.myCompany])

  const isUbiquiti = companies?.company?.entityType === 'ubiquiti'

  const handleDeliveryAddressChange = (option: DropdownOption) => {
    const addressId = option.value as CompanyAddressId
    selectDeliveryAddress(addressId)
    setDeliveryAddressId(addressId)
  }

  const handleMyAddressChange = (option: DropdownOption) => {
    const addressId = option.value as CompanyAddressId
    selectReturnAddress(addressId)
    setReturnAddressId(addressId)
  }

  const isLoading = !nextHandlerId || loading
  const isSubmitDisabled = isLoading || isAddressLoading

  return (
    <Wrap>
      <Fields>
        <Heading>Shipment Confirmation</Heading>
        {isLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}

        {!isLoading && (
          <>
            {!isUbiquiti && (
              <>
                <SubHeading>Delivery Address</SubHeading>

                {deliveryAddressId && (
                  <Dropdown
                    width="auto"
                    options={deliveryAddressOptions}
                    value={deliveryAddressId as string}
                    onChange={handleDeliveryAddressChange}
                    data-testid="delivery-address"
                  />
                )}
                {!deliveryAddressId && (
                  <>
                    {portalType !== 'VAR' && <Text>Shipping address not available.</Text>}
                    {portalType === 'VAR' && (
                      <Text>
                        Shipping address is not available. Please ask your distributor to add a delivery address to
                        their portal.
                      </Text>
                    )}
                  </>
                )}
              </>
            )}

            <SubHeading>Return Address</SubHeading>

            {returnAddressId && (
              <StyledDropdown
                width="auto"
                options={returnAddressOptions}
                value={returnAddressId as string}
                onChange={handleMyAddressChange}
                data-testid="return-address"
              />
            )}
            {!returnAddressId && (
              <>
                {portalType === 'DIRECT' && (
                  <Text>
                    The shipping address provided is incomplete. Please go to the Settings section of the Direct portal
                    and fill out the missing information.
                  </Text>
                )}
                {portalType !== 'DIRECT' && (
                  <Text>
                    The shipping address provided is incomplete. Please go to the Settings section and fill out the
                    missing information.
                  </Text>
                )}
              </>
            )}

            <SubHeading>Shipment Details</SubHeading>

            <ShipmentInfoInput value={shipmentField.value} onChange={handleChange} data-testid="shipping-company" />

            <SubHeading>Inventory</SubHeading>
            <Text color="tertiary">
              {itemCount} device{itemCount > 1 && 's'}
            </Text>
          </>
        )}
      </Fields>

      <Footer>
        {errorMessage && (
          <Text centered color="danger">
            {errorMessage}
          </Text>
        )}
        <BackButtonWrap>
          <Button variant="tertiary" disabled={loading} type="button" onClick={() => navigate('/tickets/pending')}>
            Back
          </Button>
        </BackButtonWrap>
        <Button
          variant="primary"
          disabled={isSubmitDisabled}
          type="submit"
          loader={isSubmitting ? 'loading' : undefined}
          data-testid="btn-submit-shipment"
        >
          Submit
        </Button>
      </Footer>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Heading = styled(Text).attrs({ color: 'primary' })`
  margin-bottom: ${cssVariables['spacing-xxxxxl']};
  display: block;
`

Heading.defaultProps = {
  size: 'header-s',
  weight: 'bold',
}

const SubHeading = styled(Text)`
  margin-top: ${cssVariables['spacing-xxl']};
  margin-bottom: ${cssVariables['spacing-m']};
  display: block;
`

SubHeading.defaultProps = {
  size: 'header-xs',
  weight: 'bold',
}

const Fields = styled.div`
  flex: 1;
  padding: ${cssVariables['spacing-xxxxxl']} ${cssVariables['spacing-xxxxl']};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${cssVariables.motifs.light.neutral03}; // TODO: fix margin
  padding: ${cssVariables['spacing-xxxxl']};
`

const BackButtonWrap = styled.div`
  margin-right: ${cssVariables['spacing-s']};
`

const StyledDropdown = styled(Dropdown)`
  [class*='input-caption'] {
    height: auto;
    min-height: 30px;
    padding: 5px 0;
  }
`
