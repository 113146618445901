import { CustomerTab, DetailsTab, LogTab } from '../Ticket'

export const tabs = [DetailsTab, CustomerTab, LogTab]

export const DEFAULT_TICKETS_PER_PAGE = 100

export interface CommonProps {
  searchQuery: string
  stickyHeadTop?: string
}
