// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type Submit_Step1_ViewFailureFragment = { readonly __typename?: 'Ticket' } & Pick<
  Types.Ticket,
  'description' | 'handlerDescription' | 'handlerFailureCategoryId' | 'customerFailureCategoryId'
> & {
    readonly device: { readonly __typename?: 'Device' } & {
      readonly product: Types.Maybe<{ readonly __typename?: 'Product' } & Pick<Types.Product, 'rmaParentCategoryId'>>
    }
    readonly companyFiles: ReadonlyArray<{ readonly __typename?: 'File' } & Pick<Types.File, 'id' | 'filename' | 'url'>>
    readonly userFiles: ReadonlyArray<{ readonly __typename?: 'File' } & Pick<Types.File, 'id' | 'filename' | 'url'>>
  }

export type Submit_Step1_TicketsPendingRmaQueryVariables = Types.Exact<{
  filter: Types.TicketsPendingRmaFilter
}>

export type Submit_Step1_TicketsPendingRmaQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsPendingRma: { readonly __typename?: 'TicketsResult' } & {
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> & {
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'productImage' | 'mac'
          > & {
              readonly product: Types.Maybe<
                { readonly __typename?: 'Product' } & Pick<Types.Product, 'id' | 'rmaParentCategoryId'>
              >
            }
          readonly companyFiles: ReadonlyArray<
            { readonly __typename?: 'File' } & Pick<Types.File, 'id' | 'filename' | 'url'>
          >
          readonly userFiles: ReadonlyArray<
            { readonly __typename?: 'File' } & Pick<Types.File, 'id' | 'filename' | 'url'>
          >
        } & Submit_Step1_ViewFailureFragment
    >
  }
}

export const Submit_Step1_ViewFailureFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Submit_Step1_ViewFailure' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const Submit_Step1_TicketsPendingRmaDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Submit_Step1_TicketsPendingRma' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsPendingRmaFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsPendingRma' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userFiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Submit_Step1_ViewFailure' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Submit_Step1_ViewFailure' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useSubmit_Step1_TicketsPendingRmaQuery__
 *
 * To run a query within a React component, call `useSubmit_Step1_TicketsPendingRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmit_Step1_TicketsPendingRmaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmit_Step1_TicketsPendingRmaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubmit_Step1_TicketsPendingRmaQuery(
  baseOptions: Apollo.QueryHookOptions<
    Submit_Step1_TicketsPendingRmaQuery,
    Submit_Step1_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useQuery<Submit_Step1_TicketsPendingRmaQuery, Submit_Step1_TicketsPendingRmaQueryVariables>(
    Submit_Step1_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export function useSubmit_Step1_TicketsPendingRmaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Submit_Step1_TicketsPendingRmaQuery,
    Submit_Step1_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Submit_Step1_TicketsPendingRmaQuery, Submit_Step1_TicketsPendingRmaQueryVariables>(
    Submit_Step1_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export type Submit_Step1_TicketsPendingRmaQueryHookResult = ReturnType<typeof useSubmit_Step1_TicketsPendingRmaQuery>
export type Submit_Step1_TicketsPendingRmaLazyQueryHookResult = ReturnType<
  typeof useSubmit_Step1_TicketsPendingRmaLazyQuery
>
export type Submit_Step1_TicketsPendingRmaQueryResult = Apollo.QueryResult<
  Submit_Step1_TicketsPendingRmaQuery,
  Submit_Step1_TicketsPendingRmaQueryVariables
>
