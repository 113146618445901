// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type Tickets_Expired_TicketsPendingRmaQueryVariables = Types.Exact<{
  search: Types.Maybe<Types.Scalars['String']>
  cursor: Types.Maybe<Types.Scalars['Cursor']>
  limit: Types.Maybe<Types.Scalars['Int']>
}>

export type Tickets_Expired_TicketsPendingRmaQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsPendingRma: { readonly __typename?: 'TicketsResult' } & {
    readonly pageInfo: { readonly __typename?: 'PageInfo' } & Pick<
      Types.PageInfo,
      'endCursor' | 'hasNextPage' | 'total'
    >
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<
        Types.Ticket,
        'id' | 'updatedAt' | 'expiresAt' | 'revivedCount' | 'customerFailureCategoryId' | 'handlerFailureCategoryId'
      > & {
          readonly submittedBy:
            | ({ readonly __typename?: 'Company' } & {
                companyId: Types.Company['id']
                companyName: Types.Company['name']
                companyWebsite: Types.Company['website']
              })
            | ({ readonly __typename?: 'Customer' } & {
                customerId: Types.Customer['id']
                customerName: Types.Customer['fullname']
              })
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'mac' | 'productImage'
          >
        }
    >
  }
}

export const Tickets_Expired_TicketsPendingRmaDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tickets_Expired_TicketsPendingRma' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsPendingRma' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isExpired' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revivedCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submittedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'companyId' },
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'companyName' },
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'companyWebsite' },
                                    name: { kind: 'Name', value: 'website' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'customerId' },
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'customerName' },
                                    name: { kind: 'Name', value: 'fullname' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useTickets_Expired_TicketsPendingRmaQuery__
 *
 * To run a query within a React component, call `useTickets_Expired_TicketsPendingRmaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickets_Expired_TicketsPendingRmaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTickets_Expired_TicketsPendingRmaQuery({
 *   variables: {
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTickets_Expired_TicketsPendingRmaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Tickets_Expired_TicketsPendingRmaQuery,
    Tickets_Expired_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useQuery<Tickets_Expired_TicketsPendingRmaQuery, Tickets_Expired_TicketsPendingRmaQueryVariables>(
    Tickets_Expired_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export function useTickets_Expired_TicketsPendingRmaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Tickets_Expired_TicketsPendingRmaQuery,
    Tickets_Expired_TicketsPendingRmaQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Tickets_Expired_TicketsPendingRmaQuery, Tickets_Expired_TicketsPendingRmaQueryVariables>(
    Tickets_Expired_TicketsPendingRmaDocument,
    baseOptions,
  )
}
export type Tickets_Expired_TicketsPendingRmaQueryHookResult = ReturnType<
  typeof useTickets_Expired_TicketsPendingRmaQuery
>
export type Tickets_Expired_TicketsPendingRmaLazyQueryHookResult = ReturnType<
  typeof useTickets_Expired_TicketsPendingRmaLazyQuery
>
export type Tickets_Expired_TicketsPendingRmaQueryResult = Apollo.QueryResult<
  Tickets_Expired_TicketsPendingRmaQuery,
  Tickets_Expired_TicketsPendingRmaQueryVariables
>
