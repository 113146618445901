import type { CompanyId } from '@shared/types/brands'
import type { DropdownOption } from '@ubnt/ui-components'
import { Loader } from '@ubnt/ui-components'
import { useMemo } from 'react'
import { MessageContainer } from '../MessageContainer'
import { Dropdown } from '../SprintShared'
import { useCompanyDropdownQuery } from './__generated__/Ticket'

type Value = CompanyId | null

interface CompanyDropdownProps {
  value?: Value
  onChange?: (newValue?: Value) => void
}

export const CompanyDropdown = ({ value, onChange }: CompanyDropdownProps) => {
  const { data, loading } = useCompanyDropdownQuery()

  const options = useMemo(
    (): DropdownOption[] => [
      ...(data?.myCompany?.parents.map((company) => ({
        value: company.id,
        label: company.name,
      })) ?? []),
    ],
    [data],
  )

  if (loading) {
    return (
      <MessageContainer center>
        <Loader size="small" />
      </MessageContainer>
    )
  }

  return (
    <Dropdown
      options={options}
      value={value as string}
      onChange={(_option, newValue) => {
        if (onChange) {
          onChange(newValue as Value)
        }
      }}
      width="100%"
      data-testid="select-distributor"
    />
  )
}
