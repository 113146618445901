import { createAddressOptions } from '@shared-ui/components/Ticket/TicketService'
import type { CompanyAddress } from '@shared-ui/generated/graphql'
import { parsePhoneNumber } from '@shared-ui/helper/Phone'
import type { CompanyAddressId, Writeable } from '@shared/types/brands'
import { useSettingsStore } from './SettingsStore'
import type { ResellerAddress, ResellerInfo } from './SettingsTypes'
import type { MyCompanyQuery } from './__generated__/Settings'

export const SettingsService = {
  update: (data: MyCompanyQuery | undefined) => {
    if (!data || !data.myCompany) {
      return
    }

    const company = data.myCompany

    SettingsService.updateInfo({
      name: company.name || '',
      nameLegal: company.nameLegal || '',
      email: company.email || '',
      website: company.website || '',
    })
    SettingsService.updateAddresses(data.myCompany.addresses as Writeable<CompanyAddress[]>)
    SettingsService.selectBillingAddress()
  },

  updateInfo: (info: ResellerInfo) => {
    useSettingsStore.getState().updateInfo(info)
  },

  updateAddresses: (addresses: CompanyAddress[]) => {
    const { selectedAddressId } = useSettingsStore.getState()

    const selectedAddress = addresses.find((entry) => entry.id === selectedAddressId)
    if (!selectedAddress) {
      useSettingsStore.getState().selectAddress(toResellerAddress(addresses[0]))
    }

    useSettingsStore.getState().updateAddresses(addresses)

    SettingsService.updateAddressesOptions()
  },

  updateAddressesOptions: () => {
    const { addresses } = useSettingsStore.getState()

    const addressesOptions = createAddressOptions(addresses)

    useSettingsStore.getState().updateAddressesOptions(addressesOptions)
  },

  selectAddress: (id: CompanyAddressId | string) => {
    const { addresses } = useSettingsStore.getState()

    const address = addresses.find((entry) => entry.id === id)

    useSettingsStore.getState().selectAddress(toResellerAddress(address))
  },

  updateAddress: (companyAddress: CompanyAddress) => {
    const { addresses, selectedAddressId } = useSettingsStore.getState()

    const newCompanyAddress = { ...companyAddress }

    const address = addresses.find((entry) => entry.id === newCompanyAddress.id)
    if (address) {
      useSettingsStore.getState().updateAddress(newCompanyAddress)
    } else {
      useSettingsStore.getState().addAddress(newCompanyAddress)
    }

    if (newCompanyAddress.isDefaultBilling && newCompanyAddress.id) {
      useSettingsStore.getState().setBillingAddress(newCompanyAddress.id)
    }

    SettingsService.updateAddressesOptions()

    if ((!selectedAddressId && companyAddress.id) || selectedAddressId === companyAddress.id) {
      SettingsService.selectAddress(companyAddress.id)
    }
  },

  removeAddress: (id: CompanyAddressId) => {
    const { selectedAddressId } = useSettingsStore.getState()

    useSettingsStore.getState().removeAddress(id)

    SettingsService.updateBillingAddress()

    if (selectedAddressId === id) {
      const { addresses } = useSettingsStore.getState()
      const selectedAddress = addresses[0]

      useSettingsStore.getState().selectAddress(toResellerAddress(selectedAddress))
    }

    SettingsService.updateAddressesOptions()
  },

  updateBillingAddress: () => {
    const { addresses, selectedAddressId } = useSettingsStore.getState()

    if (addresses.length === 1) {
      const newBillingAddress = addresses[0]
      if (newBillingAddress && newBillingAddress.id) {
        useSettingsStore.getState().setBillingAddress(newBillingAddress.id)
      }

      if (selectedAddressId === newBillingAddress.id) {
        SettingsService.updateSelectedAddress()
      }
    }
  },

  selectBillingAddress: () => {
    const { addresses } = useSettingsStore.getState()

    const billingAddress = addresses.find((entry) => entry.isDefaultBilling)
    if (billingAddress && billingAddress.id) {
      SettingsService.selectAddress(billingAddress.id)
    }
  },

  updateSelectedAddress: () => {
    const { addresses, selectedAddressId } = useSettingsStore.getState()

    const address = addresses.find((entry) => entry.id === selectedAddressId)
    if (address) {
      useSettingsStore.getState().selectAddress(toResellerAddress(address))
    }
  },

  addNew: () => {
    SettingsService.selectAddress('')
  },

  showRemovePopup: () => {
    useSettingsStore.getState().setShowRemovePopup(true)
  },

  hideRemovePopup: () => {
    useSettingsStore.getState().setShowRemovePopup(false)
  },

  haveInfoChanged: (input: ResellerInfo) => {
    const { form } = useSettingsStore.getState()

    const hasChanged =
      input.name !== form.info.name ||
      input.nameLegal !== form.info.nameLegal ||
      input.website !== form.info.website ||
      input.email !== form.info.email

    return hasChanged
  },

  toCompanyAddress: (address: ResellerAddress): CompanyAddress => {
    return {
      address1: address.address1,
      address2: '',
      city: address.city,
      country: address.country,
      id: address.id as CompanyAddressId,
      isDefaultBilling: address.isDefaultBilling,
      isDefaultShipping: address.isDefaultShipping,
      phone: address.phone,
      state: address.state,
      street: address.address1,
      zipcode: address.zipcode,
    }
  },
}

const toResellerAddress = (address: CompanyAddress | undefined): ResellerAddress => {
  if (!address) {
    return {
      id: '',
      address1: '',
      city: '',
      country: '',
      isDefaultBilling: false,
      isDefaultShipping: false,
      phone: '',
      phoneCode: 'AD',
      state: '',
      zipcode: '',
    }
  }

  const phoneNumber = parsePhoneNumber(address.phone)

  return {
    id: address.id || '',
    address1: address.address1 || '',
    city: address.city || '',
    country: address.country || '',
    phone: phoneNumber.number,
    state: address.state || '',
    phoneCode: phoneNumber.country,
    zipcode: address.zipcode || '',
    isDefaultShipping: false,
    isDefaultBilling: address.isDefaultBilling || false,
  }
}
