import type { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useCallback } from 'react'
import useNestedPath from '../../hooks/useNestedPath'
import { useNavigate } from '../../hooks'

interface Props {
  TicketDetails: FC<{ onClose: () => void }>
  searchQuery?: string
}

export const TicketsTableWrap: FC<Props> = ({ children, searchQuery, TicketDetails }) => {
  const nested = useNestedPath()
  const navigate = useNavigate()
  const onTicketClose = useCallback(() => navigate(nested(searchQuery ? `?${searchQuery}` : '')), [
    navigate,
    nested,
    searchQuery,
  ])

  return (
    <>
      <Route>
        {children}

        <Switch>
          <Route path={nested('/done')} />
          <Route path={nested('/:ticketId')}>
            <TicketDetails onClose={onTicketClose} />
          </Route>
        </Switch>
      </Route>
    </>
  )
}
