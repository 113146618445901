import type { CompanyId, TicketId } from '@shared/types/brands'
import { Loader, Modal } from '@ubnt/ui-components'
import { useState } from 'react'
import { Container } from '../../../Container'
import { Flex } from '../../../Flex'
import { Grid } from '../../../Grid'
import { RequestError } from '../../../RequestError'
import { Dropdown } from '../../../SprintShared'
import { Text } from '../../../Text'
import {
  useUpdateHandler_DeviceSellerParentsQuery as useDeviceSellerParentsQuery,
  useUpdateHandler_UpdateTicketDeviceMutation as useUpdateTicketDeviceMutation,
} from './__generated__/UpdateHandler'

export type Props = {
  ticketId: TicketId
  onClose: () => void
}

export const UpdateHandler = ({ ticketId, onClose }: Props) => {
  const { loading: parentsLoading, data: parentsData, error: parentsError } = useDeviceSellerParentsQuery()
  const [updateTicketDevice, { loading: updateLoading, error: updateError }] = useUpdateTicketDeviceMutation()
  const [handlerId, setHandlerId] = useState<CompanyId>()

  const onSave = async () => {
    try {
      await updateTicketDevice({ variables: { input: { ticketId, sellerId: handlerId } } })
      onClose()
    } catch {
      //
    }
  }

  return (
    <Modal
      isOpen
      title="Handled by Company"
      onRequestClose={onClose}
      actions={[
        {
          text: 'Cancel',
          variant: 'secondary',
          onClick: onClose,
          disabled: updateLoading,
        },
        {
          text: 'Save',
          variant: 'primary',
          onClick: onSave,
          disabled: !handlerId || updateLoading,
        },
      ]}
    >
      <Container $minHeight="100px">
        {(() => {
          if (parentsError || parentsLoading) {
            return <Flex center>{parentsError ? <Text>Error loading companies.</Text> : <Loader />}</Flex>
          }

          const options = (parentsData?.myCompany?.parents || []).map((company) => ({
            label: company.name,
            value: company.id,
          }))

          if (!options?.length) {
            return <Text>Device handling has been assigned to Ubiquiti.</Text>
          }

          return (
            <Grid $container gap="m">
              <Grid $item $xs={12}>
                <Flex center>
                  <Dropdown value={handlerId} options={options} onChange={(option) => setHandlerId(option.value)} />
                </Flex>
              </Grid>

              {(updateLoading || updateError) && (
                <Grid $item $xs={12}>
                  <Flex center>{updateError ? <RequestError error={updateError} /> : <Loader size="small" />}</Flex>
                </Grid>
              )}
            </Grid>
          )
        })()}
      </Container>
    </Modal>
  )
}
