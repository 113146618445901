// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

import type { Submit_Step1_ViewFailureFragment } from './Step1'

export type UpdateTicket_UpdateTicketMutationVariables = Types.Exact<{
  input: Types.UpdateTicketInput
}>

export type UpdateTicket_UpdateTicketMutation = { readonly __typename?: 'Mutation' } & {
  readonly updateTicket: { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> &
    Submit_Step1_ViewFailureFragment
}

export const UpdateTicket_UpdateTicketDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTicket_UpdateTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTicketInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTicket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Submit_Step1_ViewFailure' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Submit_Step1_ViewFailure' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handlerFailureCategoryId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rmaParentCategoryId' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UpdateTicket_UpdateTicketMutationFn = Apollo.MutationFunction<
  UpdateTicket_UpdateTicketMutation,
  UpdateTicket_UpdateTicketMutationVariables
>

/**
 * __useUpdateTicket_UpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicket_UpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicket_UpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketUpdateTicketMutation, { data, loading, error }] = useUpdateTicket_UpdateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicket_UpdateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicket_UpdateTicketMutation,
    UpdateTicket_UpdateTicketMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateTicket_UpdateTicketMutation, UpdateTicket_UpdateTicketMutationVariables>(
    UpdateTicket_UpdateTicketDocument,
    baseOptions,
  )
}
export type UpdateTicket_UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicket_UpdateTicketMutation>
export type UpdateTicket_UpdateTicketMutationResult = Apollo.MutationResult<UpdateTicket_UpdateTicketMutation>
export type UpdateTicket_UpdateTicketMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicket_UpdateTicketMutation,
  UpdateTicket_UpdateTicketMutationVariables
>
