// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type Tickets_Active_OutgoingTicketsQueryVariables = Types.Exact<{
  query: Types.TicketsQuery
  cursor: Types.Maybe<Types.Scalars['Cursor']>
  limit: Types.Maybe<Types.Scalars['Int']>
}>

export type Tickets_Active_OutgoingTicketsQuery = { readonly __typename?: 'Query' } & {
  readonly tickets: { readonly __typename?: 'TicketsResult' } & {
    readonly pageInfo: { readonly __typename?: 'PageInfo' } & Pick<
      Types.PageInfo,
      'endCursor' | 'hasNextPage' | 'total'
    >
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<
        Types.Ticket,
        'id' | 'createdAt' | 'updatedAt' | 'status' | 'shipmentFromUid' | 'supportByName'
      > & {
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'mac' | 'productImage'
          >
        }
    >
  }
}

export const Tickets_Active_OutgoingTicketsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tickets_Active_OutgoingTickets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsQuery' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tickets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentFromUid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useTickets_Active_OutgoingTicketsQuery__
 *
 * To run a query within a React component, call `useTickets_Active_OutgoingTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTickets_Active_OutgoingTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTickets_Active_OutgoingTicketsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTickets_Active_OutgoingTicketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Tickets_Active_OutgoingTicketsQuery,
    Tickets_Active_OutgoingTicketsQueryVariables
  >,
) {
  return Apollo.useQuery<Tickets_Active_OutgoingTicketsQuery, Tickets_Active_OutgoingTicketsQueryVariables>(
    Tickets_Active_OutgoingTicketsDocument,
    baseOptions,
  )
}
export function useTickets_Active_OutgoingTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Tickets_Active_OutgoingTicketsQuery,
    Tickets_Active_OutgoingTicketsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Tickets_Active_OutgoingTicketsQuery, Tickets_Active_OutgoingTicketsQueryVariables>(
    Tickets_Active_OutgoingTicketsDocument,
    baseOptions,
  )
}
export type Tickets_Active_OutgoingTicketsQueryHookResult = ReturnType<typeof useTickets_Active_OutgoingTicketsQuery>
export type Tickets_Active_OutgoingTicketsLazyQueryHookResult = ReturnType<
  typeof useTickets_Active_OutgoingTicketsLazyQuery
>
export type Tickets_Active_OutgoingTicketsQueryResult = Apollo.QueryResult<
  Tickets_Active_OutgoingTicketsQuery,
  Tickets_Active_OutgoingTicketsQueryVariables
>
