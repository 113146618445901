import { FooterContainer } from '@shared-ui/components/FooterContainer'
import type { FooterNavLink } from '@shared-ui/components/FooterContainer'

const navLinks: FooterNavLink[] = [
  {
    to: '/terms-of-service',
    label: 'Terms of Service',
  },
  {
    to: 'https://www.ui.com/legal/privacypolicy/',
    label: 'Privacy Policy',
  },
  {
    to: '/policy-and-procedures',
    label: 'Policy and Procedures',
  },
  {
    to: '/user-guide',
    label: 'User Guide',
  },
]

const Footer = () => <FooterContainer navLinks={navLinks} />

export default Footer
