// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../generated/graphql'

export type FullAddressFragment = { readonly __typename?: 'CompanyAddress' } & Pick<
  Types.CompanyAddress,
  | 'id'
  | 'address1'
  | 'address2'
  | 'street'
  | 'city'
  | 'state'
  | 'country'
  | 'zipcode'
  | 'phone'
  | 'isDefaultShipping'
  | 'isDefaultBilling'
>

export type MyCompanyAddressesQueryVariables = Types.Exact<{ [key: string]: never }>

export type MyCompanyAddressesQuery = { readonly __typename?: 'Query' } & {
  readonly myCompany: Types.Maybe<
    { readonly __typename?: 'Company' } & Pick<Types.Company, 'id'> & {
        readonly addresses: ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        readonly rmaAddresses: Types.Maybe<
          ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        >
      }
  >
}

export const FullAddressFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
        ],
      },
    },
  ],
}
export const MyCompanyAddressesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyCompanyAddresses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rmaAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
        ],
      },
    },
  ],
}

/**
 * __useMyCompanyAddressesQuery__
 *
 * To run a query within a React component, call `useMyCompanyAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompanyAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<MyCompanyAddressesQuery, MyCompanyAddressesQueryVariables>,
) {
  return Apollo.useQuery<MyCompanyAddressesQuery, MyCompanyAddressesQueryVariables>(
    MyCompanyAddressesDocument,
    baseOptions,
  )
}
export function useMyCompanyAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCompanyAddressesQuery, MyCompanyAddressesQueryVariables>,
) {
  return Apollo.useLazyQuery<MyCompanyAddressesQuery, MyCompanyAddressesQueryVariables>(
    MyCompanyAddressesDocument,
    baseOptions,
  )
}
export type MyCompanyAddressesQueryHookResult = ReturnType<typeof useMyCompanyAddressesQuery>
export type MyCompanyAddressesLazyQueryHookResult = ReturnType<typeof useMyCompanyAddressesLazyQuery>
export type MyCompanyAddressesQueryResult = Apollo.QueryResult<
  MyCompanyAddressesQuery,
  MyCompanyAddressesQueryVariables
>
