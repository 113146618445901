import type { TicketId } from '@shared/types/brands'
import { useParams } from 'react-router-dom'
import { DetailsTab, Ticket as TicketShared } from '../../../Ticket'
import { tabs as sharedTabs } from '../../common'
import { useTickets_TicketQuery as useTicketQuery } from '../../__generated__/common'

const detailsTabWithProps = {
  ...DetailsTab,
  props: {
    displayCustomer: true,
  },
}
// Have all common tabs, just enhance the Details tab.
const tabs = [detailsTabWithProps, ...sharedTabs.filter((tab) => tab !== DetailsTab)]

interface UrlParams {
  ticketId: TicketId
}

interface TicketProps {
  onClose: () => void
}
export const Ticket = ({ onClose }: TicketProps) => {
  const { ticketId } = useParams<UrlParams>()
  const { data, loading, error } = useTicketQuery({ variables: { id: ticketId } })

  return (
    <TicketShared tabs={tabs} loading={loading} error={error} ticket={data?.ticket ?? undefined} onClose={onClose} />
  )
}
