import type { TicketId } from '@shared/types/brands'
import { useParams } from 'react-router-dom'
import { CustomerTab, DetailsTab, LogTab, MessagingTab, Ticket as TicketShared } from '../../../Ticket'
import { useTickets_TicketQuery as useTicketQuery } from '../../__generated__/common'

interface UrlParams {
  ticketId: TicketId
}

interface TicketProps {
  onClose: () => void
}
export const Ticket = ({ onClose }: TicketProps) => {
  const { ticketId } = useParams<UrlParams>()
  const { data, loading, error } = useTicketQuery({ variables: { id: ticketId } })

  const tabs = [DetailsTab, CustomerTab, MessagingTab, LogTab]

  return (
    <TicketShared tabs={tabs} loading={loading} error={error} ticket={data?.ticket ?? undefined} onClose={onClose} />
  )
}
