// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type Shared_TicketFragment = { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id' | 'status' | 'flow'>

export type FullShippingAddressFragment = { readonly __typename?: 'ShippingAddress' } & Pick<
  Types.ShippingAddress,
  'name' | 'phoneNumber' | 'email' | 'country' | 'address1' | 'address2' | 'city' | 'state' | 'zipcode'
>

export type CustomerTicketFragment = { readonly __typename?: 'Ticket' } & Pick<
  Types.Ticket,
  'id' | 'createdAt' | 'closedAt' | 'status' | 'customerName' | 'supportByName' | 'supportByUbiquiti'
> & {
    readonly device: { readonly __typename?: 'Device' } & Pick<Types.Device, 'id' | 'name' | 'mac' | 'productImage'>
    readonly rating: Types.Maybe<{ readonly __typename?: 'Rating' } & Pick<Types.Rating, 'rating'>>
    readonly shipment: Types.Maybe<
      { readonly __typename?: 'Shipment' } & Pick<
        Types.Shipment,
        'id' | 'uid' | 'trackingNumber' | 'nsError' | 'toUbiquiti'
      >
    >
    readonly shipmentAddress: { readonly __typename?: 'ShippingAddress' } & FullShippingAddressFragment
  }

export type CompanyDropdownQueryVariables = Types.Exact<{ [key: string]: never }>

export type CompanyDropdownQuery = { readonly __typename?: 'Query' } & {
  readonly myCompany: Types.Maybe<
    { readonly __typename?: 'Company' } & {
      readonly parents: ReadonlyArray<{ readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name'>>
    }
  >
}

export type GetTicketNsErrorsQueryVariables = Types.Exact<{
  ticketIds: ReadonlyArray<Types.Scalars['String']> | Types.Scalars['String']
}>

export type GetTicketNsErrorsQuery = { readonly __typename?: 'Query' } & {
  readonly getRmaErrorsFromProcessor: ReadonlyArray<
    Types.Maybe<
      { readonly __typename?: 'RmaTicketProcessorErrorResponse' } & Pick<
        Types.RmaTicketProcessorErrorResponse,
        'rmaPortalId'
      > & {
          readonly rmaErrorData: ReadonlyArray<
            { readonly __typename?: 'RmaProcessorErrorData' } & Pick<
              Types.RmaProcessorErrorData,
              'jobName' | 'isFinished' | 'hadError' | 'lastErrorObject'
            >
          >
        }
    >
  >
}

export const Shared_TicketFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
        ],
      },
    },
  ],
}
export const FullShippingAddressFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullShippingAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
        ],
      },
    },
  ],
}
export const CustomerTicketFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerTicket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByUbiquiti' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rating' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nsError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toUbiquiti' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullShippingAddress' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullShippingAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
        ],
      },
    },
  ],
}
export const CompanyDropdownDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyDropdown' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useCompanyDropdownQuery__
 *
 * To run a query within a React component, call `useCompanyDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyDropdownQuery, CompanyDropdownQueryVariables>,
) {
  return Apollo.useQuery<CompanyDropdownQuery, CompanyDropdownQueryVariables>(CompanyDropdownDocument, baseOptions)
}
export function useCompanyDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyDropdownQuery, CompanyDropdownQueryVariables>,
) {
  return Apollo.useLazyQuery<CompanyDropdownQuery, CompanyDropdownQueryVariables>(CompanyDropdownDocument, baseOptions)
}
export type CompanyDropdownQueryHookResult = ReturnType<typeof useCompanyDropdownQuery>
export type CompanyDropdownLazyQueryHookResult = ReturnType<typeof useCompanyDropdownLazyQuery>
export type CompanyDropdownQueryResult = Apollo.QueryResult<CompanyDropdownQuery, CompanyDropdownQueryVariables>
export const GetTicketNsErrorsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTicketNsErrors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ticketIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRmaErrorsFromProcessor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rmaPortalIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ticketIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rmaPortalId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rmaErrorData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'jobName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFinished' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hadError' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastErrorObject' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetTicketNsErrorsQuery__
 *
 * To run a query within a React component, call `useGetTicketNsErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketNsErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketNsErrorsQuery({
 *   variables: {
 *      ticketIds: // value for 'ticketIds'
 *   },
 * });
 */
export function useGetTicketNsErrorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTicketNsErrorsQuery, GetTicketNsErrorsQueryVariables>,
) {
  return Apollo.useQuery<GetTicketNsErrorsQuery, GetTicketNsErrorsQueryVariables>(
    GetTicketNsErrorsDocument,
    baseOptions,
  )
}
export function useGetTicketNsErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTicketNsErrorsQuery, GetTicketNsErrorsQueryVariables>,
) {
  return Apollo.useLazyQuery<GetTicketNsErrorsQuery, GetTicketNsErrorsQueryVariables>(
    GetTicketNsErrorsDocument,
    baseOptions,
  )
}
export type GetTicketNsErrorsQueryHookResult = ReturnType<typeof useGetTicketNsErrorsQuery>
export type GetTicketNsErrorsLazyQueryHookResult = ReturnType<typeof useGetTicketNsErrorsLazyQuery>
export type GetTicketNsErrorsQueryResult = Apollo.QueryResult<GetTicketNsErrorsQuery, GetTicketNsErrorsQueryVariables>
