import { ExpandedContainer } from '@shared-ui/components/Container'
import { MessageContainer } from '@shared-ui/components/MessageContainer'
import { Loader, Text } from '@ubnt/ui-components'
import type { AxiosError } from 'axios'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Document } from 'rma-frontend-shared/components/Document'
import styled from 'styled-components'

const baseUrl = process.env.REACT_APP_API_URL ?? ''
if (!baseUrl) {
  throw new Error('REACT_APP_API_URL env variable required')
}

type TosData = {
  version: number
  html: string
}

const useFetchTos = () => {
  const [data, setData] = useState<TosData | null>(null)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    const fetchTos = async () => {
      try {
        const response = await axios.get<TosData>(`${baseUrl}/tos`, {
          headers: {
            authorization: accessToken,
          },
        })
        setData(response.data)
      } catch (er) {
        setError((er as AxiosError).message)
      }
    }

    void fetchTos()
  }, [])

  return {
    data,
    error,
  }
}

export const TermsOfService = () => {
  const { data, error } = useFetchTos()

  if (error) {
    return (
      <ExpandedContainer $centered>
        <MessageContainer>
          <Text>{error}</Text>
        </MessageContainer>
      </ExpandedContainer>
    )
  }

  if (!data) {
    return (
      <ExpandedContainer $centered>
        <MessageContainer>
          <Loader />
        </MessageContainer>
      </ExpandedContainer>
    )
  }

  return (
    <div className="p-20 overflow">
      <Document heading="Terms of Service">
        <InnerHTMLContainer dangerouslySetInnerHTML={{ __html: data.html }} />
      </Document>
    </div>
  )
}

const InnerHTMLContainer = styled.div``
