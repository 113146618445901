import { Table as UiTable } from '@ubnt/ui-components'
import type { ComponentProps } from 'react'
import styled from 'styled-components'

export type { TableColumnConfig, TableItem } from '@ubnt/ui-components'

export type Props = ComponentProps<typeof UiTable>

export const Table = styled(UiTable).attrs({ hideCellOverflow: false })`
  tbody > tr {
    /* Go inline styles! */
    height: auto !important;
    min-height: 32px;
    /* For disabled row, allow pointer events (so links can be clicked) and set opacity to normal. */
    pointer-events: auto !important;
    opacity: 1 !important;
  }
` as typeof UiTable
