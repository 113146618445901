import type { ShipmentId, ShipmentUId, TicketId } from '@shared/types/brands'
import { BoxIcon } from '@ubnt/icons'
import { Loader, Text } from '@ubnt/ui-components'
import type { TabValue } from '@ubnt/ui-components/PanelTab/PanelTab'
import { useMemo } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import useNestedPath from '../../hooks/useNestedPath'
import { MessageContainer } from '../MessageContainer'
import { FixedSidePanel } from '../SprintShared'
import { Inventory } from './Inventory'
import { ShippingTab } from './ShippingTab'
import { useShipmentQuery as useQuery } from './__generated__/ShipmentTabContainer'

const sections = [
  ShippingTab,
  {
    tab: {
      label: 'Inventory',
      id: '/inventory',
      icon: BoxIcon,
    },
    component: Inventory,
  },
]

type UrlParam = {
  shipmentId: string
}

type ShipmentTabContainerProps = {
  onClose: () => void
}
export const ShipmentTabContainer = ({ onClose }: ShipmentTabContainerProps) => {
  const { shipmentId: shipmentIdInput } = useParams<UrlParam>()
  const { pathname } = useLocation()

  const activeTab = sections.find(({ tab }) => pathname.endsWith(tab.id))?.tab.id

  const shipmentId = parseInt(shipmentIdInput, 10) as ShipmentId
  const { data, loading, error } = useQuery({ variables: { id: shipmentId } })
  const nested = useNestedPath()
  const history = useHistory()

  const panelTabs = useMemo(
    () =>
      sections.map((section) => ({
        value: section.tab.id,
        icon: section.tab.icon,
        label: section.tab.label,
      })),
    [sections],
  )

  const shipment = data?.shipment ?? undefined

  return (
    <FixedSidePanel
      in
      onClose={onClose}
      header={shipment && <>Shipment #{shipment.uid}</>}
      tabs={panelTabs}
      tabValue={activeTab || panelTabs[0].value}
      onTabChange={(tabPath: TabValue) => history.push(nested(tabPath as string))}
    >
      {(() => {
        if (loading || error || !shipment) {
          return (
            <MessageContainer>
              {(() => {
                if (loading) {
                  return <Loader size="small" />
                }
                if (error) {
                  return <Text centered>{error.message}</Text>
                }
                if (!shipment) {
                  return <Text>Shipment not found.</Text>
                }

                return null
              })()}
            </MessageContainer>
          )
        }

        return (
          <Switch>
            {sections.map(({ tab, component: Component }) => (
              <Route key={tab.id} path={nested(tab.id)}>
                <Component
                  shipment={shipment}
                  linkToTickets={(ticketId: TicketId, shipmentUid: ShipmentUId) =>
                    `/tickets/active/${ticketId}/details#${shipmentUid}`
                  }
                />
              </Route>
            ))}
            <Route exact path={nested('')}>
              <Redirect to={nested(sections[0].tab.id)} />
            </Route>
          </Switch>
        )
      })()}
    </FixedSidePanel>
  )
}
