import { AuthService } from '@shared-ui/auth/auth-service'
import { HeadingTitle2, PaddedContainer } from '@shared-ui/components'
import { Button } from '@shared-ui/components/Button'
import { Container } from '@shared-ui/components/Container'
import { Flex } from '@shared-ui/components/Flex'
import { Grid } from '@shared-ui/components/Grid'
import { RequestError } from '@shared-ui/components/RequestError'
import { Text } from '@shared-ui/components/Text'
import { CheckboxFormField } from '@shared-ui/components/form/Checkbox'
import { DropdownField } from '@shared-ui/components/form/Dropdown'
import { InputField } from '@shared-ui/components/form/Input'
import useNavigate from '@shared-ui/hooks/useNavigate'
import { canadaStates, countryList, haveStatesEnabled, usaStates } from '@shared/lib/constants'
import { ResellerAddressCreateInputSchema, ResellerSettingsSchema } from '@shared/lib/validation/schemas'
import type { DropdownOption } from '@ubnt/ui-components'
import { Loader, cssVariables } from '@ubnt/ui-components'
import { Field, Formik, useFormikContext } from 'formik'
import type { CountryCode } from 'libphonenumber-js/min'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/min'
import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import { useSignUpResellerMutation } from './__generated__/SignupForm'

const Separator = styled.div`
  height: 14px;
`

const addressSchema = yup.object({
  reseller: ResellerSettingsSchema,
  address: ResellerAddressCreateInputSchema,
  billingAddress: yup.object().when('address.isDefaultBilling', {
    is: true,
    then: yup.object(),
    otherwise: ResellerAddressCreateInputSchema,
  }),
})

const INITIAL_VALUES = {
  reseller: {
    name: '',
    nameLegal: '',
    website: '',
    email: '',
  },
  address: {
    address1: '',
    address2: null,
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phone: '',
    isDefaultBilling: true,
    isDefaultShipping: true,
  },
  billingAddress: {
    address1: '',
    address2: null,
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phone: '',
    isDefaultBilling: true,
    isDefaultShipping: false,
  },
}

type FormType = typeof INITIAL_VALUES

type UrlParam = {
  hash: string
}

interface SingupFormProps {
  onComplete: () => void
}
export const SignupForm = ({ onComplete }: SingupFormProps) => {
  const { hash } = useParams<UrlParam>()
  const navigate = useNavigate()
  const [signUpReseller, { called, loading, error, data }] = useSignUpResellerMutation()

  const handleSubmit = useCallback(
    async ({ reseller, address, billingAddress }: FormType) => {
      const input = {
        reseller,
        address: {
          ...address,
          phone: (parsePhoneNumberFromString(address.phone)?.number ?? '') as string,
        },
        billingAddress: address.isDefaultBilling
          ? null
          : {
              ...billingAddress,
              phone: (parsePhoneNumberFromString(billingAddress.phone)?.number ?? '') as string,
            },
        hash,
      }

      await signUpReseller({ variables: { input } })
    },
    [hash, signUpReseller],
  )

  const signUpSucceeded = called && data?.resellerSignUp === true

  useEffect(() => {
    if (signUpSucceeded) {
      onComplete()
      navigate('/')
      void AuthService.authorize()
    }
  }, [signUpSucceeded, navigate, onComplete])

  if (signUpSucceeded) {
    return (
      <Flex full center>
        <Loader />
      </Flex>
    )
  }

  // TODO: How to get pre-fill data?
  const initialValues: FormType = {
    ...INITIAL_VALUES,
  }

  const signUpFailed = called && data?.resellerSignUp === false

  return (
    <Grid $container justify="center">
      <Container $maxWidth="520px" $padding={['xl', 0]}>
        <PaddedContainer $padding="28px">
          <HeadingTitle2>Join Ubiquiti&apos;s Growing Reseller Network!</HeadingTitle2>

          {!error && signUpFailed && (
            <Text color="danger" weight="bold">
              Sign up failed, please contact an Administrator.
            </Text>
          )}

          {error && <RequestError color="danger" error={error} />}

          <Text $size="l2">Fill out this form to register your business in our reseller RMA portal.</Text>

          <Separator />
          <Separator />

          <Formik initialValues={initialValues} validationSchema={addressSchema} onSubmit={handleSubmit}>
            <Form loading={loading} />
          </Formik>
        </PaddedContainer>
      </Container>
    </Grid>
  )
}

const Form = ({ loading }: { loading: boolean }) => {
  const { values, submitForm } = useFormikContext<FormType>()

  return (
    <div>
      <Text heading="primary">Tell us about your company.</Text>

      <Separator />

      <Field label="Store name" name="reseller.name" component={InputField} />

      <Field
        label="Legal name (as it appears on your tax or incorporation documents)"
        name="reseller.nameLegal"
        component={InputField}
      />

      <Field label="Website" name="reseller.website" component={InputField} />

      <Field label="Email" name="reseller.email" component={InputField} />

      <Separator />

      <Text heading="primary">Tell us where you&apos;d prefer to receive RMA products.</Text>

      <Address address="address" />

      <Field
        style={{ width: '100%' }}
        label="This address is the same as my billing address."
        name="address.isDefaultBilling"
        component={CheckboxFormField}
      />

      {!values.address.isDefaultBilling && (
        <>
          <Text heading="primary">RMA Billing Address</Text>

          <Address address="billingAddress" />

          <Separator />
        </>
      )}

      <Separator />

      <Button type="submit" full variant="primary" onClick={submitForm} disabled={loading}>
        Register
      </Button>
    </div>
  )
}

const Address = ({ address }: { address: 'address' | 'billingAddress' }) => {
  const { values, setFieldValue } = useFormikContext<FormType>()
  const asYouType = useMemo(() => new AsYouType(), [])

  const stateListUS = useMemo(() => Object.keys(usaStates).map((state) => ({ value: state, label: state })), [])
  const stateListCA = useMemo(() => Object.keys(canadaStates).map((state) => ({ value: state, label: state })), [])

  return (
    <>
      <Separator />

      <Field label="Address" name={`${address}.address1`} component={InputField} />

      <Field label="City" name={`${address}.city`} component={InputField} />

      <Group style={{ marginTop: -20 }}>
        <Field
          name={`${address}.country`}
          width="100%"
          options={countryList}
          component={DropdownField}
          onChange={(option: DropdownOption) => {
            setFieldValue(`${address}.country`, option.value)
            if (!haveStatesEnabled(`${option.value as CountryCode}`)) {
              setFieldValue(`${address}.state`, '')
            }
          }}
          label="Country"
        />
        <Field
          disabled={!haveStatesEnabled(values[address].country as CountryCode)}
          name={`${address}.state`}
          width="100%"
          options={values[address].country === 'CA' ? stateListCA : stateListUS}
          component={DropdownField}
          label="State"
          size="body"
        />
      </Group>
      <Group>
        <Field label="Zip code" name={`${address}.zipcode`} component={InputField} />
        <Field
          label="Phone number"
          name={`${address}.phone`}
          type="number"
          component={InputField}
          onChange={(e: ChangeEvent, input: string) => {
            asYouType.reset()
            const phone = asYouType.input(input)
            setFieldValue(`${address}.phone`, phone)
          }}
        />
      </Group>
      <Separator />
    </>
  )
}

const Group = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    width: calc(50% - ${cssVariables['spacing-m']} / 2);
    margin-bottom: ${cssVariables['spacing-xl']};

    &:first-child {
      margin-right: ${cssVariables['spacing-m']};
    }
  }
`
