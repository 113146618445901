import { Text } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled from 'styled-components'

export function SubNavigationBar({ left, title, right }: { left?: ReactNode; title?: ReactNode; right?: ReactNode }) {
  return (
    <Nav>
      <NavLeft>{left}</NavLeft>
      <NavTitle>{title}</NavTitle>
      <NavRight>{right}</NavRight>
    </Nav>
  )
}

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  min-height: 51px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.white6};
`

const NavLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 49px;
  flex: 1;
  margin-left: 2px;
`

const NavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

const NavTitle = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  flex: 1;
`
