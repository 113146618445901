import { useParams } from 'react-router-dom'

import type { TicketId } from '@shared/types/brands'

import { Ticket as TicketShared } from '../../../Ticket'
import { useTickets_TicketQuery as useTicketQuery } from '../../__generated__/common'
import { tabs } from '../../common'

export type Props = {
  onClose: () => void
}

type UrlParams = {
  ticketId: TicketId
}

export const Ticket = ({ onClose }: Props) => {
  const { ticketId } = useParams<UrlParams>()
  const { data, loading, error } = useTicketQuery({ variables: { id: ticketId } })

  return (
    <TicketShared tabs={tabs} loading={loading} error={error} ticket={data?.ticket ?? undefined} onClose={onClose} />
  )
}
