import { AugmentText, Loader, Table } from '@ubnt/ui-components'
import type { Shipment } from 'generated/graphql'
import { useEffect, useMemo } from 'react'
import type { PaginationInfo } from 'rma-shared/types/table'
import { useGoTo, useRequest } from '../../hooks'
import { LoaderContainer } from '../Containers'
import { PaginationControls } from '../PaginationControls'
import { StickyTableContainer, StickyTableWrap } from '../StickyTable'
import type { TableColumnConfig } from '../Table'
import { ViewRow } from '../table-columns'
import { Text } from '../Text'
import { useShipmentsStore } from './shipments-store'

export function ShipmentsTableActive({ search, stickyHeadTop }: { search: string; stickyHeadTop: string }) {
  type Column = Pick<Shipment, 'id' | 'uid' | 'deviceCount' | 'status' | 'destination' | 'trackingNumber'>

  const goTo = useGoTo()

  const { pageInfo } = useShipmentsStore()
  const [fetch, { data, loading, error }] = useRequest('companyShipments')

  const columns: TableColumnConfig<Column>[] = useMemo(
    () => [
      {
        id: 'id',
        label: 'Shipment',
        renderCell: (row) => <ViewRow id={row.id} text={row.uid} />,
      },
      {
        id: 'deviceCount',
        label: 'No. of devices',
        renderCell: (row) => <Text>{row.deviceCount}</Text>,
      },
      {
        id: 'status',
        label: 'Status',
        renderCell: (row) => (
          <Text ellipsis title={row.status}>
            {row.status}
          </Text>
        ),
      },
      {
        id: 'destination',
        label: 'Destination',
        renderCell: (row) => <Text>{row.destination}</Text>,
      },
      {
        id: 'trackingNumber',
        label: 'Tracking',
        renderCell: (row) => <Text>{row.trackingNumber}</Text>,
      },
    ],
    [],
  )

  const loadPage = async ({ limit, offset }: PaginationInfo) => {
    try {
      const result = await fetch({
        limit,
        offset,
        search,
        type: 'open',
      })

      useShipmentsStore.setState({
        pageInfo: {
          limit,
          offset,
          total: result.total,
        },
      })
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    void loadPage({
      limit: pageInfo.limit,
      offset: 0,
      total: 0,
    })
  }, [search])

  if (loading || error || !data) {
    return (
      <StickyTableWrap>
        <LoaderContainer>{error ? <AugmentText>Error loading data.</AugmentText> : <Loader />}</LoaderContainer>
      </StickyTableWrap>
    )
  }

  const { items } = data

  return (
    <StickyTableWrap>
      <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
        <Table
          columns={columns}
          items={items}
          initialSortBy="id"
          disableColumnFilters
          rowHeight={50}
          headerHeight={50}
          onRowClick={(item) => goTo(`/${item.id}`)}
          renderPlaceholder={() => <Text size="inherit">No incoming tickets.</Text>}
          renderFooter={() => <PaginationControls pageInfo={pageInfo} loadPage={loadPage} itemsLabel="shipments" />}
        />
      </StickyTableContainer>
    </StickyTableWrap>
  )
}
