import { Container } from '@shared-ui/components/Container'
import { Flex } from '@shared-ui/components/Flex'
import { Grid } from '@shared-ui/components/Grid'
import { Image } from '@shared-ui/components/Image'
import { Text } from '@shared-ui/components/Text'
import { CheckboxFormFieldExt, CheckboxValidationMessage } from '@shared-ui/components/form/Checkbox'
import { DropdownField } from '@shared-ui/components/form/Dropdown'
import { InputField } from '@shared-ui/components/form/Input'
import { phoneCodeList as phoneCodeListShared } from '@shared-ui/constants/phoneCodes'
import { parsePhoneNumber } from '@shared-ui/helper/Phone'
import { getFlagPath } from '@shared-ui/utils/flags'
import type { CountryCode } from '@shared/lib/constants'
import { canadaStates, countryList, haveStatesEnabled, usaStates } from '@shared/lib/constants'
import { ResellerAddressCreateInputSchema, ResellerSettingsSchema } from '@shared/lib/validation/schemas'
import type { CompanyAddressId } from '@shared/types/brands'
import type { DropdownOption } from '@ubnt/ui-components'
import { Button, Loader, Modal, cssVariables } from '@ubnt/ui-components'
import { Field, Formik, useFormikContext } from 'formik'
import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { config } from '../../config'
import { SettingsService } from './SettingsService'
import { useSettingsStore } from './SettingsStore'
import type { ResellerForm } from './SettingsTypes'
import {
  useMyCompanyQuery,
  useRemoveAddressMutation,
  useUpdateAddressMutation,
  useUpdateInfoMutation,
} from './__generated__/Settings'

type PhoneCodeOption = Omit<DropdownOption, 'label'> & {
  phoneCode: string
}

const addressSchema = yup.object({
  info: ResellerSettingsSchema,
  address: ResellerAddressCreateInputSchema,
})

export const Settings = () => {
  const settings = useSettingsStore()

  const { data, loading, error } = useMyCompanyQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [requestUpdateInfo, { loading: updateInfoLoading, error: updateInfoError }] = useUpdateInfoMutation()
  const [
    requestRemoveAddress,
    { loading: removeAddressLoading, error: removeAddressError },
  ] = useRemoveAddressMutation()
  const [
    requestUpdateAddress,
    { loading: updateAddressLoading, error: updateAddressError },
  ] = useUpdateAddressMutation()

  useEffect(() => {
    SettingsService.update(data)
  }, [data])

  const handleSubmit = useCallback(
    async (input: ResellerForm) => {
      try {
        const companyAddress = SettingsService.toCompanyAddress(input.address)
        const haveInfoChanged = SettingsService.haveInfoChanged(input.info)

        if (haveInfoChanged) {
          await requestUpdateInfo({ variables: { input: input.info } })
          SettingsService.updateInfo(input.info)
        }

        const updateResponse = await requestUpdateAddress({ variables: { address: companyAddress } })
        if (updateResponse.data) {
          const address = updateResponse.data.resellerAddressUpdate
          SettingsService.updateAddress(address)
        }
      } catch {
        //
      }
    },
    [requestUpdateAddress, requestUpdateInfo],
  )

  const handleRemoveAddress = async () => {
    SettingsService.hideRemovePopup()

    if (!settings.selectedAddressId) {
      return
    }

    try {
      const removedAddressId = settings.selectedAddressId as CompanyAddressId
      const removeResponse = await requestRemoveAddress({
        variables: { addressId: removedAddressId },
      })
      if (removeResponse.data) {
        const isAddressRemoved = removeResponse.data.resellerAddressDelete
        if (isAddressRemoved) {
          SettingsService.removeAddress(removedAddressId)
        }
      }
    } catch {
      //
    }
  }

  if (loading && !data) {
    return (
      <Flex full center>
        <Loader />
      </Flex>
    )
  }

  const isRemoveDisabled =
    settings.addresses.length <= 1 ||
    removeAddressLoading ||
    settings.selectedAddressId === '' ||
    settings.form.address.isDefaultBilling
  const haveLoading = loading || updateInfoLoading || removeAddressLoading || updateAddressLoading
  const haveError = error || updateInfoError || removeAddressError || updateAddressError

  return (
    <Grid $container justify="center" flex="grow">
      <Container $maxWidth="500px" $padding={['xl', 0]}>
        <Grid $container>
          <Grid $item $xs={12}>
            <Grid $container>
              <HeadingContainer>
                <Text size="header-xl" color="primary">
                  Settings
                </Text>
              </HeadingContainer>

              {!haveLoading && haveError && (
                <Grid $container direction="column" gap="m">
                  <Grid $item>
                    <Grid $container direction="column" gap="xs">
                      {error && (
                        <Text $center color="danger">
                          {error.message}
                        </Text>
                      )}
                      {removeAddressError && (
                        <Text $center color="danger">
                          {removeAddressError.message}
                        </Text>
                      )}
                      {updateAddressError && (
                        <Text $center color="danger">
                          {updateAddressError.message}
                        </Text>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Formik
          initialValues={settings.form}
          enableReinitialize
          validationSchema={addressSchema}
          onSubmit={handleSubmit}
        >
          <Form
            loading={haveLoading}
            isRemoveDisabled={isRemoveDisabled}
            selectedAddressId={settings.selectedAddressId}
            onAddNew={SettingsService.addNew}
            onAddressChange={(option) => SettingsService.selectAddress(option.value.toString())}
            onAddressRemove={SettingsService.showRemovePopup}
          />
        </Formik>
      </Container>

      <Modal
        isOpen={settings.showRemovePopup}
        size="small"
        onRequestClose={SettingsService.hideRemovePopup}
        actions={[
          {
            text: 'Close',
            onClick: SettingsService.hideRemovePopup,
          },
          {
            text: 'Remove',
            variant: 'danger',
            onClick: handleRemoveAddress,
          },
        ]}
      >
        Are you sure that you want to delete this address?
      </Modal>
    </Grid>
  )
}

interface FormProps {
  loading: boolean
  selectedAddressId: string
  isRemoveDisabled: boolean
  onAddNew: () => void
  onAddressChange: (option: DropdownOption) => void
  onAddressRemove: () => void
}
const Form = ({
  loading,
  selectedAddressId,
  isRemoveDisabled,
  onAddNew,
  onAddressChange,
  onAddressRemove: onAddressDelete,
}: FormProps) => {
  const settings = useSettingsStore()
  const { values, submitForm } = useFormikContext<ResellerForm>()

  const isSaveDisabled = settings.form.forceBillingAddress && !values.address.isDefaultBilling

  return (
    <>
      <Heading>Store Details</Heading>

      <Field label="Store Name" name="info.name" component={InputField} />

      <Field label="Legal Name" name="info.nameLegal" component={InputField} />

      <Field label="Website" name="info.website" component={InputField} />

      <Field label="Email" name="info.email" component={InputField} />

      <Heading>RMA Address</Heading>

      <Grid $container gap="m" style={{ marginTop: 20 }}>
        <AddressRow $xs={10}>
          <Field
            placeholder="Addresses"
            value={selectedAddressId}
            width="100%"
            options={settings.addressesOptions}
            component={DropdownField}
            onChange={onAddressChange}
            searchable
          />
        </AddressRow>
        <Grid $container $item $xs={2} justify="center" $alignItems="center">
          <Button variant="link" onClick={onAddNew}>
            Add New
          </Button>
        </Grid>
      </Grid>

      <Address />

      <div style={{ margin: '1em 0' }}>
        <Field
          label="Use this address as Billing Address"
          name="address.isDefaultBilling"
          component={CheckboxFormFieldExt}
        />
        {isSaveDisabled && <CheckboxValidationMessage>Please select a billing address</CheckboxValidationMessage>}
      </div>

      {loading && (
        <Grid $container $item $xs={12} $alignItems="center" justify="center">
          <Loader />
        </Grid>
      )}

      {!loading && (
        <Grid style={{ marginTop: '10px' }} $container $item $xs={12}>
          <Button
            variant="secondary"
            color="danger"
            onClick={onAddressDelete}
            disabled={isRemoveDisabled || values.address.isDefaultBilling}
          >
            Delete
          </Button>

          <Spacer />

          <Button type="submit" variant="primary" onClick={submitForm} disabled={loading || isSaveDisabled}>
            Save Changes
          </Button>
        </Grid>
      )}
    </>
  )
}

const Address = () => {
  const { values, setFieldValue } = useFormikContext<ResellerForm>()

  const phoneCodeList = useMemo(
    () =>
      phoneCodeListShared.map((item) => ({
        ...item,
        image: <Image src={getFlagPath(item.value, config.publicUrl)} />,
      })),
    [],
  )

  const stateListUS = useMemo(() => Object.keys(usaStates).map((state) => ({ value: state, label: state })), [])
  const stateListCA = useMemo(() => Object.keys(canadaStates).map((state) => ({ value: state, label: state })), [])

  return (
    <>
      <AddressRow $xs={12}>
        <Field label="Street" name="address.address1" component={InputField} />
      </AddressRow>

      <div className="flex justify-center align-center flex-1">
        <Grid $container gap="m" $alignItems="flex-end">
          <AddressRow $xs={2}>
            <Field label="City" name="address.city" component={InputField} />
          </AddressRow>

          <AddressRow $xs={5}>
            <Field
              placeholder="Country"
              name="address.country"
              width="100%"
              options={countryList}
              component={DropdownField}
              searchable
              onChange={(option: DropdownOption) => {
                if (values.address.country !== option.value) {
                  setFieldValue('address.state', '')
                }
                setFieldValue('address.country', option.value)
              }}
            />
          </AddressRow>

          <AddressRow $xs={3}>
            <Field
              disabled={!haveStatesEnabled(values.address.country as CountryCode)}
              placeholder="State"
              name="address.state"
              width="100%"
              options={values.address.country === 'CA' ? stateListCA : stateListUS}
              component={DropdownField}
              searchable
            />
          </AddressRow>

          <AddressRow $xs={2}>
            <Field label="Zip code" name="address.zipcode" component={InputField} />
          </AddressRow>
        </Grid>
      </div>

      <Grid $item $xs={12}>
        <Grid $container gap="m" $alignItems="flex-end">
          <Grid $item $xs={2}>
            <Field
              placeholder=""
              name="address.phoneCode"
              width="100%"
              options={phoneCodeList}
              component={DropdownField}
              onChange={(option: PhoneCodeOption) => {
                if (values.address.phoneCode !== option.value) {
                  setFieldValue('address.phoneCode', option.value)
                  setFieldValue('address.phone', option.phoneCode)
                }
              }}
            />
          </Grid>

          <Grid $item $xs={10}>
            <Field
              label="Phone number"
              name="address.phone"
              type="number"
              component={InputField}
              onChange={(_event: ChangeEvent, input: string) => {
                const phone = parsePhoneNumber(input)
                if (phone.code !== values.address.phoneCode && phone.country) {
                  setFieldValue('address.phoneCode', phone.country)
                }
                setFieldValue('address.phone', phone.number)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const HeadingContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`

const Spacer = styled.div`
  flex: 1;
`

const AddressRow = styled(Grid).attrs({ $item: true })`
  margin-bottom: ${cssVariables['spacing-xl']};
`

const Heading = styled(Text).attrs({ heading: 'primary' })`
  margin-bottom: ${cssVariables['spacing-m']};
`
