// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

import type { Shared_Address_CompanyFragment } from '../../../../../../packages/rma-frontend-shared/components/__generated__/CompanyAddress'

export type Join_Invitation_ChildCompanyFragment = { readonly __typename?: 'Company' } & {
  readonly parents: ReadonlyArray<{ readonly __typename?: 'Company' } & Pick<Types.Company, 'id'>>
} & Shared_Address_CompanyFragment

export type Join_Invitation_InvitationRelationQueryVariables = Types.Exact<{
  hash: Types.Scalars['String']
}>

export type Join_Invitation_InvitationRelationQuery = { readonly __typename?: 'Query' } & {
  readonly invitationRelation: Types.Maybe<
    { readonly __typename?: 'InvitationRelationResponse' } & Pick<
      Types.InvitationRelationResponse,
      'expiresAt' | 'joinedAt'
    > & {
        readonly parentCompany: { readonly __typename?: 'Company' } & Pick<Types.Company, 'id'> &
          Shared_Address_CompanyFragment
        readonly childCompany: Types.Maybe<
          { readonly __typename?: 'Company' } & Pick<Types.Company, 'id'> & Join_Invitation_ChildCompanyFragment
        >
      }
  >
}

export type FetchMyCompaniesQueryVariables = Types.Exact<{ [key: string]: never }>

export type FetchMyCompaniesQuery = { readonly __typename?: 'Query' } & {
  readonly myCompanies: ReadonlyArray<
    { readonly __typename?: 'Company' } & {
      readonly parents: ReadonlyArray<{ readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name'>>
    }
  >
}

export type Join_Invitation_JoinResellerMutationVariables = Types.Exact<{
  hash: Types.Scalars['String']
}>

export type Join_Invitation_JoinResellerMutation = { readonly __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'resellerJoin'
>

export const Join_Invitation_ChildCompanyFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Join_Invitation_ChildCompany' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Address_Company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Address_Company' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nameLegal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const Join_Invitation_InvitationRelationDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Join_Invitation_InvitationRelation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invitationRelation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentCompany' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Address_Company' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childCompany' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Join_Invitation_ChildCompany' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'joinedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Join_Invitation_ChildCompany' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Address_Company' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Address_Company' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nameLegal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useJoin_Invitation_InvitationRelationQuery__
 *
 * To run a query within a React component, call `useJoin_Invitation_InvitationRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoin_Invitation_InvitationRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoin_Invitation_InvitationRelationQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useJoin_Invitation_InvitationRelationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Join_Invitation_InvitationRelationQuery,
    Join_Invitation_InvitationRelationQueryVariables
  >,
) {
  return Apollo.useQuery<Join_Invitation_InvitationRelationQuery, Join_Invitation_InvitationRelationQueryVariables>(
    Join_Invitation_InvitationRelationDocument,
    baseOptions,
  )
}
export function useJoin_Invitation_InvitationRelationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Join_Invitation_InvitationRelationQuery,
    Join_Invitation_InvitationRelationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Join_Invitation_InvitationRelationQuery, Join_Invitation_InvitationRelationQueryVariables>(
    Join_Invitation_InvitationRelationDocument,
    baseOptions,
  )
}
export type Join_Invitation_InvitationRelationQueryHookResult = ReturnType<
  typeof useJoin_Invitation_InvitationRelationQuery
>
export type Join_Invitation_InvitationRelationLazyQueryHookResult = ReturnType<
  typeof useJoin_Invitation_InvitationRelationLazyQuery
>
export type Join_Invitation_InvitationRelationQueryResult = Apollo.QueryResult<
  Join_Invitation_InvitationRelationQuery,
  Join_Invitation_InvitationRelationQueryVariables
>
export const FetchMyCompaniesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchMyCompanies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompanies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useFetchMyCompaniesQuery__
 *
 * To run a query within a React component, call `useFetchMyCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMyCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMyCompaniesQuery, FetchMyCompaniesQueryVariables>,
) {
  return Apollo.useQuery<FetchMyCompaniesQuery, FetchMyCompaniesQueryVariables>(FetchMyCompaniesDocument, baseOptions)
}
export function useFetchMyCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMyCompaniesQuery, FetchMyCompaniesQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchMyCompaniesQuery, FetchMyCompaniesQueryVariables>(
    FetchMyCompaniesDocument,
    baseOptions,
  )
}
export type FetchMyCompaniesQueryHookResult = ReturnType<typeof useFetchMyCompaniesQuery>
export type FetchMyCompaniesLazyQueryHookResult = ReturnType<typeof useFetchMyCompaniesLazyQuery>
export type FetchMyCompaniesQueryResult = Apollo.QueryResult<FetchMyCompaniesQuery, FetchMyCompaniesQueryVariables>
export const Join_Invitation_JoinResellerDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Join_Invitation_JoinReseller' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resellerJoin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
              },
            ],
          },
        ],
      },
    },
  ],
}
export type Join_Invitation_JoinResellerMutationFn = Apollo.MutationFunction<
  Join_Invitation_JoinResellerMutation,
  Join_Invitation_JoinResellerMutationVariables
>

/**
 * __useJoin_Invitation_JoinResellerMutation__
 *
 * To run a mutation, you first call `useJoin_Invitation_JoinResellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoin_Invitation_JoinResellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinInvitationJoinResellerMutation, { data, loading, error }] = useJoin_Invitation_JoinResellerMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useJoin_Invitation_JoinResellerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Join_Invitation_JoinResellerMutation,
    Join_Invitation_JoinResellerMutationVariables
  >,
) {
  return Apollo.useMutation<Join_Invitation_JoinResellerMutation, Join_Invitation_JoinResellerMutationVariables>(
    Join_Invitation_JoinResellerDocument,
    baseOptions,
  )
}
export type Join_Invitation_JoinResellerMutationHookResult = ReturnType<typeof useJoin_Invitation_JoinResellerMutation>
export type Join_Invitation_JoinResellerMutationResult = Apollo.MutationResult<Join_Invitation_JoinResellerMutation>
export type Join_Invitation_JoinResellerMutationOptions = Apollo.BaseMutationOptions<
  Join_Invitation_JoinResellerMutation,
  Join_Invitation_JoinResellerMutationVariables
>
