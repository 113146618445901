import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useNestedPath from './useNestedPath'

export const useGoTo = () => {
  const history = useHistory()
  const nested = useNestedPath()

  return useCallback(
    (url: string) => {
      return history.push(nested(url + window.location.search))
    },
    [nested],
  )
}
