// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

import type { FullAddressFragment } from '../../../../__generated__/Common'

export type Submit_Step2_CompaniesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['CompanyId']
}>

export type Submit_Step2_CompaniesQuery = { readonly __typename?: 'Query' } & {
  readonly company: Types.Maybe<
    { readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name' | 'website' | 'entityType'> & {
        readonly addresses: ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        readonly rmaAddresses: Types.Maybe<
          ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        >
      }
  >
  readonly myCompany: Types.Maybe<
    { readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name' | 'website'> & {
        readonly addresses: ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        readonly rmaAddresses: Types.Maybe<
          ReadonlyArray<{ readonly __typename?: 'CompanyAddress' } & FullAddressFragment>
        >
      }
  >
}

export const Submit_Step2_CompaniesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Submit_Step2_Companies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rmaAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rmaAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FullAddress' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FullAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
        ],
      },
    },
  ],
}

/**
 * __useSubmit_Step2_CompaniesQuery__
 *
 * To run a query within a React component, call `useSubmit_Step2_CompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmit_Step2_CompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmit_Step2_CompaniesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSubmit_Step2_CompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<Submit_Step2_CompaniesQuery, Submit_Step2_CompaniesQueryVariables>,
) {
  return Apollo.useQuery<Submit_Step2_CompaniesQuery, Submit_Step2_CompaniesQueryVariables>(
    Submit_Step2_CompaniesDocument,
    baseOptions,
  )
}
export function useSubmit_Step2_CompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Submit_Step2_CompaniesQuery, Submit_Step2_CompaniesQueryVariables>,
) {
  return Apollo.useLazyQuery<Submit_Step2_CompaniesQuery, Submit_Step2_CompaniesQueryVariables>(
    Submit_Step2_CompaniesDocument,
    baseOptions,
  )
}
export type Submit_Step2_CompaniesQueryHookResult = ReturnType<typeof useSubmit_Step2_CompaniesQuery>
export type Submit_Step2_CompaniesLazyQueryHookResult = ReturnType<typeof useSubmit_Step2_CompaniesLazyQuery>
export type Submit_Step2_CompaniesQueryResult = Apollo.QueryResult<
  Submit_Step2_CompaniesQuery,
  Submit_Step2_CompaniesQueryVariables
>
