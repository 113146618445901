import type { CompanyAddress } from '@shared-ui/generated/graphql'
import type { CompanyAddressId, Writeable } from '@shared/types/brands'
import { create } from 'zustand'
import type { CompanyAddressOption, ResellerAddress, ResellerForm, ResellerInfo } from './SettingsTypes'

type SettingsState = {
  form: ResellerForm
  addresses: CompanyAddress[]
  addressesOptions: CompanyAddressOption[]
  selectedAddressId: string
  showRemovePopup: boolean
}

type SettingsStateFunctions = {
  updateInfo: (info: ResellerInfo) => void
  updateAddresses: (addresses: CompanyAddress[]) => void
  updateAddress: (newAddress: CompanyAddress) => void
  addAddress: (newAddress: CompanyAddress) => void
  removeAddress: (addressId: string) => void
  setBillingAddress: (billingAddressId: CompanyAddressId) => void
  updateAddressesOptions: (addressesOptions: CompanyAddressOption[]) => void
  selectAddress: (resellerAddress: ResellerAddress) => void
  setShowRemovePopup: (flag: boolean) => void
}

const createInitialState = (): SettingsState => {
  return {
    form: {
      info: {
        name: '',
        nameLegal: '',
        website: '',
        email: '',
      },
      address: {
        address1: '',
        city: '',
        country: '',
        id: '',
        isDefaultBilling: false,
        isDefaultShipping: false,
        phone: '',
        phoneCode: 'AD',
        state: '',
        zipcode: '',
      },
      forceBillingAddress: false,
    },
    addresses: [],
    addressesOptions: [],
    selectedAddressId: '',
    showRemovePopup: false,
  }
}

export const useSettingsStore = create<SettingsState & SettingsStateFunctions>((set) => ({
  ...createInitialState(),

  updateInfo(info: ResellerInfo) {
    set((state) => {
      return {
        form: {
          ...state.form,
          info,
        },
      }
    })
  },

  updateAddresses(addresses: CompanyAddress[]) {
    set(() => ({ addresses }))
  },

  updateAddress(newAddress: CompanyAddress) {
    set((state) => {
      const index = state.addresses.findIndex((entry) => entry.id === newAddress.id)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.addresses]
      newAddresses[index] = newAddress

      return {
        addresses: newAddresses,
      }
    })
  },

  addAddress(newAddress: CompanyAddress) {
    set((state) => {
      const newAddresses = [...state.addresses]
      newAddresses.push(newAddress)

      return {
        addresses: newAddresses,
      }
    })
  },

  removeAddress(addressId: string) {
    set((state) => {
      const index = state.addresses.findIndex((entry) => entry.id === addressId)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.addresses]
      newAddresses.splice(index, 1)

      return {
        addresses: newAddresses,
      }
    })
  },

  setBillingAddress(billingAddressId: CompanyAddressId) {
    set((state) => {
      const newAddresses = [...state.addresses]

      for (let n = 0; n < newAddresses.length; n += 1) {
        const address = newAddresses[n]
        const writebleAddress = address as Writeable<CompanyAddress>
        writebleAddress.isDefaultBilling = address.id === billingAddressId
      }

      return {
        addresses: newAddresses,
      }
    })
  },

  updateAddressesOptions(addressesOptions: CompanyAddressOption[]) {
    set(() => ({
      addressesOptions,
    }))
  },

  selectAddress(resellerAddress: ResellerAddress) {
    set((state) => ({
      form: {
        ...state.form,
        address: resellerAddress,
        forceBillingAddress: resellerAddress.isDefaultBilling,
      },
      selectedAddressId: resellerAddress.id,
    }))
  },

  setShowRemovePopup(showRemovePopup: boolean) {
    set(() => ({
      showRemovePopup,
    }))
  },
}))
