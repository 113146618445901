import styled from 'styled-components'

export const Bullet = styled.div<{ color: string; round?: boolean }>`
  width: 8px;
  min-width: 8px;

  height: 8px;
  min-height: 8px;

  ${(props) => props.round && 'border-radius: 50%;'}

  margin-right: ${(props) => props.theme.spacing.s};

  background-color: ${(props) => props.color};
`
