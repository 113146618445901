import { Link } from '@shared-ui/components'
import { Document } from 'rma-frontend-shared/components/Document'

export const PolicyAndProcedures = () => {
  return (
    <div className="p-20 overflow">
      <Document heading="RESELLER POLICY & PROCEDURES">
        <div>
          <p>
            <b>How to link to a distributor (for existing reseller accounts):</b>
          </p>
          <ul>
            <li>As a reseller, you can be linked to multiple distributors.</li>
            <li>To get linked to a distributor you will need an invitation link from your distributor.</li>
            <li>Once you receive the invitation link, click on it to be redirected to the account.ui.com website.</li>
            <li>Log into the system and confirm that you agree to be linked to the requesting distributor.</li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <b>Customer RMA tickets</b>
          </p>
          <p>When a user submits a ticket for a reseller, it will appear in the reseller’s account at var.ui.com.</p>
          <ul>
            <li>
              The reseller can find their customers’ tickets in 2 places:
              <ul>
                <li>Dashboard</li>
                <li>Customer Tickets page</li>
              </ul>
            </li>
            <li>
              The reseller can view ticket details, communicate with the customers as well as manage the tickets by
              clicking the “View details” link (last column of the ticket list table) and choosing the corresponding
              section.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <b>Processing customer tickets</b>
          </p>
          <ul>
            <li>
              The reseller must complete the RMA process below within 30 days of ticket submission. The reseller must
              also respond promptly, courteously and professionally.
            </li>
            <li>
              The reseller can process a ticket in the &quot;Manage&quot; tab of the &quot;View details&quot; modal
              window.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <b>Step 1: Warranty Confirmation</b>
          </p>
          <p>The reseller must confirm whether the product that a customer wishes to return:</p>
          <ul style={{ listStyle: 'circle' }}>
            <li>was purchased at their store and</li>
            <li>
              is within the warranty pursuant to the terms and conditions of Ubiquiti’s limited warranty{' '}
              <Link href="https://www.ui.com/support/warranty/">here</Link> or applicable laws.
            </li>
          </ul>
          <p>
            On the “Manage” tab, click “Confirm” to confirm that the product is within warranty or “Decline” to decline
            the warranty because it was expired for the item or purchased in a different shop.
          </p>

          <p>&nbsp;</p>

          <p>
            <b>Step 2: Confirm receipt of item</b>
          </p>
          <p>
            Once the reseller confirms that the product is within warranty, the customer will receive an automatic email
            with a shipping address to return the product to the reseller.&nbsp; Upon the receipt of the product, the
            reseller must verify that the MAC ID/Serial number matches the one indicated in the ticket. If MAC ID/Serial
            number matches, the reseller must go to the “Manage” tab and click “Confirm” to confirm that the product has
            been received.
          </p>
          <p>
            If the MAC ID doesn&apos;t match and the reseller determines that the product is not covered by the
            manufacturer’s warranty, the reseller may decline RMA by clicking &quot;Decline&quot; and leaving a note to
            the customer with the reason of the decline.
          </p>

          <p>&nbsp;</p>

          <p>
            <b>Step 3: RMA approval</b>
          </p>
          <p>
            The reseller must physically check the product to verify the issue described by the customer and go to the
            “Manage” tab to either:
          </p>
          <ul>
            <li>Approve RMA: Select Failure Category, enter detailed problem description and click “Approve”.</li>
            <li>
              Deny RMA: Enter note to the customer, explaining why RMA has been denied (e.g. no issue has been found)
              and click “Decline”.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <b>Step 4: Shipping replacement</b>
          </p>
          <p>
            The reseller shall ship the replacement product for approved RMA as soon as possible. Once a replacement
            product has been shipped, the reseller must go to the “Manage” tab, enter the shipping company and the
            tracking number, then click “Confirm.”
          </p>

          <p>&nbsp;</p>

          <p>
            <b>Closing the ticket</b>
          </p>
          <p>The ticket closes 21 days after the replacement is shipped.</p>

          <p>&nbsp;</p>

          <p>
            <b>Customer ratings:</b>
          </p>

          <ul>
            <li>
              Customers will be able to rate the reseller’s performance. They can change their rating any time until the
              ticket is closed.
            </li>
            <li>Ticket closes 21 days after the replacement is shipped.</li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <b>Getting replacement of the faulty items from distributors (for resellers):</b>
          </p>
          <p>
            Once a reseller has completed a RMA claim, the reseller can then ask its distributor to provide a
            replacement for the product. First, the reseller must ship the faulty product to the applicable distributor.
          </p>
          <p>
            A reseller has 45 days following the approval of the RMA ticket to create a ticket to the distributor on the
            “RMA Requests to Distributors”. The first tab, “Pending”, will display a list of pending customer tickets
            that have been approved by the reseller but haven&apos;t yet been sent to the distributor. Tickets will stay
            under this tab for 45 days. After that, they will move to the “Expired” tab. The reseller will be able to
            see how many days are left until a ticket expires.
          </p>
          <p>
            Then a reseller can select all the items they want to send to a distributor in one shipment and click
            “Send”. Next, the reseller should confirm the inventory of shipment and check whether all the ticket
            descriptions are accurate. Then, the reseller needs to confirm shipping information by entering the shipping
            company and tracking number of the shipment that will contain all the items sent to a distributor. After
            confirmation of all the information, the reseller should click “Confirm,” so that the system will create
            tickets to the distributor.
          </p>
          <p>
            Once the distributor receives the shipment, such distributor will need to check each item individually to
            confirm that information provided by the reseller is correct and the issue matches the description.
          </p>
          <p>
            The reseller can track the status of its tickets to its distributor on the same page, under the “Active”
            tab.
          </p>
        </div>
      </Document>
    </div>
  )
}
