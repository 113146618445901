import { UbiquitiLogo } from '@ubnt/icons'
import { Checkbox } from '@ubnt/ui-components'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { AuthService } from '../auth/auth-service'
import { useAuthStore } from '../auth/auth-store'
import { RmaButton } from './Button'
import { Link } from './Link'
import { useAcceptTosMutation } from './__generated__/TosAgreement'

export const TosAgreement = () => {
  const { user } = useAuthStore()
  const [tosChecked, setTosChecked] = useState(false)
  const [acceptTos] = useAcceptTosMutation()

  const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTosChecked(e.target.checked)
  }, [])

  const onAccept = useCallback(async () => {
    if (!user?.id || !tosChecked) {
      return
    }
    try {
      const userId = user.id
      if (!userId) {
        return
      }

      const acceptResult = await acceptTos({ variables: { userId } })
      if (acceptResult.data?.acceptTos) {
        await AuthService.authorize()
      }
    } catch (e) {
      // notification?
    }
  }, [user, acceptTos, tosChecked])

  return (
    <TosPageWrapper>
      <div>
        <LogoHolder>
          <UbiquitiLogo size="full" />
        </LogoHolder>
        <HeaderText>Ubiquiti Inc. Terms of Service</HeaderText>
        <CheckHolder>
          <Checkbox id="tosAccept" checked={tosChecked} onChange={onCheckboxChange}>
            I agree to the <Link href="/terms-of-service">Terms of Service</Link>.
          </Checkbox>
        </CheckHolder>
        <div>
          <TosButton variant="primary" onClick={onAccept} disabled={!tosChecked}>
            Accept
          </TosButton>
        </div>
      </div>
    </TosPageWrapper>
  )
}

const TosPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  margin: 0 auto;
`

const LogoHolder = styled.div`
  margin: 0 auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin: 40px 0 15px;
`

const CheckHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 30px;
`

const TosButton = styled(RmaButton)`
  max-width: 140px;
`
