import { Loader, Text, Table } from '@ubnt/ui-components'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { FC } from 'react'
import { useState } from 'react'
import { getFailureCategory } from 'rma-shared/types/tickets'
import { Container } from '../../../Container'
import { LoaderContainer } from '../../../Containers'
import { RemoteImage } from '../../../Image'
import { ReviveTicket } from '../../../ReviveTicket'
import type { TableColumnConfig, TableItem } from '../../../Table'
import { Customer, Device, FormattedDate, SupportBy } from '../../../table-columns'
import { useTickets_Expired_TicketsPendingRmaQuery as useTicketsPendingRmaExpiredQuery } from './__generated__/TicketsTable'
import { PaginationControls } from '../../../PaginationControls'
import type { CommonProps } from '../../common'
import { DEFAULT_TICKETS_PER_PAGE } from '../../common'
import { StickyTableWrap, StickyTableContainer } from '../../../StickyTable'

interface SubmittedBy {
  companyId?: string
  companyName?: string
  companyWebsite?: string
  customerId?: string
  customerName?: string
}

interface Row {
  id: string
  submittedBy: SubmittedBy
  model: {
    name: string
    image: string | null
  }
  macId: string
  updatedAt: string
  expiresAt?: string | null
  revivedCount: number
  failureCategory: string
  revive?: undefined
}

export const TicketsTable: FC<CommonProps> = ({ searchQuery, stickyHeadTop }) => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_TICKETS_PER_PAGE)
  const { loading, error, data } = useTicketsPendingRmaExpiredQuery({
    variables: {
      cursor: offset.toString(),
      limit,
      search: searchQuery,
    },
  })

  const total = data?.ticketsPendingRma?.pageInfo.total || 0

  return (
    <StickyTableWrap>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        const items = (data?.ticketsPendingRma?.result || []).map(
          (item): TableItem<Row> => {
            const submittedBy = item.submittedBy as SubmittedBy

            return {
              id: item.id,
              model: {
                name: item.device.name,
                image: item.device.productImage,
              },
              submittedBy: {
                companyId: submittedBy.companyId ?? undefined,
                companyName: submittedBy.companyName ?? undefined,
                companyWebsite: submittedBy.companyWebsite ?? undefined,
                customerId: submittedBy.customerId ?? undefined,
                customerName: submittedBy.customerName ?? undefined,
              },
              macId: item.device.mac || '',
              updatedAt: item.updatedAt,
              expiresAt: item.expiresAt,
              revivedCount: item.revivedCount,
              failureCategory: getFailureCategory(item.handlerFailureCategoryId || item.customerFailureCategoryId),
            }
          },
        )

        return (
          <StickyTableContainer $headTopOffset={stickyHeadTop} $noItems={!items.length}>
            <Table
              rowHeight={50}
              headerHeight={50}
              initialSortBy="expiresAt"
              disableColumnFilters
              columns={columns}
              items={items}
              renderPlaceholder={() => (
                <Container $padding={['m', 0]}>
                  <Text size="inherit">No expired tickets{searchQuery && ' found'}.</Text>
                </Container>
              )}
              renderFooter={() => (
                <PaginationControls
                  pageInfo={{
                    offset,
                    limit,
                    total,
                  }}
                  loadPage={(newPageInfo) => {
                    setOffset(newPageInfo.offset)
                    setLimit(newPageInfo.limit)
                  }}
                  itemsLabel="tickets"
                />
              )}
            />
          </StickyTableContainer>
        )
      })()}
    </StickyTableWrap>
  )
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'submittedBy',
    label: 'Submitted by',
    sortable: true,
    renderCell: (row) => {
      if (row.submittedBy.customerId && row.submittedBy.customerName) {
        return <Customer id={row.id} name={row.submittedBy.customerName} />
      }

      if (row.submittedBy.companyId && row.submittedBy.companyName) {
        return <SupportBy name={row.submittedBy.companyName} />
      }

      return null
    },
  },
  {
    id: 'model',
    label: 'Device',
    sortable: true,
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'macId',
    label: 'MAC ID / Serial',
    sortable: true,
    renderCell: (row) => formatSerial(row.macId),
  },
  {
    id: 'expiresAt',
    label: 'Expiration date',
    sortable: true,
    renderCell: (row) => <FormattedDate date={row.expiresAt} />,
  },
  {
    id: 'failureCategory',
    label: 'Failure Category',
    sortable: true,
    renderCell: (row) => <>{row.failureCategory}</>,
  },
  {
    id: 'updatedAt',
    label: 'Updated on',
    sortable: true,
    renderCell: (row) => <FormattedDate date={row.updatedAt} />,
  },
  {
    id: 'revive',
    align: 'right',
    renderCell: (row) => <ReviveTicket ticketId={row.id} revivedCount={row.revivedCount} />,
    renderLabel: () => null,
  },
]
