import styled from 'styled-components'
import { cssVariables } from '@ubnt/ui-components'

export const StickyTableContainer = styled.div<{ $noItems?: boolean; $headTopOffset?: string | number }>`
  flex: 1;

  [class*='tableContainer'] {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  [class*='tableContainer'] > [class*='table'] {
    flex: ${({ $noItems }) => ($noItems ? '0' : '1')};
    display: flex;
    flex-direction: column;
  }

  [class*='tableContainer'] > [class*='placeholder'] {
    margin-bottom: ${cssVariables['spacing-s']};
  }

  thead {
    position: sticky;
    top: ${({ $headTopOffset }) => $headTopOffset || '0'};
    background: ${cssVariables['white-0']};
    z-index: 1;
  }

  tr:last-child td {
    border-bottom: 0 !important;
  }

  [class*='footer'] {
    position: sticky;
    bottom: 0;
    margin-top: 0;
    padding-bottom: ${cssVariables['spacing-l']};
    background: white;
    border-top: 1px solid ${cssVariables['white-6']};

    &:empty {
      display: none;
    }
  }
`

export const StickyTableWrap = styled.div`
  margin: 0 ${cssVariables['spacing-xl']};
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${cssVariables['white-0']};
`
