import { StackedBar, Text } from '@ubnt/ui-components'
import styled from 'styled-components'

import { Flex, FlexItem } from '../Flex'
import { StarIcon } from '../icons/StarIcon'

export const Legend = styled(Flex).attrs({ center: true })`
  margin-top: ${(props) => props.theme.spacing.l};
`

export const LegendItem = styled(Flex).attrs({ center: true })`
  margin-left: ${(props) => props.theme.spacing.l};
  &:first-child {
    margin-left: 0;
  }
`

export const Star = styled(StarIcon).attrs({ size: 'small', isActive: true })`
  margin-left: ${(props) => props.theme.spacing.xxs};
  margin-right: ${(props) => props.theme.spacing.s};
`

export const Head = styled(Flex).attrs({ alignCenter: true, justifyBetween: true })`
  margin: ${(props) => props.theme.spacing.xl} 0;
`

export const AverageContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing.l};
`

export const Average = styled(Text).attrs({ size: 'header-m', weight: 'bold', color: 'primary' })`
  display: block;

  white-space: nowrap;

  line-height: 1;
  font-size: 40px;
  letter-spacing: 1px;
`

export const Bar = styled(StackedBar)`
  border-radius: 1px;
  background-color: ${(props) => props.theme.colors.white4};
`

export const RatingCountContainer = styled(Flex).attrs({ column: true, justifyAround: true })`
  margin-left: ${(props) => props.theme.spacing.s};
`

export const RatingContainer = styled(FlexItem)`
  height: 100%;
  max-width: 287px;

  color: ${(props) => props.theme.colors.grey3};

  svg {
    margin-left: ${(props) => props.theme.spacing.s};
    &:first-child {
      margin-left: 0;
    }
  }
`
