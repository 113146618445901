import { TICKET_MAX_UPLOAD_FILES } from '@shared/tickets/constants'
import type { FileId, TicketId } from '@shared/types/brands'
import { Modal } from '@ubnt/ui-components'
import { useCallback, useMemo, useState } from 'react'
import type { File } from '../../../../generated/graphql'
import { Container } from '../../../Container'
import { Grid } from '../../../Grid'
import { Attachments } from '../../../file-attachments'
import type { FileUploadFile } from '../../../file-upload'
import { FileUpload } from '../../../file-upload'

export interface AttachmentsUpdateInput {
  ticketId: TicketId
  filesRemoveIds: FileId[]
  newCompanyFiles: FileUploadFile[]
}

interface Props {
  onClose: () => void
  onSave: (input: AttachmentsUpdateInput) => void
  skipFileCopyMap: Map<TicketId, FileId[]>
  newCompanyFilesMap: Map<TicketId, FileUploadFile[]>
  ticket: {
    id: TicketId
    userFiles: readonly Pick<File, 'id' | 'url' | 'filename'>[]
    companyFiles: readonly Pick<File, 'id' | 'url' | 'filename'>[]
  }
}

export const UpdateAttachments = ({ ticket, skipFileCopyMap, newCompanyFilesMap, onClose, onSave }: Props) => {
  const [filesRemoveIds, setFilesRemoveIds] = useState<FileId[]>([])
  const [newCompanyFiles, setNewCompanyFiles] = useState<FileUploadFile[]>(newCompanyFilesMap.get(ticket.id) ?? [])

  const alreadyUploadedFiles = useMemo(() => {
    return [
      ...ticket.userFiles.filter(
        (file) => !filesRemoveIds.includes(file.id) && !(skipFileCopyMap.get(ticket.id) ?? []).includes(file.id),
      ),
      ...ticket.companyFiles.filter(
        (file) => !filesRemoveIds.includes(file.id) && !(skipFileCopyMap.get(ticket.id) ?? []).includes(file.id),
      ),
    ]
  }, [ticket.id, ticket.userFiles, ticket.companyFiles, skipFileCopyMap, filesRemoveIds])

  const maxFilesCount = useMemo(() => {
    return TICKET_MAX_UPLOAD_FILES * 2 - alreadyUploadedFiles.length
  }, [alreadyUploadedFiles])

  const showMaxFilesMessege = useMemo(() => {
    return TICKET_MAX_UPLOAD_FILES * 2 === alreadyUploadedFiles.length + newCompanyFiles.length
  }, [alreadyUploadedFiles, newCompanyFiles])

  const setNewCompanyFilesLimited = useCallback(
    (files: FileUploadFile[]) => {
      setNewCompanyFiles(files.slice(0, maxFilesCount))
    },
    [setNewCompanyFiles, maxFilesCount],
  )

  return (
    <Modal
      isOpen
      title="RMA Ticket Attachments"
      onRequestClose={onClose}
      actions={[
        {
          text: 'Cancel',
          variant: 'secondary',
          onClick: onClose,
          disabled: false,
        },
        {
          text: 'Save',
          variant: 'primary',
          onClick: () => {
            onSave({
              ticketId: ticket.id,
              filesRemoveIds,
              newCompanyFiles,
            })
            onClose()
          },
          disabled: false,
        },
      ]}
    >
      <Container $minHeight="170px">
        <Grid $container direction="column" gap="m">
          <Grid $item $xs={12} $container gap="s">
            <Grid $item $xs={12}>
              <Attachments
                attachments={alreadyUploadedFiles}
                onRemove={(file) => {
                  setFilesRemoveIds([...filesRemoveIds, file.id])
                }}
              />
            </Grid>
            <Grid $item $xs={12}>
              <FileUpload maxFiles={maxFilesCount} files={newCompanyFiles} setFiles={setNewCompanyFilesLimited} />
              {showMaxFilesMessege && (
                <p>
                  <b>A maximum of {TICKET_MAX_UPLOAD_FILES * 2} files can be attached to this ticket.</b>
                </p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  )
}
